import React from 'react';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';

import Giftcard from '../../cards/components/Giftcard';

const BrandCard = ({ brand }) => (
  <Col xs={6} sm={6} md={4} lg={3} className="p-2 p-lg-3">
    <Giftcard card={brand} />
  </Col>
);

export default BrandCard;

BrandCard.propTypes = {
  brand: PropTypes.object,
};
