import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { useFormik } from 'formik';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import swal from 'sweetalert';
import * as Yup from 'yup';

import { useRewardPoints } from './hooks/useRewardPoints';
import SearchProductButtons from './components/SearchProductButtons';

const RewardPoints = () => {
  const { t } = useTranslation();
  const {
    onGetRewardPointsAction,
    onGetTransactionsAction,
    onGetConvertCreditsAction,
    credits: transactions,
    errors: rewardPointsErrors,
    total_credits,
    onRedeemGiftCard,
  } = useRewardPoints();
  const [errors, setErrors] = useState([]);

  const handleOffence = () => {
    setErrors([]);
  };

  const formik = useFormik({
    initialValues: {
      giftcard_number: '',
    },
    validationSchema: Yup.object({
      giftcard_number: Yup.string()
        .min(2, `${t('validation:minFieldLength')} 2`)
        .max(200, `${t('validation:maxFieldLength')} 200`),
    }),
    onSubmit: async data => {
      onRedeemGiftCard({
        ...data,
        callback: () => {
          swal({
            title: `${t('rewardPoints.rewardModal.title')}?`,
            text: `${t('rewardPoints.rewardModal.text')}.`,
            icon: 'warning',
            buttons: true,
            dangerMode: true,
            className: 'reward-modal',
          }).then(willDelete => {
            if (willDelete) {
              onGetConvertCreditsAction(data);
              setTimeout(() => onGetTransactionsAction(), 2000);
              formik.resetForm();
            }
          });
        },
      });
    },
    validateOnChange: false,
  });

  useEffect(() => {
    setErrors(rewardPointsErrors);
  }, [rewardPointsErrors]);

  useEffect(() => {
    onGetRewardPointsAction();
    onGetTransactionsAction();
  }, [onGetRewardPointsAction, onGetTransactionsAction]);

  return (
    <Container fluid>
      <Row>
        <Col sm="5" className="pd-5 mt-5">
          <Row>
            <Col xs={12}>
              <Form onSubmit={formik.handleSubmit}>
                <p className="rewardspoints-font">{t('rewardPoints.redeemYourGiftCard')}</p>
                <InputGroup className="mb-3 redeem-button">
                  <FormControl
                    placeholder={t('rewardPoints.form.redeemPlaceholder')}
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    className="redeem-button-a"
                    value={formik.values.giftcard_number}
                    onChange={e => {
                      formik.handleChange(e);
                      handleOffence(e.currentTarget.value);
                    }}
                    onBlur={formik.handleBlur}
                    name="giftcard_number"
                  />
                  <InputGroup.Append>
                    <Button
                      variant="info"
                      className="redeem-button-b rounded-0"
                      type="Submit"
                      value="Redeem"
                      disabled={!formik.isValid}
                    >
                      {t('rewardPoints.form.redeemButtonText')}
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
                {formik.errors.giftcard_number ? (
                  <p className="validation-messages text-left">{formik.errors.giftcard_number}</p>
                ) : null}
                {errors && errors.length && formik.values.giftcard_number ? (
                  <p className="validation-messages text-left">{errors.join('\n')}</p>
                ) : null}
              </Form>
            </Col>
          </Row>
        </Col>

        <Col sm={12} md={7} lg={{ span: 6, offset: 1 }}>
          <div className="cardpoints mt-5">
            <h3 className="pt-4 text-center font-points">{t('rewardPoints.points.title')}</h3>
            <h1 className="mt-4 text-center point-a">{total_credits}</h1>
            <p className='mt-2 text-center points-b'>{t('rewardPoints.points.subtitle')}!</p>
          </div>
          <SearchProductButtons />
        </Col>
      </Row>
      <Row>
        <Col sm="12" className="mt-5 pd-5">
          <h2 className="trans-detail">{t('rewardPoints.transactionDetails.title')}</h2>
          <div className="table-responsive">
            <Table className="mt-4 hover border">
              <thead className="table-head">
                <tr>
                  <th>{t('rewardPoints.transactionDetails.table.date')}</th>
                  <th>{t('rewardPoints.transactionDetails.table.type')}</th>
                  <th>{t('rewardPoints.transactionDetails.table.details')}</th>
                  <th>{t('rewardPoints.transactionDetails.table.amount')}</th>
                  <th>{t('rewardPoints.transactionDetails.table.balance')}</th>
                </tr>
              </thead>
              <tbody>
                {transactions && transactions.length > 0 ? (
                  transactions.map((transaction, index) => {
                    return (
                      <tr key={index} className="table-body-font">
                        <td className="font-date">
                          <Moment format="MMM Do,YYYY">{transaction.created_at}</Moment>
                          <br />
                          <Moment format="h:mm a">{transaction.created_at}</Moment>
                        </td>
                        <td>{transaction.type}</td>
                        {transaction.type === 'GIFT CARD' ? (
                          <td>
                            Gift Card Redemption: &nbsp;
                            {transaction.card_number}
                          </td>
                        ) : (
                          <td>{t('rewardPoints.transactionDetails.table.orderNumber')}: &nbsp;{transaction.order_id}</td>
                        )}
                        {transaction.transaction_type === 'CREDITED' || transaction.transaction_type === 'CASHBACK' ? (
                          <td className="font-balance">+ &nbsp;{transaction.amount}</td>
                        ) : (
                          <td className="font-balance-red">- &nbsp;{transaction.amount}</td>
                        )}
                        <td>{transaction.current_balance}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td style={{ color: 'red' }}>{t('rewardPoints.transactionDetails.table.noResults')}</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default RewardPoints;
