import React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { ROUTES } from '../../routes';

import NotFoundImage from '../../assets/media/images/not-found.png';
import '../../assets/scss/NotFound.scss';

const NotFound = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Container className="notfound-container">
      <Row>
        <Col xs={12} className="text-center">
          <img src={NotFoundImage} className="img-fluid" alt="" />
        </Col>
        <Col xs={12} className="text-center oops-container">
          <p className="oops-text">{t('notFound.message')}</p>
        </Col>
      </Row>
      <Row className="pt-3">
        <Col xs={12} md={{ offset: 4, span: 4 }} className="d-grid gap-2">
          <Button
            type="button"
            variant="info"
            className="go-to-homepage-btn btn-block"
            onClick={() => history.push({ pathname: ROUTES.home })}
          >
            {t('notFound.goToHomePageButton')}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default NotFound;
