import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CryptoJS from 'crypto-js';

import { getRedeemCardNumberState, redeemCardActions } from '../redux/reducer';
import { useRewardPoints } from '../../rewardPoints/hooks/useRewardPoints';
import config from '../../../config';

const decryptToken = token => {
  try {
    const data = token.replaceAll(' ', '+');
    const iv = CryptoJS.enc.Utf8.parse('BBBBBBBBBBBBBBBB');
    const key = CryptoJS.enc.Utf8.parse(config.aesSecret);

    let decryptedData = CryptoJS.AES.decrypt(data, key, {
      iv,
      mode: CryptoJS.mode.CBC,
    });

    return decryptedData.toString(CryptoJS.enc.Utf8);
  } catch (e) {
    console.error('e', e);
  }
};

export const useRedeemCard = () => {
  const dispatch = useDispatch();
  const giftcardNumber = useSelector(getRedeemCardNumberState);
  const { onRedeemGiftCard, onGetConvertCreditsAction } = useRewardPoints();

  const onSaveGiftcardNumber = useCallback(
      payload => {
        dispatch(redeemCardActions.saveGiftcardNumber(payload));
      },
      [dispatch]
  );

  const onRedeemCard = useCallback(() => {
    if (giftcardNumber) {
      onRedeemGiftCard({
        giftcard_number: giftcardNumber,
        callback: () => onGetConvertCreditsAction({ giftcard_number: giftcardNumber }),
      });
    }
  }, [onRedeemGiftCard, giftcardNumber, onGetConvertCreditsAction]);

  const onDecryptHash = useCallback(token => {
    return decryptToken(token);
  }, []);

  return {
    onSaveGiftcardNumber,
    onRedeemCard,
    giftcardNumber,
    onDecryptHash,
  };
};
