export const filterByCardName = (array, string) => {
  if (array) {
    return array.filter(o =>
      Object.keys(o).some(() => o["name"].toLowerCase().includes(string.toLowerCase())
      ));
  }
  return array;
};


