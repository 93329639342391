import {useCallback} from "react";
import {sendGiftAction} from "../redux/actions";
import {useDispatch, useSelector} from "react-redux";
import {getGiveawayState, giveawayActions} from "../redux/reducer";

export const useGiveaway = () => {
    const dispatch = useDispatch();
    const { ...giveawayState } = useSelector(getGiveawayState);

    const onSendingGift = useCallback(
        giveawayGiftCardData => {
            dispatch(sendGiftAction(giveawayGiftCardData));
        },
        [dispatch]
    );

    const onResetState = useCallback(
        () => {
            dispatch(giveawayActions.resetState());
        },
        [dispatch]
    );

    const onClearErrors = useCallback(() => {
        dispatch(giveawayActions.clearErrors());
    }, [dispatch]);

    return {
        ...giveawayState,
        onSendingGift,
        onClearErrors,
        onResetState
    }
}
