import React from 'react';
import { useTranslation } from 'react-i18next';

import { ColumnsTitle } from './ColumnsTitle';
import { RowDataPropType } from '../../propTypes/RowData';

export const OrderNumberColumn = ({ rowData }) => {
  const { t } = useTranslation();

  return (
    <>
      <ColumnsTitle title={t('orders.orderNo')} />
      <p className="text_order">{rowData.orderid}</p>
    </>
  );
};

OrderNumberColumn.propTypes = {
  rowData: RowDataPropType,
};
