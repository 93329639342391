import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from './useAuth';
import { useActiveUser } from './useActiveUser';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ROUTES } from '../../../routes';
import { useHistory } from 'react-router-dom';
import { useRedeemCard } from '../../redeemCard/hooks/useRedeemCard';

export const useLoginHook = () => {
  const { t } = useTranslation();
  const { onLogin, onLogout, isAuthenticated, reset, errors: authErrors, onSetErrors, onClearErrors } = useAuth();
  const { onRedeemCard, giftcardNumber } = useRedeemCard();
  const { onGetUserActiveAction, ...useractive } = useActiveUser();
  const history = useHistory();
  const [isValid, setIsValid] = useState(false);
  const [visible, setVisible] = useState(true);
  const [message, setMessage] = useState('');
  const [loginBtnClicked, setLoginBtnClicked] = useState(false);

  const googleId = `${process.env.REACT_APP_GOOGLE_API_KEY || ''}`;
  const fbId = `${process.env.REACT_APP_FB_APP_ID || ''}`;

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      recaptcha: '',
    },
    validationSchema: Yup.object({
      recaptcha: Yup.string().required('ReCAPTCHA is required'),
      email: Yup.string().email(t('validation:invalidEmail')).required(t('validation:requiredField')),
      password: Yup.string()
        .min(2, `${t('validation:minFieldLength')} 2`)
        .max(200, `${t('validation:maxFieldLength')} 200`)
        .required(t('validation:requiredField')),
    }),
    onSubmit: data => {
      setLoginBtnClicked(true);
      onLogin(data);
    },
    validateOnChange: false,
  });

  useEffect(() => {
    onClearErrors();
  }, [onClearErrors]);

  useEffect(() => {
    const search = history.location.search;
    if (search.indexOf('?confirm_account=') !== -1) {
      const token = search.split('confirm_account=')[1];
      const data = { token };
      onGetUserActiveAction(data);
      if (useractive.verified === true) {
        setIsValid(true);
        setMessage(t('auth.logIn.messages.accountSuccessfullyCreated'));
        window.setTimeout(() => {
          setVisible(false);
        }, 3000);
      }
      history.push({ pathname: ROUTES.home });
    }
  }, [useractive.verified, onGetUserActiveAction, history, t]);

  useEffect(() => {
    if (isAuthenticated) {
      loginBtnClicked && onRedeemCard();
      history.push({ pathname: ROUTES.home });
    }

    if (reset) {
      setIsValid(true);
      setMessage(t('auth.logIn.messages.passwordSuccessfullyUpdated'));
      window.setTimeout(() => {
        setVisible(false);
      }, 3000);
    }
  }, [isAuthenticated, reset, history, onRedeemCard, loginBtnClicked, t]);

  const googleLoginSuccess = (event, recaptchaValue) => {
    setLoginBtnClicked(true);
    onLogin({
      provider: 'Google',
      token_type: 'access_token',
      token: event.accessToken,
      expires_at: event?.tokenObj?.expires_at,
      recaptcha: recaptchaValue || ""
    });
  };

  const googleLoginFailure = event => {
    console.log('error ', event);
    onSetErrors([t('auth.logIn.messages.logInFailed')]);
  };

  const facebookLoginSuccess = (event, recaptchaValue) => {
    setLoginBtnClicked(true);
    onLogin({
      provider: 'Facebook',
      token_type: 'access_token',
      token: event.accessToken,
      expires_at: event?.data_access_expiration_time,
      recaptcha: recaptchaValue || ""
    });
  };

  const facebookLoginFailure = event => {
    console.log('error ', event);
    onSetErrors([t('auth.logIn.messages.logInFailed')]);
  };
  return {
    giftcardNumber,
    authErrors,
    isValid,
    visible,
    formik,
    message,
    fbId,
    googleId,
    googleLoginSuccess,
    googleLoginFailure,
    facebookLoginSuccess,
    facebookLoginFailure,
  };
};
