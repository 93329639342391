import React from 'react';
import './index.css';

function Header() {
  return (
    <>
      <div>
        <div className="row header-row">
          <div className="triangle-right"/>
          <div className="triangle-left"/>
        </div>
      </div>
    </>
  );
}

export default Header;
