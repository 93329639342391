import { useDispatch, useSelector } from 'react-redux';
import { getGiftcardsState, giftCardsAction } from '../redux/reducer';
import { useCallback, useMemo } from 'react';
import { giftCardsUnitAction, giftCardThemeAction } from '../redux/actions';

export const useGiftcards = () => {
  const giftcardsState = useSelector(getGiftcardsState);
  const dispatch = useDispatch();

  const currentCountryName = useMemo(
    () =>
      giftcardsState?.selectedCountry?.country_name
        ? giftcardsState?.selectedCountry?.country_name
        : giftcardsState?.countries?.length
        ? giftcardsState?.countries[0]?.country_name
        : '',
    [giftcardsState?.selectedCountry?.country_name, giftcardsState?.countries]
  );

  const onGiftCardsUnitAction = useCallback(
    data => {
      dispatch(giftCardsUnitAction(data));
    },
    [dispatch]
  );

  const onGiftCardThemeAction = useCallback(() => {
    dispatch(giftCardThemeAction());
  }, [dispatch]);

  const onSelectCountry = useCallback(
    country => {
      dispatch(giftCardsAction.selectCountry(country));
    },
    [dispatch]
  );

  return {
    onGiftCardsUnitAction,
    onGiftCardThemeAction,
    onSelectCountry,
    currentCountryName,
    ...giftcardsState,
  };
};
