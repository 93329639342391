import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Emailicon from '../../../assets/media/icons/Email-icon.svg';
import Usericon from '../../../assets/media/icons/User-icon.svg';
import Passwordicon from '../../../assets/media/icons/Password-icon.svg';
import { ROUTES } from '../../../routes';
import { useTranslation } from 'react-i18next';
import Gift from '../../redeemCard/components/Gift';
import RewardsByLogo from '../../../assets/media/icons/rewardsby-logo.svg';
import { useSignupHook } from '../hooks/useSignupHook';
import SocialButtons from './SocialButtons';
import SignUpDivider from './SignUpDivider';

const SignUpRedeem = () => {
  const { t } = useTranslation();
  const {
    formik,
    togglePass,
    eye,
    showPass,
    authErrors,
    giftcardNumber,
  } = useSignupHook();

  return (
    <Container className="redeem">
      <div className="d-flex justify-content-center signup-redeem-container">
        <div className="login-redeem-card">
          <p className="login-text text-center h3">{t('auth.signUp.title')}</p>

          {giftcardNumber ? <Gift giftcardNumber={giftcardNumber} /> : null}

          <Form onSubmit={formik.handleSubmit} className="user">
            <Row>
              <Col className="name-input">
                <Form.Group controlId="formBasicText" className="singup-input icons_login form-group-custom">
                  <Form.Control
                    size="md"
                    type="text"
                    placeholder={t('auth.signUp.form.firstName')}
                    className="icons_fields"
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    name="first_name"
                  />
                  <img src={Usericon} alt="Icon" className="icon_img" />
                </Form.Group>
              </Col>
              <Col className="lastname-input">
                <Form.Group controlId="formBasiclast-name" className="singup-input icons_login form-group-custom">
                  <Form.Control
                    size="md"
                    type="text"
                    placeholder={t('auth.signUp.form.lastName')}
                    className="icons_fields"
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    name="last_name"
                  />
                  <img src={Usericon} alt="Icon" className="icon_img" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                {formik.errors.first_name ? <p className="validation-messages">{formik.errors.first_name}</p> : null}
              </Col>
              <Col>
                {formik.errors.last_name ? <p className="validation-messages">{formik.errors.last_name}</p> : null}
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="formBasicEmail6" className="icons_login form-group-custom">
                  <Form.Control
                    size="md"
                    type="email"
                    placeholder={t('auth.signUp.form.enterEmail')}
                    className="icons_fields"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    name="email"
                  />
                  <img src={Emailicon} alt="Icon" className="icon_img" />
                </Form.Group>
                {formik.errors.email ? <p className="validation-messages">{formik.errors.email}</p> : null}

                <Form.Group controlId="formBasicPassword" className="icons_login form-group-custom">
                  <Form.Control
                    size="md"
                    type={showPass ? 'text' : 'password'}
                    placeholder={t('common.password')}
                    className="icons_fields"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    name="password"
                  />
                  <img src={Passwordicon} alt="Icon" className="icon_img" />
                  <i onClick={togglePass} className="icon_right">
                    {eye}
                  </i>{' '}
                </Form.Group>
                {formik.errors.password ? <p className="validation-messages">{formik.errors.password}</p> : null}

                <Form.Group controlId="formBasic-confirmPassword" className="icons_login form-group-custom">
                  <Form.Control
                    size="md"
                    type={showPass ? 'text' : 'password'}
                    placeholder={t('auth.signUp.form.confirmPassword')}
                    className="icons_fields"
                    value={formik.values.password_confirmation}
                    onChange={formik.handleChange}
                    name="password_confirmation"
                  />
                  <i onClick={togglePass} className="icon_right">
                    {eye}
                  </i>{' '}
                  <img src={Passwordicon} alt="Icon" className="icon_img" />
                </Form.Group>
                {formik.errors.password_confirmation ? (
                  <p className="validation-messages">{formik.errors.password_confirmation}</p>
                ) : null}
                {authErrors && authErrors.length ? (
                  <p className="validation-messages">{authErrors.join('\n')}</p>
                ) : null}

                <Button className="btn-custom" variant="info" size="lg" type="submit">
                  {t('auth.common.signUpText')}
                </Button>
                <SignUpDivider logInUrl={ROUTES.auth.logInRedeem} />
                <SocialButtons />
              </Col>
            </Row>
          </Form>
        </div>
        <div className="signup-redeem-info">
          <img src={RewardsByLogo} alt="" className="mb-4" />
          <p className="welcome-title mb-3">{t('auth.signUp.messages.redeemWelcome')}</p>
          <p className="welcome-text">{t('auth.logIn.messages.redeemWelcome')}</p>
        </div>
      </div>
    </Container>
  );
};

export default SignUpRedeem;
