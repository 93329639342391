import { createAsyncThunk } from '@reduxjs/toolkit';
import { loginAPI, signupAPI, resetpasswordAPI, forgotpasswordAPI, updatepasswordAPI, logoutAPI } from '../api';

import { pageLoaderActions } from '../../../shared/loaders/pageLoader/redux/page-loader.reducer';

export const loginAction = createAsyncThunk('auth/login', async (payload, thunkAPI) => {
  const { dispatch } = thunkAPI;
  dispatch(pageLoaderActions.setPageLoadingAction(true));

  const request = {
    signin: { ...payload },
  };
  const response = await loginAPI(request, payload.recaptcha);
  dispatch(pageLoaderActions.setPageLoadingAction(false));
  return response;
});
export const signupAction = createAsyncThunk('auth/signup', async (payload, thunkAPI) => {
  const { dispatch, rejectWithValue } = thunkAPI;
  try {
    dispatch(pageLoaderActions.setPageLoadingAction(true));
    const request = {
      signup: {
        first_name: payload.first_name,
        last_name: payload.last_name,
        email: payload.email,
        password: payload.password,
        password_confirmation: payload.password_confirmation,
      },
    };

    const response = await signupAPI(request);
    dispatch(pageLoaderActions.setPageLoadingAction(false));
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }

});

export const resetPasswordAction = createAsyncThunk('auth/resetpassword', async (payload, thunkAPI) => {
  const { dispatch } = thunkAPI;
  dispatch(pageLoaderActions.setPageLoadingAction(true));
  const request = {
    change_password: {
      password: payload.password,
      password_confirmation: payload.password_confirmation,
    },
    token: payload.token,
  };

  const response = await resetpasswordAPI(request);
  dispatch(pageLoaderActions.setPageLoadingAction(false));
  return response;
});

export const updatePasswordAction = createAsyncThunk('auth/updatepassword', async (payload, thunkAPI) => {
  const { dispatch } = thunkAPI;
  dispatch(pageLoaderActions.setPageLoadingAction(true));
  const request = {
    change_password: {
      password: payload.password,
      password_confirmation: payload.password_confirmation,
    },
  };

  const response = await updatepasswordAPI(request);
  dispatch(pageLoaderActions.setPageLoadingAction(false));
  return response;
});

export const forgotPasswordAction = createAsyncThunk('auth/forgotpassword', async (payload, thunkAPI) => {
  const { dispatch } = thunkAPI;
  dispatch(pageLoaderActions.setPageLoadingAction(true));
  const request = {
    email: payload.email
  };

  const response = await forgotpasswordAPI(request);
  dispatch(pageLoaderActions.setPageLoadingAction(false));
  return response;
});

export const logoutAction = createAsyncThunk('auth/logout', async (payload, thunkAPI) => {
  const { dispatch } = thunkAPI;
  dispatch(pageLoaderActions.setPageLoadingAction(true));
  let response = null;
  if (payload?.callAPI) {
    response = await logoutAPI();
  }
  dispatch(pageLoaderActions.setPageLoadingAction(false));
  return response;
});
