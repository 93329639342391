import React from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';

import CardsCategories from '../../../cards/components/CardsCategories';
import GiftCardsByCountry from "./GiftCardsByCountry";

const AllGiftCards = ({ cardsLimit }) => {
  const { t } = useTranslation();
  return (
    <>
      <Container className="px-1 px-sm-3 px-md-4 mx-0 mx-sm-auto">
        <Row className="allGiftCard-box width-limit">
          <Col className="px-0 px-lg-2">
            <h3 className="giftiallcard-text-a">{t('home.browseByCategory.heading')}</h3>
            <CardsCategories />
          </Col>
        </Row>
      </Container>
      <Container className="flex-column mx-auto my-0 my-md-2 my-lg-4 full-width">
        <GiftCardsByCountry cardsLimit={cardsLimit}/>
      </Container>
    </>
  );
};

export default AllGiftCards;

AllGiftCards.propTypes = {
  cardsLimit: PropTypes.number,
};
