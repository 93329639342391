import React from 'react';
import { get } from 'lodash';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';

function CategoryCard({ category, active }) {
  const { name, image } = category;
  const normal_square = get(image, 'normal_square');
  //NOTE: create a default image to dispaly if normal image is null
  return (
    <>
      <Col xs={12} className="px-0 d-flex justify-content-center">
        <img src={normal_square} alt="Icon" className="img-category" />
      </Col>
      <Col xs={12} className="px-0">
        <p className={`products_icons mb-0 ${active ? 'text-white' : 'text-dark'}`}>{name}</p>
      </Col>
    </>
  );
}
export default CategoryCard;

CategoryCard.propTypes = {
  category: PropTypes.shape({
    name: PropTypes.string,
    image: PropTypes.object,
  }),
  active: PropTypes.string,
};
