/* eslint-disable no-unused-vars */

import { createAsyncThunk } from '@reduxjs/toolkit';
import { pageLoaderActions } from '../../../shared/loaders/pageLoader/redux/page-loader.reducer';
import { getCountriesAPI } from '../api';
import { giftCardsUnitAction } from '../../cards/redux/actions';

export const getCountriesListAction = createAsyncThunk('topbar/countrylist/get', async (payload = {}, thunkAPI) => {
  const { dispatch } = thunkAPI;
  dispatch(pageLoaderActions.setPageLoadingAction(true));
  const response = await getCountriesAPI();
  dispatch(giftCardsUnitAction());
  dispatch(pageLoaderActions.setPageLoadingAction(false));
  return response;
});
