import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Fade from 'react-bootstrap/Fade';
import Alert from 'react-bootstrap/Alert';

import Passwordicon from '../../../assets/media/icons/Password-icon.svg';
import checkbox from '../../../assets/media/icons/checkbox.svg';
import { useAuth } from '../../auth/hooks/useAuth';
import swal from "sweetalert";

const UpdatePassword = () => {
  const { t } = useTranslation();
  const { message: authMessage, onClearErrors, onUpdatePassword, errors } = useAuth();
  const [isValid, setIsValid] = useState(false);
  const [visible, setVisible] = useState(true);
  const [message, setMessage] = useState('');

  const formik = useFormik({
    initialValues: {
      password: '',
      password_confirmation: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, `${t('validation:minFieldLength')} 8`)
        .max(200, `${t('validation:maxFieldLength')} 200`)
        .required(t('validation:requiredField')),
      password_confirmation: Yup.string()
        .when("password", {
        is: val => (val && val.length > 0),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Both passwords need to be same"
        )
      })
        .min(8, `${t('validation:minFieldLength')} 8`)
        .max(200, `${t('validation:maxFieldLength')} 200`)
        .required(t('validation:requiredField')),
    }),
    onSubmit: data => {
      onUpdatePassword(data)
      formik.resetForm();
    },
  });

  useEffect(() => {
    let visibleTimeout
    const passwordUpdated = authMessage === 'Password updated successfully!'
    if (passwordUpdated) {
      setIsValid(true);
      setMessage('Password updated successfully!');
      visibleTimeout = window.setTimeout(() => {
        setVisible(false);
        onClearErrors();
      }, 3000);
    }
    if(errors) {
      swal({
        title: '',
        icon: 'error',
        text: errors[0],
        type: '',
        allowEscapeKey: false,
        showConfirmButton: true,
        showCancelButton: false,
        confirmButtonColor: '#00AF9A',
      });
      onClearErrors();
    }
    return () => {
      passwordUpdated && clearTimeout(visibleTimeout)
    }

  }, [authMessage, onClearErrors]);

  return (
    <>
      <div>
        {isValid ? (
          <Fade>
            <Alert variant="info" transition={visible}>
              <img src={checkbox} className="me-3" style={{ width: '30px' }} alt="Icon" />
              {message}
            </Alert>
          </Fade>
        ) : (
          <></>
        )}
      </div>
      <div className="forgot-password mx-auto">
        <p className="login-text text-center h3">{t('profile.enterYourPassword')}</p>
        <Form className="mt-4" onSubmit={formik.handleSubmit}>
          <Form.Group controlId="formBasicnew_password" className="icons_login form-group-custom">
            <Form.Control
              size="lg"
              type="password"
              placeholder={t('profile.newPassword')}
              className="icons_fields form-group-custom"
              value={formik.values.password}
              onChange={formik.handleChange}
              name="password"
            />
            <img src={Passwordicon} alt="Icon" className="icon_img" />
          </Form.Group>
          {formik.touched.password && formik.errors.password ? (
            <p className="validation-messages">{formik.errors.password}</p>
          ) : null}

          <Form.Group controlId="formBasicconfirm_password" className="form-group-custom icons_login">
            <Form.Control
              size="lg"
              type="password"
              placeholder={t('profile.confirmPassword')}
              className="icons_fields"
              value={formik.values.password_confirmation}
              onChange={formik.handleChange}
              name="password_confirmation"
            />
            <img src={Passwordicon} alt="Icon" className="icon_img" />
          </Form.Group>
          {formik.touched.password_confirmation && formik.errors.password_confirmation ? (
            <p className="validation-messages">{formik.errors.password_confirmation}</p>
          ) : null}
          <Button className="btn-custom mt-3" variant="info" size="lg" type="submit">
            {t('common.submit')}
          </Button>
        </Form>
      </div>
    </>
  );
};

export default UpdatePassword;
