import GiveawaySplashScreenImg from '../../../assets/media/images/giveaway-splash-screen.png';
import SmallSuccessfulCheckMark from '../../../assets/media/icons/small-successful-check-mark.png';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useTranslation} from "react-i18next";
import React from "react";
import ImaEuropeLogo from "../../../assets/media/images/ima-europe-logo.png";

export const SplashScreen = ({isDisplaying}) => {
    const {t} = useTranslation();

    return (
        <div className={"splash-root " + (!isDisplaying ? "hidden" : "")}>
            <div className="splash-screen">
                <Row>
                    <Col className="p-0">
                        <img className="ima-europe-logo" src={ImaEuropeLogo} alt={t('giveaway.imaEuropeLogo')} width={315} height={157}/>

                        <div className="splash-info">
                            <h1 className="title">{t('giveaway.splash.title')}</h1>
                            <p className="subtitle">{t('giveaway.splash.subtitle')}</p>
                            <img className="successful-check-mark" src={SmallSuccessfulCheckMark} alt={t('giveaway.successfulCheckMark')} width={41}
                                 height={41}/>
                            <p className="date">{t('giveaway.splash.date')}</p>
                            <p className="time">09:45 – 19:30 CEST</p>
                        </div>
                    </Col>
                    <Col className="splash-image">
                        <img src={GiveawaySplashScreenImg} alt={t('giveaway.splash.screen')}/>
                    </Col>
                </Row>

                <p className="footer-text"><span
                        className="font-weight-bold">{t('giveaway.splash.location')}:</span> {t('giveaway.splash.footerText')}
                </p>

                <div className="footer-line"/>
            </div>
        </div>
    );
}
