import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { commonActions, getCommonState } from '../redux/reducer';
import { contactUsAction } from '../redux/actions';

export const useCommon = () => {
  const dispatch = useDispatch();
  const commonState = useSelector(getCommonState);

  const onContactUsAction = useCallback(
    data => {
      dispatch(contactUsAction(data));
    },
    [dispatch]
  );

  const onUpdateContactAction = useCallback(
    contact => {
      dispatch(commonActions.updateContact(contact));
    },
    [dispatch]
  );

  const onSetUserCommonAction = useCallback(
    user => {
      dispatch(commonActions.setUser(user));
    },
    [dispatch]
  );

  const onSetGiftingToUser = useCallback(
    giftingToUser => {
      dispatch(commonActions.setGiftingToUser(giftingToUser));
    },
    [dispatch]
  );

  return {
    onContactUsAction,
    onUpdateContactAction,
    onSetUserCommonAction,
    onSetGiftingToUser,
    ...commonState,
  };
};
