import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

import ButtunDelete from '../../../assets/media/icons/Button-Delete.svg';
import { useTranslation } from 'react-i18next';

const getImageSrcFromCartItem = item => {
  const images = item?.images;
  if (!images) return '';
  return (
    images?.medium_rectangle ||
    images?.medium_square ||
    images?.normal_rectangle ||
    images?.normal_square ||
    images?.small_rectangle ||
    images?.small_square ||
    images?.thumb_rectangle ||
    images?.thumb_square ||
    images?.xsmall_rectangle ||
    ''
  );
};

const CartItem = props => {
  const { item, decrementQuantity, incrementQuantity, removeItem } = props;

  const imageSrc = useMemo(() => item.giftcard_image || getImageSrcFromCartItem(item), [item]);

  const {t} = useTranslation()

  return (
    <div className="item-list">
      <Row className="align-items-center">
        <Col md={3}>
          <Image
            src={imageSrc}
            rounded
            style={{
              width: '100%',
              height: 'auto',
              objectFit: 'contain',
            }}
          />
        </Col>
        <Col md={9}>
          <div className="item-details">
            <p>
              <b>{item?.name || item?.product_name || ''}</b>
            </p>
            <p>Gifting for: {item?.isforself ? 'My Self' : 'Others'} </p>
            <div className="d-flex justify-content-between align-items-center mt-3 me-2">
              <div className="cart-inc-dec-box px-1">
                <button className="btn btn-link text-decoration-none" onClick={() => decrementQuantity(item)}>
                  <span>-</span>
                </button>
                <span className="mx-2">{item?.quantity}</span>
                <button className="btn btn-link text-decoration-none" onClick={() => incrementQuantity(item)}>
                  <span>+</span>
                </button>
              </div>
              <span className="count-name">{item?.country_name}</span>
              <span className="count-symbol">
                {item?.currency} {item?.giftcard_value || 0}
              </span>
              <Image
                src={ButtunDelete}
                style={{
                  width: '5%',
                  height: '5%',
                  cursor: 'pointer',
                }}
                onClick={() => removeItem(item)}
              />
            </div>
          </div>
        </Col>
      </Row>
      {
        item?.is_redeemable_for_gg_points ? 
        <span className='text-danger'>{t('cart.errorMessage.productPurchase')}</span> : null
      }
    </div>
  );
};

export default CartItem;

CartItem.propTypes = {
  decrementQuantity: PropTypes.func,
  incrementQuantity: PropTypes.func,
  item: PropTypes.object,
  removeItem: PropTypes.func,
};
