import React from 'react';
import AllFeaturedCards from './components/AllFeaturedCards';
import AllGiftCards from '../home/components/AllGiftCards/AllGiftCards';

const AllCards = () => (
  <div className="allgiftcards-sec">
    <AllFeaturedCards />
    <AllGiftCards />
  </div>
);

export default AllCards;
