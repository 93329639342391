import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Emailicon from '../../../assets/media/icons/Email-icon.svg';
import { Button, Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useHistory } from 'react-router';
import { useEffect } from 'react';
import { ROUTES } from '../../../routes';
import { useAuth } from '../hooks/useAuth';
import { useTranslation } from 'react-i18next';

const ForgotPassword = () => {
  const { t } = useTranslation();
  const { onForgotPassword, status, errors, onClearErrors } = useAuth();
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .min(2, `${t('validation:minFieldLength')} 2`)
        .max(200, `${t('validation:maxFieldLength')} 200`)
        .email(t('validation:invalidEmail'))
        .required(t('validation:requiredField')),
    }),
    onSubmit: data => {
      onForgotPassword({
        email: data.email,
      });
    },
  });

  useEffect(() => {
    if (status === 'OK') {
      history.push({ pathname: ROUTES.home });
    }
  });

  useEffect(() => {
    onClearErrors();
  }, []);

  return (
    <>
      <Container>
        <Row>
          <Col>
            <div className="forgot-password mx-auto">
              <p className="login-text text-center h3">{t('auth.forgotPassword.title')}?</p>
              <p className="text-center mt-0">
                <small>{t('auth.forgotPassword.subtitle')}</small>
              </p>

              <Form onSubmit={formik.handleSubmit}>
                <Form.Group controlId="formBasicEmail2" className="icons_login">
                  <Form.Control
                    size="md"
                    type="email"
                    placeholder={t('common.enterEmail')}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    className="icons_fields"
                    name="email"
                  />
                  <img src={Emailicon} alt="Icon" className="icon_img" />
                </Form.Group>
                {formik.touched.email && formik.errors.email ? (
                  <p className="validation-messages">{formik.errors.email}</p>
                ) : null}
                {errors && errors.length ? <p className="validation-messages">{errors.join('\n')}</p> : null}

                <Button className="btn-custom" variant="info" size="lg" type="Submit">
                  {t('auth.forgotPassword.resetMyPassword')}
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ForgotPassword;
