import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useFormik } from 'formik';
import swal from 'sweetalert';
import { Dropdown } from 'primereact/dropdown';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';

import { useGiftcards } from '../../cards/hooks/useGiftcards';
import { useProfile } from '../hooks/useProfile';
import { useAuth } from '../../auth/hooks/useAuth';

import Emailicon from '../../../assets/media/icons/Email-icon.svg';
import Usericon from '../../../assets/media/icons/User-icon.svg';
import Calendericon from '../../../assets/media/icons/calendar-alt.svg';
import Phoneicon from '../../../assets/media/icons/phone-alt.svg';

import PhoneCodesByCountry from '../../../constants/phone-codes-by-country.json';
import Languages from '../../../constants/languages.json';
import * as Yup from 'yup';

const getCountryCodeNumberFromItsName = countryName => {
  if (!countryName) return '';

  const countryCodeNumber = PhoneCodesByCountry?.[countryName];
  if (!countryCodeNumber) return '';

  return countryCodeNumber;
};

const getFullPhoneNumberByCountryName = (phoneNumberWithoutCountryCode, countryName) => {
  const countryCodeNumber = getCountryCodeNumberFromItsName(countryName);

  return countryCodeNumber + phoneNumberWithoutCountryCode;
};

const getPhoneNumberWithoutCountryCode = (fullPhoneNumber, countryName) => {
  if (!fullPhoneNumber || !countryName) return '';
  const countryCodeNumber = getCountryCodeNumberFromItsName(countryName);

  return fullPhoneNumber.replace(countryCodeNumber, '');
};

const UserProfile = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();
  const { countries } = useGiftcards();
  const {
    onGetProfileListAction,
    onUpdateUserProfileAction,
    onClearProfileErrors,
    onGetPhoneLengthByCountryNameAction,
    profile,
    phone_max_length,
    phone_min_length,
    country_code,
    profile_updated,
    error: profileError,
  } = useProfile();

  const handleSuccess = () => {
    swal({
      title: '',
      icon: 'success',
      text: t('profile.weHaveSuccessfullyEditedYourDetails'),
      type: '',
      allowEscapeKey: false,
      showConfirmButton: true,
      showCancelButton: false,
      confirmButtonColor: '#00AF9A',
    });
  };

  const handleFailure = errorMessage => {
    swal({
      title: '',
      icon: 'error',
      text: errorMessage,
      type: '',
      allowEscapeKey: false,
      showConfirmButton: true,
      showCancelButton: false,
      confirmButtonColor: '#00AF9A',
    });
  };

  useEffect(() => {
    if (isAuthenticated) {
      onGetProfileListAction();
      onClearProfileErrors();
    }
  }, [onGetProfileListAction, onClearProfileErrors]);

  useEffect(() => {
    if (profile_updated) {
      handleSuccess();
      onClearProfileErrors();
    }
    if (profileError !== null) {
      handleFailure(profileError);
      onClearProfileErrors();
    }
  }, [onClearProfileErrors, onClearProfileErrors, profile_updated, profileError]);

  const formik = useFormik({
    initialValues: {
      firstName: profile?.first_name || '',
      lastName: profile?.last_name || '',
      dob: profile?.birthday || null,
      language: profile?.language || '',
      country: profile?.nationality || '',
      phone: getPhoneNumberWithoutCountryCode(profile?.phone, profile?.nationality),
      gender: profile?.gender || '',
      email: profile?.email || '',
    },
    validationSchema: Yup.object({
      phone: Yup.string()
        .min(phone_min_length, `${t('validation:minFieldLength')} ${phone_min_length}`)
        .max(phone_max_length, `${t('validation:maxFieldLength')} ${phone_max_length}`),
    }),
    onSubmit: data => {
      const payload = {
        ...data,
        phone: getFullPhoneNumberByCountryName(data.phone.replace(/[()\-_ ]/g, ''), data.country),
      };
      onUpdateUserProfileAction(payload);
    },
    validateOnChange: false,
  });

  const handlePhoneNumChange = event => {
    const value = event?.target?.value;
    formik.setFieldValue('phone', value);
  };

  const handleLanguageChange = event => {
    const value = event?.target?.value?.name;
    formik.setFieldValue('language', value);
  };

  const handleCountryChange = event => {
    formik.setFieldValue('country', event?.value?.country_name);
    const payload = {
      countryName: event?.value?.country_name,
    };
    onGetPhoneLengthByCountryNameAction(payload);
    formik.setFieldValue('phone', getPhoneNumberWithoutCountryCode(profile?.phone, profile?.nationality));
  };

  return (
    <Container>
      <Row>
        <Col xs={12} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
          <div className="profile-card">
            <Form className="profile-form" onSubmit={formik.handleSubmit}>
              <Row>
                <Form.Group as={Col} xs={12} md={6} controlId="firstName" className="icons_login">
                  <Form.Control
                    size="sm"
                    type="text"
                    placeholder={t('profile.firstName')}
                    className="profile-iconsfields"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    name="firstName"
                  />
                  <img src={Usericon} alt="Icon" className="profile_icon" />
                </Form.Group>

                <Form.Group as={Col} xs={12} md={6} controlId="lastName" className="icons_login">
                  <Form.Control
                    size="sm"
                    type="text"
                    placeholder={t('profile.lastName')}
                    className="profile-iconsfields-a"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    name="lastName"
                  />
                </Form.Group>
              </Row>

              <Form.Group controlId="dob" className="position-relative form-group-custom">
                <Form.Control
                  size="sm"
                  type="date"
                  className="profile-iconsfields"
                  value={formik.values.dob}
                  onChange={formik.handleChange}
                  name="dob"
                />
                <img src={Calendericon} alt="Icon" className="profile_icon" />
              </Form.Group>

              <Form.Group controlId="language" className="mx-auto form-group-custom">
                <Dropdown
                  name="language"
                  className="w-100"
                  value={formik.values.language}
                  options={Languages}
                  onChange={handleLanguageChange}
                  filter
                  style={{ paddingLeft: '.25rem', height: '46px' }}
                  filterBy="name"
                  placeholder={formik.values.language || t('profile.language')}
                  optionLabel="name"
                />
              </Form.Group>

              <Form.Group controlId="country" className="form-group-custom mx-auto">
                <Dropdown
                  className="w-100"
                  value={formik.values.country}
                  options={countries}
                  onChange={handleCountryChange}
                  filter
                  style={{ paddingLeft: '.25rem', height: '46px' }}
                  filterBy="country_name"
                  placeholder={formik.values.country || t('profile.pleaseSelect')}
                  optionLabel="country_name"
                />
              </Form.Group>

              <Form.Group controlId="phone" className="form-group-custom position-relative d-flex">
                <Form.Control
                  size="sm"
                  type="text"
                  className="country-code"
                  value={country_code ? `+${country_code}` : getCountryCodeNumberFromItsName(profile?.nationality)}
                  readOnly
                />

                <Form.Control
                  size="sm"
                  type="number"
                  name="phone"
                  className="phone-num-mask"
                  style={{ height: '46px' }}
                  value={formik.values.phone}
                  onChange={handlePhoneNumChange}
                />

                <img src={Phoneicon} alt="Icon" className="profile_icon" />
              </Form.Group>

              {formik.errors.phone ? <p className="validation-messages">{formik.errors.phone}</p> : null}

              <Form.Group controlId="email" className="form-group-custom position-relative">
                <Form.Control
                  size="sm"
                  type="email"
                  placeholder={t('common.email')}
                  className="profile-iconsfields"
                  style={{ height: '46px' }}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  name="email"
                  readOnly
                />
                <img src={Emailicon} alt="Icon" className="profile_icon" />
              </Form.Group>

              <div className="text-center">
                <Button className="profile-btn width-limit" variant="info" size="lg" type="submit">
                  {t('profile.editProfile')}
                </Button>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default UserProfile;
