import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SignUpDivider = ({logInUrl}) => {
  const { t } = useTranslation();
  return (
    <>
      <p className="login-bottom-text text-center">
        {t('auth.signUp.form.alreadyHaveAnAccount')} ?{' '}
        <Link to={logInUrl}>{t('auth.common.logInText')}</Link>
      </p>
      <div className="auth-divider-container">
        <div className="divider" />
        <p className="text"> {t('auth.signUp.form.orSignUpWith')}</p>
        <div className="divider" />
      </div>
    </>
  );
};

export default SignUpDivider;
