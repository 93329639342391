import { api } from '../../../api';

export const loginAPI = (userData, recaptcha) => {
  if (recaptcha) {
    return api.post(
      `/v1/accounts/sessions/signin`,
      userData,
      { headers: { 'recaptcha-response': recaptcha } }
    );
  }
  return api.post(`/v1/accounts/sessions/signin`, userData);
};

export const signupAPI = userData => {
  return api.post(`/v1/accounts/registrations/signup`, userData);
};

export const resetpasswordAPI = userData => {
  return api.put(`/v1/accounts/passwords/update`, userData);
};

export const updatepasswordAPI = async userData => {
  return api.put(`/v1/accounts/passwords/change_password`, userData);
};

export const forgotpasswordAPI = userData => {
  return api.post(`/v1/accounts/passwords/new`, userData);
};

export const logoutAPI = () => {
  return api.delete(`/v1/accounts/sessions/signout`);
};
