import React from 'react';
import { useTranslation } from 'react-i18next';

import { ColumnsTitle } from './ColumnsTitle';
import { RowDataPropType } from '../../propTypes/RowData';

export const PaidAmountColumn = ({ rowData }) => {
  const { t } = useTranslation();

  return (
    <>
      <ColumnsTitle title={t('orders.amountPaid')} />
      <p className="text_order">
        {rowData.payment_currency} {rowData.payment_currency_amount}
      </p>
    </>
  );
};

PaidAmountColumn.propTypes = {
  rowData: RowDataPropType,
};
