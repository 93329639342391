import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUserActiveState } from '../redux/useractive.reducer';
import { getUserActiveAction } from '../redux/useractive.actions';

export const useActiveUser = () => {
  const dispatch = useDispatch();
  const activeUserState = useSelector(getUserActiveState);

  const onGetUserActiveAction = useCallback(
    data => {
      dispatch(getUserActiveAction(data));
    },
    [dispatch]
  );

  return {
    onGetUserActiveAction,
    ...activeUserState,
  };
};
