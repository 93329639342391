import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Col from 'react-bootstrap/Col';

import { getCountryIdFromName } from '../../../utils';
import { useOrders } from '../../orders/hooks/useOrders';
import { useTopBar } from '../../topBar/hooks/useTopBar';

import Usericon from '../../../assets/media/icons/User-icon.svg';
import Emailicon from '../../../assets/media/icons/Email-icon.svg';
import {useTranslation} from "react-i18next";
import { useCookie } from "../../../utils/useCookie";

const GuestForm = props => {
  const { t } = useTranslation();
  const { cartState } = props;
  const { onSetGuestPayload } = useOrders();
  const { countries } = useTopBar();
  const { getCookie } = useCookie()

  const getMarginAmount = amount => {
    const additionalCharge = (parseFloat(amount) * 5) / 100;
    return parseFloat(additionalCharge + parseFloat(amount)).toFixed(2);
  };

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
    },
    validationSchema: Yup.object({
      first_name: Yup.string()
        .max(500, `${t('validation:maxFieldLength')} 500`)
        .required(t('validation:requiredField')),
      last_name: Yup.string()
        .max(500, `${t('validation:maxFieldLength')} 500`)
        .required(t('validation:requiredField')),
      email: Yup.string()
        .min(2, `${t('validation:minFieldLength')} 2`)
        .max(500, `${t('validation:maxFieldLength')} 500`)
        .email(t('validation:invalidEmail'))
        .required(t('validation:requiredField')),
    }),
    onSubmit: data => {
      const tduid = getCookie('TRADEDOUBLER')
      const payload = {
        user: {
          first_name: data.first_name,
          email: data.email,
          last_name: data.last_name,
        },
        giftcard: cartState.lineItems.map(lineItem => {
          return {
            ...lineItem,
            country_id: lineItem?.country_id || getCountryIdFromName(countries, lineItem?.country_name),
            card_value_aed:
              lineItem.currency !== cartState.checkoutCart.currency
                ? getMarginAmount(lineItem.card_value_aed)
                : lineItem.card_value_aed,
          };
        }),
        order: {
          order_total_aed: cartState.checkoutCart.total_amount,
          program_id: 1,
          payment_currency: cartState.checkoutCart.currency,
          isforself: true,
          isbuynow: false,
          card_value_aed: null,
          use_credits: false,
          current_exchange_rate: parseFloat(cartState.conversion.currency_exchange_rate).toFixed(2),
          use_hassad_points: false,
          language_code: 'en',
          currency: cartState.checkoutCart.currency_id,
          is_myworld: !!tduid
        },
      };
      onSetGuestPayload(payload);
      const nextButton = document.getElementsByClassName('cart-next-btn')[0];
      nextButton.click();
    },
  });

  return (
    <div className="container mx-auto mt-5">
      <Col xs={12} md={{ span: 6, offset: 3 }}>
        <div className="card p-3 p-md-4">
          <p className="login-text text-center h3">{t('cart.widget.checkoutAsGuest')}</p>
          <p className="login-sub-text text-center mt-0">
            <small>{t('auth.common.enterToContinueText')}</small>
          </p>

          <Form className="user" onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <Form.Group controlId="formBasicText" className="singup-input icons_login form-group-custom">
                  <Form.Control
                    size="md"
                    type="text"
                    placeholder={t('auth.common.firstName')}
                    className="icons_fields"
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    name="first_name"
                    isInvalid={formik.touched.first_name && formik.errors.first_name}
                  />
                  <img src={Usericon} alt="Icon" className="icon_img" />
                </Form.Group>
              </div>
              <div className="col-sm-12 col-md-6">
                <Form.Group controlId="formBasiclast-name" className="singup-inputfield form-group-custom">
                  <Form.Control
                    size="lg"
                    type="text"
                    placeholder={t('auth.common.lastName')}
                    className="icons_fields_b"
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    name="last_name"
                    isInvalid={formik.touched.last_name && formik.errors.last_name}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <Form.Group controlId="formBasicEmail4" className="icons_login form-group-custom">
                  <Form.Control
                    size="md"
                    type="email"
                    placeholder={t('common.enterEmail')}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    className="icons_fields"
                    name="email"
                    isInvalid={formik.touched.email && formik.errors.email}
                  />
                  <img src={Emailicon} alt="Icon" className="icon_img" />
                </Form.Group>
              </div>
            </div>
            <Button className="btn-custom my-3" variant="info" size="lg" type="submit">
              Next
            </Button>
          </Form>
        </div>
      </Col>
    </div>
  );
};

export default GuestForm;

GuestForm.propTypes = {
  cartState: PropTypes.object,
  orderActions: PropTypes.object,
  orderState: PropTypes.object
};
