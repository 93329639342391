import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Footer, Loaders, Navbar } from '../../index';
import { useLocation } from 'react-router-dom';

export const MainLayout = ({ children, externalRoutes }) => {
  const { pathname } = useLocation();
  const isExternal = useMemo(() => externalRoutes.includes(pathname), [pathname, externalRoutes]);

  return (
    <>
      {!isExternal && <Navbar />}
      <Loaders.Page />
      <div className="App-container">{children}</div>
      {!isExternal && <Footer />}
    </>
  );
};

MainLayout.propTypes = {
  children: PropTypes.element,
};
