import { getConversionRateService, getFixerConvertedAmount } from "../cards/api";

const convertPaymentCurrency = async order => {
  const res = await getFixerConvertedAmount(order.giftcard_value, order.unit_name, "AED");
  const convertedAmount = Number(res.data.converted_amount)
  // Get commision 5% if there was a conversion
  return order.unit_name === "AED" ? convertedAmount :
    convertedAmount + (convertedAmount * 5 / 100)
}

// converts all giftcard_value to AED currency
const convertToAED = async (orders) => Promise.all(orders.map(async order => {
  const convertedPaymentCurrency = await convertPaymentCurrency(order) * order.quantity
  return ({
    credits_used: order.credits_used,
    giftcard_value: parseFloat(convertedPaymentCurrency).toFixed(2),
    payment_currency: order.payment_currency,
    card_currency: order.unit_name,
    quantity: order.quantity,
    brand_name: order.brand_name,
    id: order.id,
    orderid: order.orderid,
    event_id: order.event_id,
    myworld_commission: order.myworld_commission
  });
}))

const calculatePoints = (points, currencies) => {
  const eachCardPoints = points / currencies.length

  return currencies.reduce((sum, currency) => {
    return currency === "AED" ? sum + eachCardPoints : sum + (eachCardPoints + eachCardPoints * 5 / 100)
  }, 0)
}

const substractPoints = (orders) => {
  let points = calculatePoints(
    Number(orders[0].credits_used),
    orders.map(order => order.unit_name)
  )
  if (!points) return orders
  let ordersToSubstract = orders // JSON.parse(JSON.stringify(orders)) # for deep copy
  let iteration = 0
  while (points > 0) {
    iteration += 1
    if (!ordersToSubstract.length) break
    const substract = points / ordersToSubstract.length
    points -= substract * ordersToSubstract.length
    ordersToSubstract.forEach(item => {
      const valueAfterSubstract = parseFloat(Number(item.giftcard_value) - substract).toFixed(2)
      if (valueAfterSubstract < 0) {
        item.giftcard_value = 0
        points += Math.abs(valueAfterSubstract)
        ordersToSubstract = ordersToSubstract.filter(order => order.id !== item.id)
        return
      }
      item.giftcard_value = valueAfterSubstract
    })
  }
  return ordersToSubstract
}

const convertPricesToFinalCurrency = async (orders) => {
  const currencyRate = orders[0].payment_currency !== "AED" && await getConversionRateService({ currency: orders[0].payment_currency })
  return orders.map(order => {
    order.giftcard_value  = order.payment_currency === "AED" ?
      order.giftcard_value : order.giftcard_value * parseFloat(currencyRate.data.currency_exchange_rate).toFixed(2)
    return order
  })
}


const TrackingPixel = async (orders, trackingPixel, tduid) => {

  const ordersPricesInAED = await convertToAED(orders)
  const ordersWithSubstractedPoints = substractPoints(ordersPricesInAED)
  const ordersPricesInFinalCurrency = await convertPricesToFinalCurrency(ordersWithSubstractedPoints)

  const ordersWithComission = ordersPricesInFinalCurrency.filter(order => Number(order.myworld_commission) > 0)
  if (ordersWithComission.length) {
    let events = {}
    ordersWithComission.forEach(order => {
      // if (!Object.keys(EventIds).includes(order.myworld_commission)) return
      events[order.myworld_commission] = events[order.myworld_commission] ?
        [...events[order.myworld_commission], order] :
        [order]
    })
    for (let event in events) {
      trackingPixel(
        events[event],
        tduid,
        // EventIds[event],
        events[event][0].event_id
      )
    }
  }
}

export default TrackingPixel