import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { Tab } from 'react-bootstrap';

export const FaqListItem = ({ faqElement, faqElementIndex }) => {
  const { t } = useTranslation('faq');

  return (
    <Tab.Pane eventKey={faqElement.eventKey}>
      <Accordion defaultActiveKey="0">
        {Array.from({ length: faqElement.count }, (_, i) => i + 1).map((card, cardIndex) => (
          <Card key={`${faqElement.eventKey}-${faqElementIndex}-card-${cardIndex}`}>
            <Accordion.Toggle as={Card.Header} eventKey={cardIndex.toString()}>
              {t(`${faqElement.content}-Title${card}`)}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={cardIndex.toString()}>
              {faqElement?.dangerousElements?.includes(card) ? (
                <Card.Body
                  dangerouslySetInnerHTML={{
                    __html: t(`${faqElement.content}-Body${card}`, { interpolation: { escapeValue: false } }),
                  }}
                />
              ) : (
                <Card.Body>{t(`${faqElement.content}-Body${card}`)}</Card.Body>
              )}
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    </Tab.Pane>
  );
};

export const FaqElementPropType = PropTypes.shape({
  count: PropTypes.number,
  content: PropTypes.string,
  eventKey: PropTypes.string,
  dangerousElements: PropTypes.arrayOf(PropTypes.number),
});

FaqListItem.propTypes = {
  faqElement: FaqElementPropType,
  faqElementIndex: PropTypes.number,
};
