import { useCallback } from "react";
import { useCookie } from "../../utils/useCookie";


const useTrackingPixelRedirect = () => {
  const { setCookie } = useCookie()
  const trackingPixelRedirect = useCallback(() => {

    function getVar(name) {
      let get_string = document.location.search;
      let name_index;
      let space;
      let return_value = "";

      do {
        name_index = get_string.indexOf(name + "=");
        if (name_index !== -1) {
          get_string = get_string.substr(
            name_index + name.length + 1,
            get_string.length - name_index
          );

          let end_of_value = get_string.indexOf("&");
          let value;

          if (end_of_value !== -1) {
            value = get_string.substr(0, end_of_value);
          } else {
            value = get_string;
          }
          if (return_value === "" || value === "") {
            return_value += value;
          } else {
            return_value += ", " + value;
          }
        }
      } while (name_index !== -1);
      {
        space = return_value.indexOf("+");
      }

      while (space !== -1) {
        return_value = return_value.substr(0, space) + " " + return_value.substr(space + 1, return_value.length);
        space = return_value.indexOf("+");
      }
      return (return_value);
    }

    function redirTo() {
      let strReturn = "";
      const strHref = document.location.href;
      if (strHref.indexOf("&url=") > -1) {
        strReturn = strHref.substr(strHref.indexOf("&url=") + 5);
      } else {
        // Change URL to the default landing page/homepage.
        strReturn = window.location.protocol + "//" + window.location.host;
      }
      return strReturn;
    }

    const mytduid = getVar("tduid");
    setCookie("TRADEDOUBLER", mytduid, 1);
    window.location = redirTo();
  }, [])
  return { trackingPixelRedirect }
};
export default useTrackingPixelRedirect;
