import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';


import { useOrders } from '../../hooks/useOrders';

import '../UserOrders/userOrders.scss';
import { useCookie } from "../../../../utils/useCookie";
import { useTrackingPixel } from "../../../trackingPixel/useTrackingPixel";
import TrackingPixel from "../../../trackingPixel/trackingPixel";



export const ConfirmOrder = ({ showOrdersHeading, failed }) => {
  const { t } = useTranslation();
  const { onOrderDetailsAction, orders, ...orderState } = useOrders();
  const { getCookie, removeCookie } = useCookie();
  const { trackingPixel } = useTrackingPixel();
  const location = useLocation();
  const getOrder = useCallback(async () => {
    const search = location.search.split('?order_id=')[1] || null;
    let id = 0;
    if (search) {
      id = search.substring(0, search.indexOf('&') !== -1 ? search.indexOf('&') : search.length);
    }
    if (id) {
      await onOrderDetailsAction({
        order_id: id,
        image_size: 'medium_rectangle',
        accessToken: orderState?.accessToken || null,
      });
    }
  }, [location, onOrderDetailsAction]);

  useEffect(async () => {
    await getOrder();
  }, [getOrder]);

  // trackingPixel entry point
  useEffect(() => {
    let tduid = getCookie("TRADEDOUBLER")
    if (tduid && !failed) {
      if (orders && orders.length && orders[0].amount > Number(orders[0].credits_used)) {
        TrackingPixel(orders, trackingPixel, tduid)
        removeCookie("TRADEDOUBLER")
      }
    }
  }, [orders])

  return (
    <div className="datatable-responsive-demo">
      <div className="container mt-4">
        {showOrdersHeading ? <p className="order mb-5">{t('orders.yourOrders')}</p> : null}
        {orders?.map((detail, i) => (
          <Container className="order_box_card" key={i}>
            <Row>
              <Col sm={9} className="orderid_text">
                {t('orders.orderID')} : {detail.orderid}
              </Col>
              <Col sm={3}>
                <p className="status1 float-right w-100 text-uppercase">
                  {failed ? t('common.status.failed') : detail?.order_status}
                </p>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col sm={3} lg={3}>
                <Image src={detail?.images?.color?.medium_rectangle || ''} rounded className="img-fluid rounded" />
                <p className="text_card"> {detail.brand_name}</p>
              </Col>
              <Col sm={6}>
                <div className="d-flex flex-column pt-2">
                  <p className="card_title">{detail.brand_name}</p>
                  <p className="gift_title">
                    {t('common.giftingFor')} : {detail.isgift ? t('common.someoneElse') : t('common.myself')}
                  </p>
                  <div className="d-flex justify-content-between align-items-center mt-3 me-2">
                    <p className="quantity_text">
                      {t('common.quantity')}: {detail.quantity}
                    </p>
                  </div>
                </div>
              </Col>
              <Col sm>
                <div className="quantity_text text-right">
                  {detail.unit_name} {detail.giftcard_value}
                </div>
                <div className="quantity_text text-right">
                  {t('common.creditsUsed')}: {detail.credits_used}
                </div>
                <div />
                <div className="date_text mt-5">
                  {t('common.placedAt')} :<Moment format=" MMM DD, YYYY">{detail.date}</Moment>
                  <Moment format=" h:mm A">{detail.date}</Moment>
                </div>
              </Col>
            </Row>
            {detail.isgift ? (
              <>
                <Row>
                  <Col sm>
                    <p className="gift_details">{t('orders.giftDetails')}</p>
                  </Col>
                </Row>
                <Row>
                  <Col sm>
                    <p className="send_to">{t('common.sentTo')}</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form>
                      <Form.Row>
                        <Col xs={5}>
                          <Form.Control
                            type="email"
                            placeholder={detail.gift.recipient_email}
                            value={detail.gift.recipient_email}
                            style={{
                              border: '1px solid #B2B2C9',
                              color: 'none',
                              fontWeight: '600',
                            }}
                          />
                        </Col>
                      </Form.Row>
                      <Form.Row>
                        <Col xs={5}>
                          <Form.Control
                            as="textarea"
                            placeholder={detail.gift.gift_message}
                            value={detail.gift.gift_message}
                            style={{
                              border: '1px solid #B2B2C9',
                              color: 'none',
                              fontWeight: '600',
                            }}
                            className="mt-3"
                            rows={3}
                          />
                        </Col>
                      </Form.Row>
                    </Form>
                  </Col>
                </Row>
              </>
            ) : (
              ''
            )}
          </Container>
        ))}
      </div>
    </div>
  );
};

ConfirmOrder.propTypes = {
  showOrdersHeading: PropTypes.bool,
  failed: PropTypes.bool,
};
