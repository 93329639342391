import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { main, conditions, faq, privacyPolicy, validation, promotionTerms } from './translations';

export const defaultNS = 'main';

export const translationsResources = {
  en: {
    main,
    conditions,
    faq,
    privacyPolicy,
    validation,
    promotionTerms
  },
};

const i18nConfig = () => {
  i18n
    // load translation using xhr -> see /public/locales
    // learn more: https://github.com/i18next/i18next-xhr-backend
    // .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      defaultNS,
      ns: ['common', 'conditions', 'faq', 'privacyPolicy', 'validation'],
      fallbackLng: 'en',
      detection: {
        order: ['localStorage', 'cookie'],
      },
      resources: translationsResources,
      debug: false,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
    });
};

export default i18nConfig;
