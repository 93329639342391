import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Usericon from '../../../assets/media/icons/User-icon.svg';
import Passwordicon from '../../../assets/media/icons/Password-icon.svg';
import RewardsByLogo from '../../../assets/media/icons/rewardsby-logo.svg';
import Alert from 'react-bootstrap/Alert';
import Fade from 'react-bootstrap/Fade';
import { Link } from 'react-router-dom';
import checkbox from '../../../assets/media/icons/checkbox.svg';
import { ROUTES } from '../../../routes';
import { useTranslation } from 'react-i18next';
import Gift from '../../redeemCard/components/Gift';
import { useLoginHook } from '../hooks/useLoginHook';
import SocialButtons from './SocialButtons';
import LoginDivider from "./LoginDivider";

const LoginRedeem = () => {
  const { t } = useTranslation();
  const { formik, visible, isValid, authErrors, message, giftcardNumber } = useLoginHook();

  return (
    <>
      <div>
        {isValid ? (
          <Fade unmountOnExit={!visible}>
            <Alert variant="info" transition={visible}>
              <img src={checkbox} className="me-3" style={{ width: '30px' }} alt="Icon" />
              {message}
            </Alert>
          </Fade>
        ) : (
          <></>
        )}
      </div>

      <Container className="redeem">
        <Row className="width-limit m-0">
          <Col className="d-flex justify-content-center login-redeem-container">
            <div className="login-redeem-card">
              <p className="login-text text-center h3 pt-4 mb-0">{t('auth.logIn.title')}</p>

              {giftcardNumber ? <Gift giftcardNumber={giftcardNumber} /> : null}

              <Form onSubmit={formik.handleSubmit} className="user login-form">
                <Form.Group controlId="formBasicEmail5" className="icons_login form-group-custom">
                  <Form.Control
                    size="md"
                    type="email"
                    placeholder={t('common.enterEmail')}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    className="icons_fields"
                    name="email"
                    isInvalid={formik.touched.email && formik.errors.email}
                  />
                  <img src={Usericon} alt="Icon" className="icon_img" />
                </Form.Group>

                {formik.touched.email && formik.errors.email ? (
                  <p className="validation-messages">{formik.errors.email}</p>
                ) : null}

                <Form.Group controlId="formBasicPassword" className="icons_login form-group-custom">
                  <Form.Control
                    size="md"
                    type="password"
                    placeholder={t('common.password')}
                    className="icons_fields"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    name="password"
                    isInvalid={formik.touched.password && formik.errors.password}
                  />
                  <img src={Passwordicon} alt="Icon" className="icon_img" />
                </Form.Group>

                {formik.touched.password && formik.errors.password ? (
                  <p className="validation-messages">{formik.errors.password}</p>
                ) : null}

                <div className="redio-forgot">
                  <Form.Group className="forgot mb-4" style={{ textAlign: 'left' }}>
                    <Link
                      className="link-color"
                      to={{
                        pathname: ROUTES.auth.forgotPassword,
                        state: { idc: false },
                      }}
                    >
                      {t('auth.logIn.form.forgotPassword')}?
                    </Link>
                  </Form.Group>
                </div>

                {authErrors && authErrors.length ? (
                  <p className="validation-messages">{authErrors.join('\n')}</p>
                ) : null}
                <Button className="btn-custom" variant="info" size="lg" type="Submit">
                  {t('auth.logIn.form.loginToContinue')}
                </Button>
                <LoginDivider signUpUrl={ROUTES.auth.signUpRedeem}/>
                <SocialButtons />
              </Form>
            </div>
            <div className="login-redeem-info">
              <img src={RewardsByLogo} alt="" className="mb-4" />
              <p className="welcome-title mb-3">{t('auth.common.welcome')}</p>
              <p className="welcome-text">{t('auth.logIn.messages.redeemWelcome')}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LoginRedeem;
