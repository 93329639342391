import React from "react";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

const BrowseGiftsTrigger = ({ triggerBrowseGifts }) => {
  const { t } = useTranslation();

  return (
    <div className="text-center">
      <Button variant="info" size="md" className="mt-3 startgf-fields-button" onClick={triggerBrowseGifts}>
        {t("home.startGifting.browseGiftsButton")}
      </Button>
    </div>
  );
};

export default BrowseGiftsTrigger;