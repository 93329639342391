import React from 'react';
import Nav from 'react-bootstrap/Nav';
import { useTranslation } from 'react-i18next';
import Tab from 'react-bootstrap/Tab';

import UserProfile from './components/UserProfile';
import Updatepassword from './components/UpdatePassword';

const EditProfile = () => {
  const { t } = useTranslation();

  return (
    <div className="container-fluid px-0">
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <div className="userprofile-box userprofile-box-info mb-0">
          <Nav>
            <div className="mx-auto d-flex">
              <Nav.Item>
                <Nav.Link eventKey="first" className="nav-userprofile me-2">
                  {t('profile.basicProfile')}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second" className="nav-userprofile me-3">
                  {t('profile.changePassword')}
                </Nav.Link>
              </Nav.Item>
            </div>
          </Nav>
        </div>
        <Tab.Content>
          <Tab.Pane eventKey="first">
            <UserProfile />
          </Tab.Pane>
          <Tab.Pane eventKey="second">
            <Updatepassword />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};
export default EditProfile;
