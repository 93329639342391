import React from 'react';
import PropTypes from 'prop-types';
import { Nav } from 'react-bootstrap';

export const FaqLinkListItem = ({ text, eventKey }) => (
  <Nav.Item>
    <Nav.Link className="mb-3 faq-list" eventKey={eventKey}>
      {text}
    </Nav.Link>
  </Nav.Item>
);

FaqLinkListItem.propTypes = {
  text: PropTypes.string,
  eventKey: PropTypes.string,
};
