import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Frames, CardNumber, ExpiryDate, Cvv } from 'frames-react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { Loaders } from '../../../shared';
import { ROUTES } from '../../../routes';
import { useAuth } from '../../auth/hooks/useAuth';
import { useCart } from '../../cart/hooks/useCart';
import { useOrders } from '../../orders/hooks/useOrders';
import { useProfile } from '../../profile/hooks/useProfile';
import { usePageLoader } from '../../../shared/loaders/pageLoader/hooks/usePageLoader';

import './Frame.scss';
import { useCookie } from "../../../utils/useCookie";

const Checkout = () => {
  const { t } = useTranslation();
  const { onCreateOrderAction, onCreateGuestOrderAction, onSetOrdersLoading, ...orderState } = useOrders();
  const { checkoutCart, conversion } = useCart();
  const { onGetProfileListAction, ...profileState } = useProfile();
  const { onSetPageLoadingAction } = usePageLoader();
  const { isAuthenticated } = useAuth();
  const history = useHistory();
  const { getCookie } = useCookie()

  const publicKey =
    checkoutCart.currency !== 'SAR'
      ? `${process.env.REACT_APP_FRAMES_PUBLIC_KEY}`
      : `${process.env.REACT_APP_FRAMES_PUBLIC_KEY_SAR}`;

  useEffect(() => {
    if (isAuthenticated) {
      onGetProfileListAction();
    }
  }, [onGetProfileListAction]);

  useEffect(() => {
    if (orderState.error || orderState.order_checkout_error) {
      history.push({ pathname: ROUTES.failure });
    }
  }, [orderState.order_checkout_error, orderState.error, history]);

  useEffect(() => {
    if (orderState?.redirect_url) {
      window.location.href = orderState?.redirect_url;
    }
  }, [orderState?.redirect_url]);

  const [areCardDetailsValid, setAreCardDetailsValid] = useState(false);

  const processOrder = event => {
    const tduid = getCookie('TRADEDOUBLER')

    if (isAuthenticated) {
      const payload = {
        orders: {
          card_value_aed: null,
          order_total_aed: checkoutCart.total_amount,
          program_id: 1,
          use_credits: checkoutCart.are_reward_points_used,
          current_exchange_rate: conversion.currency_exchange_rate,
          use_hassad_points: false,
          language_code: 'en',
          isbuynow: false,
          isforself: 1,
          payment_currency: checkoutCart.currency || 'AED',
          currency: checkoutCart.currency_id,
          is_myworld: !!tduid
        },
      };
      if (checkoutCart.are_reward_points_used) {
        payload.orders['used_credits'] = checkoutCart?.used_credits || 0;
      }
      onCreateOrderAction({
        data: payload,
        event,
        amount_to_pay: checkoutCart.amount_to_pay,
      });
    } else {
      onCreateGuestOrderAction({
        data: orderState.guest_payload,
        event,
        amount_to_pay: checkoutCart.amount_to_pay,
      });
    }
  };

  const checkCardValidation = event => {
    setAreCardDetailsValid(event.isValid);
  };

  const getFramesWidget = () => {
    if (publicKey) {
      return (
        <Frames
          config={{
            debug: !process.env.REACT_APP_IS_PRODUCTION,
            publicKey,
            localization: {
              cardNumberPlaceholder: t('payment.cardNumber'),
              expiryMonthPlaceholder: 'MM',
              expiryYearPlaceholder: 'YY',
              cvvPlaceholder: 'CVV',
            },
            style: {
              base: {
                fontSize: '17px',
              },
            },
          }}
          cardTokenized={e => {
            processOrder(e);
          }}
          cardValidationChanged={checkCardValidation}
        >
          <CardNumber />
          <div className="date-and-code">
            <ExpiryDate />
            <Cvv />
          </div>
          <Button
            id="pay-button"
            onClick={() => {
              onSetOrdersLoading(true);
              onSetPageLoadingAction(true);
              Frames.submitCard();
            }}
            disabled={!areCardDetailsValid || orderState.loading}
            className="text-uppercase"
          >
            {orderState.loading ? <Loaders.Common /> : null}
            {t('payment.pay')} {checkoutCart.currency} {checkoutCart.amount_to_pay}
          </Button>
        </Frames>
      );
    }
    return null;
  };

  return (
    <Row className="mx-auto payment-card">
      <Col>
        <div className="mt-5 mx-auto custom">
          <Form.Group controlId="formBasicEmail1" className="w-100 mx-auto icons_login">
            <Form.Control
              size="md"
              type="text"
              placeholder={t('payment.nameOnTheCard')}
              value={profileState?.profile?.first_name || orderState?.guest_payload?.user?.first_name || ''}
              onChange=""
              className="cc-card card-number field"
              name="first_name"
              readOnly
            />
          </Form.Group>
          {getFramesWidget()}
        </div>
      </Col>
    </Row>
  );
};

export default Checkout;
