import {
  forgotPasswordAction,
  loginAction,
  logoutAction,
  resetPasswordAction,
  signupAction,
  updatePasswordAction,
} from '../redux/auth.actions';

import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { authActions, getAuthState } from '../redux/auth.reducer';

export const useAuth = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, errors, ...authState } = useSelector(getAuthState);

  const onLogout = useCallback(
    (callAPI = true) => {
      isAuthenticated && dispatch(logoutAction({ callAPI }));
    },
    [dispatch, isAuthenticated]
  );

  const onLogin = useCallback(
    authData => {
      dispatch(loginAction(authData));
    },
    [dispatch]
  );

  const onSignUp = useCallback(
    authData => {
      dispatch(signupAction(authData));
    },
    [dispatch]
  );

  const onResetPassword = useCallback(
    data => {
      dispatch(resetPasswordAction(data));
    },
    [dispatch]
  );

  const onUpdatePassword = useCallback(
    data => {
      dispatch(updatePasswordAction(data));
    },
    [dispatch]
  );

  const onForgotPassword = useCallback(
    data => {
      dispatch(forgotPasswordAction(data));
    },
    [dispatch]
  );

  const onClearErrors = useCallback(() => {
    dispatch(authActions.clearErrors());
  }, [dispatch]);

  const onSetErrors = useCallback(
    authErrors => {
      dispatch(authActions.setErrors(authErrors));
    },
    [dispatch]
  );

  const onRemoveLoginOrSignUpMessage = useCallback(
    payload => {
      dispatch(authActions.removeLoginOrSignUpMessage(payload));
    },
    [dispatch]
  );

  const onClearStatus = useCallback(() => {
    dispatch(authActions.clearStatus());
  }, [dispatch]);

  return {
    isAuthenticated,
    errors,
    onLogout,
    onLogin,
    onSignUp,
    onResetPassword,
    onUpdatePassword,
    onForgotPassword,
    onClearErrors,
    onSetErrors,
    onRemoveLoginOrSignUpMessage,
    onClearStatus,
    ...authState,
  };
};
