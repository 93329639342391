import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { ROUTES } from './index';
import { useAuth } from '../features/auth/hooks/useAuth';

const ProtectedRoute = props => {
  const { isAuthenticated } = useAuth();
  const { component: Component, ...rest } = props;
  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthenticated) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: ROUTES.auth.logIn,
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};
export default withRouter(ProtectedRoute);

ProtectedRoute.propTypes = {
  location: PropTypes.object,
  component: PropTypes.any,
};
