import React, { useMemo } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import Image from 'react-bootstrap/Image';
import Country from '../../../../assets/media/icons/country.svg';
import Target from '../../../../assets/media/icons/target.svg';
import Giftcard from '../../../../assets/media/icons/gift-card.svg';

const InfoPanel = () => {
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        imageSrc: Country,
        text: t('home.services.country.text'),
        alt: t('home.services.country.alt'),
      },
      {
        imageSrc: Target,
        text: t('home.services.target.text'),
        alt: t('home.services.target.alt'),
      },
      {
        imageSrc: Giftcard,
        text: t('home.services.giftcard.text'),
        alt: t('home.services.giftcard.alt'),
      },
    ],
    [t]
  );

  return (
    <Row className="mt-0 mt-sm-1 mt-md-5 icon-box width-limit me-0 container m-auto justify-content-center">
      {columns.map((column, index) => (
        <Col key={`service-${index}`} xs={12} sm={4} lg={3} className="icon-item-container">
          <Row className="p-0 ">
            <Col className="text-center p-0 py-sm-0" xs={12} sm={3}>
              <Image src={column.imageSrc} className="img-fluid" alt={column.alt}/>
            </Col>
            <Col xs={12} sm={9} className="pl-0 pl-sm-1 pe-0 pe-lg-1">
              <p className="icon-text">{column.text}</p>
            </Col>
          </Row>
        </Col>
      ))}
    </Row>
  );
};

export default InfoPanel;
