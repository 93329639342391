import { createAsyncThunk } from '@reduxjs/toolkit';
import { pageLoaderActions } from '../../../shared/loaders/pageLoader/redux/page-loader.reducer';
import { getRewardPointsAPI, getTransactionsAPI, getConvertAPI, getRemainingAPI, getConvertCreditsAPI } from '../api';

export const getRewardPointsAction = createAsyncThunk('rewardpoints/get', async (payload, thunkAPI) => {
  const { dispatch } = thunkAPI;
  dispatch(pageLoaderActions.setPageLoadingAction(true));
  const response = await getRewardPointsAPI();
  dispatch(pageLoaderActions.setPageLoadingAction(false));
  return response;
});

export const getTransactionsAction = createAsyncThunk('transcations/get', async (payload, thunkAPI) => {
  const { dispatch } = thunkAPI;
  dispatch(pageLoaderActions.setPageLoadingAction(true));
  const response = await getTransactionsAPI();
  dispatch(pageLoaderActions.setPageLoadingAction(false));
  return response;
});

export const redeemGiftCardAction = createAsyncThunk('giftcardredeem/post', async (payload, thunkAPI) => {
  const { dispatch } = thunkAPI;
  const { giftcard_number, callback } = payload;
  const request = {
    giftcard_number,
  };
  dispatch(pageLoaderActions.setPageLoadingAction(true));
  const convertRes = await getConvertAPI(request);
  if (convertRes?.code === 200 && convertRes?.data?.conversion_limit >= 5) {
    dispatch(pageLoaderActions.setPageLoadingAction(false));
    return { error: 'conversion_limit' };
  }
  if (convertRes?.code >= 400) {
    dispatch(pageLoaderActions.setPageLoadingAction(false));
    return { error: convertRes?.message || convertRes?.detail };
  }

  const remainingRes = await getRemainingAPI(request);
  if (remainingRes?.code >= 400) {
    dispatch(pageLoaderActions.setPageLoadingAction(false));
    return { error: 'card_has_already_been_redeemed' };
  }

  dispatch(pageLoaderActions.setPageLoadingAction(false));
  callback && callback();
});

export const getConvertCreditsAction = createAsyncThunk('giftcardconvert/post', async (payload, thunkAPI) => {
  const { dispatch } = thunkAPI;
  dispatch(pageLoaderActions.setPageLoadingAction(true));
  const request = {
    mylist_card: {
      card_number: payload.giftcard_number,
    },
  };
  const response = await getConvertCreditsAPI(request);
  dispatch(pageLoaderActions.setPageLoadingAction(false));
  return response;
});
