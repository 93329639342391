export const ROUTES = {
  home: '/',
  auth: {
    logIn: '/auth/login',
    logInRedeem: '/auth/login/redeem',
    signUp: '/auth/signup',
    signUpRedeem: '/auth/signup/redeem',
    resetPassword: '/auth/resetpassword',
    forgotPassword: '/auth/forgotpassword',
  },
  allCards: '/allcards',
  merchandise: '/merchandise',
  forgotPassword: '/forgotpassword',
  allFeaturedCards: '/allfeaturedcards',
  selectCard: '/selected-card/:cardId',
  order: {
    allOrder: '/order/allorder',
    confirmOrder: '/order/confirm_order',
  },
  cart: '/cart',
  profile: '/profile',
  rewardPoints: '/reward-points',
  checkout: '/checkout',
  payment: '/payment',
  termsAndConditions: '/terms-and-conditions',
  promotionTerms: '/promotion-terms',
  faq: '/faq',
  privacyPolicy: '/privacy-policy',
  failure: '/failure',
  success: '/success',
  clear: '/clear',
  myWorld: '/my-world',
  notFound: '/404',
  redeemCard: '/redeem-card',
  theBigHandshake: '/giveaway'
};

export const DYNAMIC_ROUTES = {
  selectCard: cardId => `/selected-card/${cardId}`
};
