import React, { useCallback, useMemo, useEffect } from 'react';
import Slider from 'react-slick';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

import CategoryCard from './CategoryCard';
import { Loaders } from '../../../shared';
import { useBrands } from '../../brands/hooks/useBrands';
import { useGiftcards } from '../hooks/useGiftcards';
import { useCategory } from '../../category/hooks/useCategory';

import Allmenu from '../../../assets/media/icons/allmenu.svg';

const CardsCategories = () => {
  const { selectedCountry } = useGiftcards();
  const { onBrandsByCategoryAction, onAllBrandAction, onSetBrandsCategoryId } = useBrands();
  const { data: categories, onCategoriesAction, onSetCategoryId, category_id } = useCategory();

  const items = useMemo(
    () => categories && [{ id: 'ALL', name: 'All Gift Cards', image: { normal_square: Allmenu } }, ...categories],
    [categories]
  );

  const getCardsWithCategory = category => {
    const { id } = category;
    if (id === category_id) return;
    if (id === 'ALL') {
      onAllBrandAction({
        currency: selectedCountry?.id,
        program_id: 1,
        image_size: 'medium_rectangle',
        image_type: 'Color',
        list_type: 'group',
      });
    } else {
      onBrandsByCategoryAction({
        currency: selectedCountry?.id,
        program_id: 1,
        category_id: id,
      });
    }
    onSetCategoryId(id);
    onSetBrandsCategoryId(id);
  };

  const settings = useMemo(
    () => ({
      dots: false,
      infinite: false,
      slidesToShow: 7,
      swipeToSlide: true,
      initialSlide: 0,
      fade: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 6,
            arrows: items?.length > 6,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 6,
            arrows: items?.length > 6,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 5,
            arrows: items?.length > 5,
          },
        },
        {
          breakpoint: 568,
          settings: {
            slidesToShow: 5,
            arrows: false,
          },
        },
      ],
    }),
    [items]
  );

  const categoryTemplate = useCallback(
    category => (
      <Button
        onClick={() => getCardsWithCategory(category)}
        variant="link"
        className={`row p-2 text-decoration-none category-btn text-break ${
          category.id === category_id ? 'active' : ''
        }`}
      >
        <CategoryCard
          category={category}
          key={category?.id}
          active={`${category.id === category_id ? 'active' : ''}`}
        />
      </Button>
    ),
    [category_id]
  );

  useEffect(() => {
    if (selectedCountry?.id) {
      onCategoriesAction({
        currency: selectedCountry.id,
        program_id: 1,
      });
    }
  }, [onCategoriesAction, selectedCountry?.id]);

  return (
    <Container fluid className="px-0 px-sm-1 mt-1 mt-sm-3 mt-md-4 px-lg-4">
      {!categories ? (
        <Loaders.Common />
      ) : (
        <Slider {...settings}>
          {items.map((item, index) => (
            <div key={index} className="category-slider-item">
              {categoryTemplate(item)}
            </div>
          ))}
        </Slider>
      )}
    </Container>
  );
};
export default CardsCategories;
