import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import {
  allOrdersAction,
  orderDetailsAction,
  createOrderAction,
  createOrderCheckoutAction,
  createGuestOrderAction,
  processOrderAfterRedirectAction,
  failedOrderAction,
} from './actions';

export const ORDER_INITIAL_STATE = {
  // orderid: null,
  data: [],
  orders: [],
  orderid: '',
  order_status: '',
  orderStatus: null,
  total: 0,
  error: null,
  created_order: null,
  loading: false,
  redirect_url: null,
  order_checkout_error: null,
  guest_payload: null,
  accessToken: null,
  pi_secret: null,
  pi_id: null
};

export const ORDER__FEATURE_KEY = 'order';
export const OrderAdapter = createEntityAdapter();
export const intialOrderState = OrderAdapter.getInitialState(ORDER_INITIAL_STATE);

export const orderSlice = createSlice({
  name: ORDER__FEATURE_KEY,
  initialState: ORDER_INITIAL_STATE,
  reducers: {
    clearState: state => {
      state.loading = false;
      state.created_order = null;
      state.redirect_url = null;
      state.order_checkout_error = null;
      state.error = null;
      state.guest_payload = null;
      state.data = [];
      state.orders = [];
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setGuestPayload: (state, action) => {
      state.guest_payload = action.payload;
    },
    updateAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(allOrdersAction.pending, state => {
        state.code = true;
        state.orderStatus = null;
      })
      .addCase(allOrdersAction.fulfilled, (state, action) => {
        const response = action.payload;
        if (response.code === 200) {
          state.code = true;
          state.data = response.data.orders;
        } else {
          state.error = response?.errors?.base?.join(',') || response?.message || 'Payment Failed';
        }
      })
      .addCase(allOrdersAction.rejected, () => {})
      .addCase(orderDetailsAction.pending, state => {
        state.code = true;
        state.orders = null;
        state.orderStatus = null;
      })
      .addCase(orderDetailsAction.fulfilled, (state, action) => {
        const response = action.payload;
        if (response.code === 200) {
          state.code = true;
          state.orders = response.data.order;
        }
      })
      .addCase(orderDetailsAction.rejected, state => {
        state.code = true;
      })
      .addCase(createOrderAction.pending, state => {
        state.orderid = null;
        state.created_order = null;
        state.loading = true;
      })
      .addCase(createOrderAction.fulfilled, (state, action) => {
        const response = action.payload;
        if (response.code === 200) {
          state.created_order = response.data.order;
          state.orderStatus = response?.data.order?.order_status;
          state.order_status = response?.data.order?.order_status;
        } else {
          state.error = response?.errors?.base?.join(',') || response?.message || 'Payment Failed';
        }
        state.loading = false;
      })
      .addCase(createOrderAction.rejected, (state, action) => {
        state.error = action?.error?.message || 'Order creation failed';
        state.loading = false;
        state.created_order = null;
      })
      .addCase(createOrderCheckoutAction.pending, state => {
        state.redirect_url = null;
        state.loading = true;
        state.order_checkout_error = null;
      })
      .addCase(createOrderCheckoutAction.fulfilled, (state, action) => {
        const { data, code } = action.payload;

        if (code === 200) {
          state.redirect_url = data.order.redirect_url;
          state.pi_secret = data.order.pi_secret;
          state.pi_id = data.order.pi_id;
        } else {
          state.order_checkout_error = data?.errors || 'Error in creating checkout';
        }
        state.loading = false;
      })
      .addCase(createOrderCheckoutAction.rejected, state => {
        state.redirect_url = null;
        state.loading = false;
      })
      .addCase(createGuestOrderAction.pending, state => {
        state.orderid = null;
        state.created_order = null;
        state.loading = true;
      })
      .addCase(createGuestOrderAction.fulfilled, (state, action) => {
        const response = action.payload;
        state.guest_payload = null;
        if (response.code === 200) {
          state.created_order = response.data.order;
        } else {
          state.error = response?.errors?.base?.join(',') || response?.message || 'Payment Failed';
        }
        state.loading = false;
      })
      .addCase(createGuestOrderAction.rejected, (state, action) => {
        state.error = action?.error?.message || 'Order creation failed';
        state.loading = false;
        state.created_order = null;
      })
      .addCase(processOrderAfterRedirectAction.pending, state => {
        state.accessToken = null;
        state.orderStatus = null;
      })
      .addCase(processOrderAfterRedirectAction.fulfilled, (state, action) => {
        const { data, code } = action.payload;
        if (code === 200) {
          state.accessToken = data.order?.access_token || null;
          state.orderStatus = data.order?.order_status;
        }
      })
      .addCase(processOrderAfterRedirectAction.rejected, state => {
        state.accessToken = null;
        state.orderStatus = null;
      })
      .addCase(failedOrderAction.pending, state => {
        state.loading = false;
        state.orderStatus = null;
      })
      .addCase(failedOrderAction.fulfilled, state => {
        state.loading = false;
        state.created_order = null;
        state.redirect_url = null;
        state.order_checkout_error = null;
        state.error = null;
        state.guest_payload = null;
        state.data = [];
        // state.orders = [];
      })
      .addCase(failedOrderAction.rejected, state => {
        state.loading = false;
        state.created_order = null;
        state.redirect_url = null;
        state.order_checkout_error = null;
        state.error = null;
        state.guest_payload = null;
        state.data = [];
        state.orders = [];
      });
  },
});

export const orderReducer = orderSlice.reducer;
export const orderActions = orderSlice.actions;
export const { selectAll, selectEntities } = OrderAdapter.getSelectors();
export const getOrderState = rootState => rootState[ORDER__FEATURE_KEY];
export const selectAllOrders = createSelector(getOrderState, selectAll);
export const selectOrderEntities = createSelector(getOrderState, selectEntities);
