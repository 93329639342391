import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../auth/hooks/useAuth';
import Cards from './components/Cards/Cards';
import StartGifting from './components/StartGifting';
import AllGiftCards from './components/AllGiftCards/AllGiftCards';
import AllFeaturedCards from '../cards/components/AllFeaturedCards';
import { useActiveUser } from '../auth/hooks/useActiveUser';

import LoadMore from "./components/LoadMore";
import Notification from "./components/Notification";
import {useRedeemCard} from "../redeemCard/hooks/useRedeemCard";

const Home = () => {
  const {
    alertlogin,
    signupOrLoginActionClicked,
    signupSuccess,
    status: authStatus,
    isAuthenticated,
    onClearErrors,
    onRemoveLoginOrSignUpMessage,
  } = useAuth();
  const { onGetUserActiveAction, ...useractive } = useActiveUser();
  const [isValid, setIsValid] = useState(false);
  const [visible, setVisible] = useState(true);
  const [message, setMessage] = useState('');
  const history = useHistory();
  const { t } = useTranslation();
  const {onRedeemCard} = useRedeemCard();

  useEffect(() => {
    onRedeemCard();
  }, [onRedeemCard])

  useEffect(() => {
    onClearErrors();
  }, [onClearErrors]);

  useEffect(() => {
    const value = history.location['search'].split('?', 2)[1];
    if (value !== undefined) {
      const data = { token: value };
      onGetUserActiveAction(data);
      if (useractive.verified === true) {
        setIsValid(true);
        setMessage(t('home.messages.accountCreated'));
        window.setTimeout(() => {
          setVisible(false);
        }, 3000);
      }
    }
  }, [onGetUserActiveAction, useractive.verified, history]);

  useEffect(() => {
    if (alertlogin && signupOrLoginActionClicked) {
      setIsValid(true);
      setMessage(t('home.messages.successfulLogin'));
      window.setTimeout(() => {
        setVisible(false);
      }, 3000);
      window.setTimeout(() => {
        onRemoveLoginOrSignUpMessage(false);
      }, 3000);
    }

    if (signupSuccess && signupOrLoginActionClicked) {
      setIsValid(true);
      setMessage(t('home.messages.verificationLinkHasBeenSent'));
      window.setTimeout(() => {
        onRemoveLoginOrSignUpMessage(false);
      }, 5000);

      window.setTimeout(() => {
        setVisible(false);
      }, 5000);
    }

    if (authStatus === 'OK') {
      setIsValid(true);
      setMessage(t('home.messages.resetPasswordVerificationLinkHasBeenSent'));
      window.setTimeout(() => {
        setVisible(false);
      }, 3000);
    }
  }, [
    onRemoveLoginOrSignUpMessage,
    onClearErrors,
    signupOrLoginActionClicked,
    isAuthenticated,
    signupSuccess,
    authStatus,
    alertlogin,
  ]);

  useEffect(()=>{
    window.scrollTo({
      top:0,
      behavior:"smooth"
    })
  },[])

  return (
    <>
      <Notification {...{ isValid, visible, message }} />
      <Cards />
      <StartGifting />
      <AllFeaturedCards />
      <AllGiftCards cardsLimit={16} />
      <LoadMore />
    </>
  );
};

export default Home;
