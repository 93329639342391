import React from "react";
import Fade from "react-bootstrap/Fade";
import Alert from "react-bootstrap/Alert";
import checkbox from "../../../assets/media/icons/checkbox.svg";

const Notification = ({ isValid, visible, message }) => {
  const notification = (
    <div>
      <Fade unmountOnExit={!visible}>
        <Alert variant="info" transition={visible}>
          <img src={checkbox} className="me-3" style={{ width: "30px" }} alt="Icon" />
          {message}
        </Alert>
      </Fade>
    </div>
  );
  return (
    <>
      {isValid ? notification : <></>}
    </>
  );
};

export default Notification;