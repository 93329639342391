import React from 'react';
import { useTranslation } from 'react-i18next';

import { ColumnsTitle } from './ColumnsTitle';
import { RowDataPropType } from '../../propTypes/RowData';

export const ImageColumn = ({ rowData }) => {
  const { t } = useTranslation();

  return (
    <>
      <ColumnsTitle title={t('orders.giftCards')} />
      <img src={rowData.images.medium_rectangle} className="rounded img-thumbnail2" alt="Icon" />
    </>
  );
};

ImageColumn.propTypes = {
  rowData: RowDataPropType,
};
