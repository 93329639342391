import { LocalStorage } from '../utils';

import { STORAGE_KEYS, validateTokenType } from '../utils/localStorage';
import { ROUTES } from '../routes';
import { logoutAction } from '../features/auth/redux/auth.actions';

const handleUnauthorized = tokenType => {
  localStorage.clear();
  sessionStorage.clear();
  tokenType === STORAGE_KEYS.access_token && localStorage.setItem('isRedirected', 'true');
  const loginPage = `${window.location.origin}${
    tokenType === STORAGE_KEYS.access_token && ROUTES.auth.logIn
  }`;
  if (window.location.href !== loginPage) {
    const store = require('../store').default
    store.dispatch(logoutAction());
    window.location.href = loginPage;
  }
};

export const requestHandler = (config, tokenType) => {
  const isRedirected = localStorage.getItem('isRedirected') === 'true';
  if (isRedirected) {
    localStorage.removeItem('isRedirected');
    return config;
  }

  const token = LocalStorage.getToken(tokenType);
  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  return config;
};

export const responseHandler = (response, tokenType) => {
  const data = response.data;
  const { status, code } = data;
  if (status === 'Unauthorized' && code === 401) handleUnauthorized(tokenType);

  return data;
};

export const errorHandler = (error, tokenType) => {
  validateTokenType(tokenType);

  switch (error.response.status) {
    case 404:
      throw new Error('Not Found');
    case 403:
      throw new Error('Unauthorized');
    case 401:
      handleUnauthorized(tokenType);
      break;
  }

  return Promise.reject(error);
};
