import { useDispatch, useSelector } from 'react-redux';
import { brandsActions, getBrandsState } from '../redux/reducer';
import {
  allBrandAction,
  brandsByCategoryAction,
  descriptionBrandAction,
  featureBrandsAction,
  termBrandAction,
} from '../redux/actions';
import React, { useCallback } from 'react';
import { get } from 'lodash';

export const useBrands = () => {
  const dispatch = useDispatch();
  const brandsState = useSelector(getBrandsState);
  // todo: change when design new state structure
  const brandsWithFeature = React.useMemo(() => {
    const brands = get(brandsState, 'featured_brands');
    return get(brands, 'brands') || [];
  }, [brandsState]);

  const onTermBrandAction = useCallback(
    data => {
      dispatch(termBrandAction(data));
    },
    [dispatch]
  );

  const onDescriptionBrandAction = useCallback(
    data => {
      dispatch(descriptionBrandAction(data));
    },
    [dispatch]
  );

  const onFeatureBrandsAction = useCallback(
    data => {
      dispatch(featureBrandsAction(data));
    },
    [dispatch]
  );

  const onBrandsByCategoryAction = useCallback(
    data => {
      dispatch(brandsByCategoryAction(data));
    },
    [dispatch]
  );

  const onAllBrandAction = useCallback(
    data => {
      dispatch(allBrandAction(data));
    },
    [dispatch]
  );

  const onSetBrandsCategoryId = useCallback(
    id => {
      dispatch(brandsActions.setCategoryId(id));
    },
    [dispatch]
  );

  const onSelectBrand = useCallback(
    brand => {
      dispatch(brandsActions.selectBrand(brand));
    },
    [dispatch]
  );

  return {
    onSetBrandsCategoryId,
    brandsWithFeature,
    onTermBrandAction,
    onDescriptionBrandAction,
    onFeatureBrandsAction,
    onBrandsByCategoryAction,
    onAllBrandAction,
    onSelectBrand,
    ...brandsState,
  };
};
