import {createAsyncThunk} from "@reduxjs/toolkit";
import {pageLoaderActions} from "../../../shared/loaders/pageLoader/redux/page-loader.reducer";
import {giftCardOrder} from "../api";

export const sendGiftAction = createAsyncThunk('giveaway', async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    dispatch(pageLoaderActions.setPageLoadingAction(true));
    const request = {
        giveaway_order: { ...payload },
    };
    const response = await giftCardOrder(request);
    dispatch(pageLoaderActions.setPageLoadingAction(false));
    return response;
});