import { createAsyncThunk } from '@reduxjs/toolkit';
import { pageLoaderActions } from '../../../shared/loaders/pageLoader/redux/page-loader.reducer';
import { categorylistAPI } from '../api';
import { giftcard_units } from '../api';

export const categoriesAction = createAsyncThunk('category/listCategories', async (payload, thunkAPI) => {
  const { dispatch } = thunkAPI;
  dispatch(pageLoaderActions.setPageLoadingAction(true));
  const request = {
    currency: payload.currency,
    program_id: payload.program_id,
  };
  const response = await categorylistAPI(request);
  dispatch(pageLoaderActions.setPageLoadingAction(false));
  return response;
});

export const giftcardUnitAction = createAsyncThunk('giftcard/units', async (payload, thunkAPI) => {
  const { dispatch } = thunkAPI;
  dispatch(pageLoaderActions.setPageLoadingAction(true));
  const response = await giftcard_units();
  dispatch(pageLoaderActions.setPageLoadingAction(false));
  return response;
});
