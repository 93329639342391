import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";

const LivingInCol = ({ formik, countries }) => {
  const { t } = useTranslation();
  return (
    <Col>
      <Form.Group as={Row}>
        <Form.Label className="startgf-fields-text">
          {t("home.startGifting.form.livingInLabel")}
        </Form.Label>
        <Col>
          <Form.Group>
            <Form.Control
              as="select"
              className="me-sm-2 form-select"
              id="country"
              onChange={formik.handleChange}
              custom
            >
              {countries.map((c, i) => (
                <option key={i} value={c?.id}>
                  {c?.country_name || ''}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
      </Form.Group>
    </Col>
  );
};

export default LivingInCol;