import {useAuth} from "./useAuth";
import React, { useCallback, useEffect, useState } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {useFormik} from "formik";
import * as Yup from "yup";
import {ROUTES} from "../../../routes";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useRedeemCard} from "../../redeemCard/hooks/useRedeemCard";
import { useCart } from '../../cart/hooks/useCart';

export const useSignupHook = () => {
    const { t } = useTranslation();
    const { onBulkUpdateCartAction } = useCart();

    const {
        onLogin,
        onSignUp,
        signupSuccess,
        isAuthenticated,
        errors: authErrors,
        onClearErrors,
        onSetErrors,
    } = useAuth();
    const googleId = `${process.env.REACT_APP_GOOGLE_API_KEY || ''}`;
    const fbId = `${process.env.REACT_APP_FB_APP_ID || ''}`;
    const history = useHistory();
    const [showPass, setPass] = useState(false);
    const { onRedeemCard, giftcardNumber } = useRedeemCard();
    const togglePass = () => {
        setPass(!showPass);
    };
    const eye = <FontAwesomeIcon icon={faEye} />;

    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            password_confirmation: '',
            recaptcha: '',
        },

        validationSchema: Yup.object({
            recaptcha: Yup.string().required('ReCAPTCHA is required'),
            first_name: Yup.string()
              .min(2, `${t('validation:minFieldLength')} 2`)
                .max(200, `${t('validation:maxFieldLength')} 200`)
                .required(t('validation:requiredField')),
            last_name: Yup.string()
                .min(2, `${t('validation:minFieldLength')} 2`)
                .max(200, `${t('validation:maxFieldLength')} 200`)
                .required(t('validation:requiredField')),
            email: Yup.string()
                .min(2, `${t('validation:minFieldLength')} 2`)
                .max(200, `${t('validation:maxFieldLength')} 200`)
                .email(t('validation:invalidEmail'))
                .required(t('validation:requiredField')),
            password: Yup.string()
                .min(8, `${t('validation:minFieldLength')} 8`)
                .max(200, `${t('validation:maxFieldLength')} 200`)
                .required(t('validation:requiredField')),
            password_confirmation: Yup.string()
                .min(8, `${t('validation:minFieldLength')} 8`)
                .max(200, `${t('validation:maxFieldLength')} 200`)
                .required(t('validation:requiredField')),
        }),
        onSubmit: data => {
            onClearErrors();
            onSignUp(data);
        },
        validateOnChange: false,
    });

    useEffect(() => {
        if (signupSuccess || isAuthenticated) {
            formik.resetForm();
            history.push({ pathname: ROUTES.home });
            onRedeemCard();
        }
    }, [signupSuccess, isAuthenticated, history, onBulkUpdateCartAction, formik, onRedeemCard]);

    useEffect(() => {
        onClearErrors();
    }, [onClearErrors]);

    const googleLoginSuccess = (event, recaptchaValue) => {
        onLogin({
            provider: 'Google',
            token_type: 'access_token',
            token: event.accessToken,
            expires_at: event?.tokenObj?.expires_at,
            recaptcha: recaptchaValue || ''
        });
    };

    const googleLoginFailure = event => {
        console.log('error ', event);
        onSetErrors([t('auth.signUp.messages.signUpFailed')]);
    };

    const facebookLoginSuccess = (event, recaptchaValue) => {
        onLogin({
            provider: 'Facebook',
            token_type: 'access_token',
            token: event.accessToken,
            expires_at: event?.data_access_expiration_time,
            recaptcha: recaptchaValue || ''
        });
    };

    const facebookLoginFailure = event => {
        console.log('error ', event);
        onSetErrors([t('auth.signUp.messages.signUpFailed')]);
    };

    return {
        facebookLoginFailure,
        facebookLoginSuccess,
        googleLoginFailure,
        googleLoginSuccess,
        formik,
        googleId,
        eye,
        fbId,
        showPass,
        togglePass,
        authErrors,
        giftcardNumber
    }
}
