import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import {useTranslation} from "react-i18next";

const Loader = () => {
  const { t } = useTranslation();
  return (
    <>
      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
      <span className="sr-only">{t("common.loading")}...</span>
    </>
  );
};

export default Loader;
