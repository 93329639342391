import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Header } from '../../shared';
import { PrivacyPolicyList } from './components/PrivacyPolicyList';
import { privacyPolicy } from './constants/privacyPolicy';
import { PrivacyPolicyLinkList } from './components/PrivacyPolicyLinkList';

function PrivacyPolicy() {
  const { t } = useTranslation('privacyPolicy');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <div className="container">

        <div className="row my-4">
          <div className="col-md-8 t-col-left">
            <h1>{t('privacyPolicy')}</h1>
            <button type="button" className="btn btn-primary btn-sm">
              {t('lastUpdatedDate')}
            </button>

            <hr></hr>
            <article>
              <h3 id="welcome">{t('article1.title')}</h3>
              <div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: t('article1.content', { interpolation: { escapeValue: false } }),
                  }}
                />
              </div>
            </article>

            <PrivacyPolicyList privacyPolicyList={privacyPolicy} />
          </div>

          <div className="col-md-4 t-col-right">
            <div className="sticky-side p-3">
              <p>{t('article1.title')}</p>

              <PrivacyPolicyLinkList privacyPolicyList={privacyPolicy} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default PrivacyPolicy;
