import React from "react";
import config from "../../config";

export const Captcha = ({onVerify}) => {
    const handleRecaptcha = token => {
        onVerify(!!token);
    }

    const handleExpiredRecaptcha = () => {
        onVerify(false);
    }

    window.handleRecaptcha = handleRecaptcha;
    window.handleExpiredRecaptcha = handleExpiredRecaptcha;
    //data-sitekey for localhost 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI

    return (
        <div className="g-recaptcha"
             data-sitekey={config.captchaSiteKey}
             data-callback="handleRecaptcha"
             data-expired-callback="handleExpiredRecaptcha"
        />
    );
}