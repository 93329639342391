import React from 'react';
import { useTranslation } from 'react-i18next';

import { ColumnsTitle } from './ColumnsTitle';
import { RowDataPropType } from '../../propTypes/RowData';

export const StatusColumn = ({ rowData }) => {
  const { t } = useTranslation();

  return (
    <>
      <ColumnsTitle title={t('orders.status')} />
      <p className="status">{rowData.order_status}</p>
    </>
  );
};

StatusColumn.propTypes = {
  rowData: RowDataPropType,
};
