import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { giftCardsUnitAction, giftCardThemeAction } from './actions';

export const GIFTCARDS_INIT_STATE = {
  message: '',
  errors: '',
  giftingTo: '',
  currency: '1',
  dest_currency: '',
  source_currency: '',
  giftunit_id: 1,
  countries: [],
  selectedCountry: null,
  theme: [],
  selectedCurrency: undefined,
  conversion: undefined,
};

export const GIFTCARD_REDUCER = 'giftCards';
export const giftcardsAdaptor = createEntityAdapter();
/*export const initialGiftcardState =
  giftcardsAdaptor.getInitialState(GIFTCARDS_INIT_STATE);*/

export const giftcardSlice = createSlice({
  name: GIFTCARD_REDUCER,
  initialState: GIFTCARDS_INIT_STATE,
  reducers: {
    setSelectCurreny(state, action) {
      state.selectedCurrency = action.payload;
    },
    setGiftingTo(state, action) {
      state.giftingTo = action.payload;
    },
    selectCountry(state, action) {
      state.selectedCountry = action.payload;
    },
  },
  /*removeSelectedCard(state) {
    state.selectedBrand = null;
  },*/

  extraReducers: builder => {
    builder
      .addCase(giftCardsUnitAction.pending, state => {
        state.errors = null;
        state.data = null;
      })
      .addCase(giftCardsUnitAction.fulfilled, (state, action) => {
        const response = action.payload;
        const { data, code, selectedCountry } = response;
        if (code === 200) {
          state.countries = data?.giftcard_units;
          if (!state.selectedCountry) {
            state.selectedCountry = selectedCountry || null;
          }
        }
      })
      .addCase(giftCardsUnitAction.rejected, (state, action) => {
        state.errors = [action.error.message || ''];
      })
      .addCase(giftCardThemeAction.pending, state => {
        state.errors = null;
        state.theme = null;
      })
      .addCase(giftCardThemeAction.fulfilled, (state, action) => {
        const response = action.payload;

        if (response && response) {
          state.theme = response.gifting_category;
        }
      })
      .addCase(giftCardThemeAction.rejected, state => {
        state.errors = null;
        state.theme = null;
      });
  },
});

export const giftCardsReducer = giftcardSlice.reducer;
export const giftCardsAction = giftcardSlice.actions;
export const { selectAll, selectEntities } = giftcardsAdaptor.getSelectors();
export const getGiftcardsState = rootState => rootState[GIFTCARD_REDUCER];
/*export const selectAllGiftcards = createSelector(getGiftcardsState, selectAll);*/
export const selectAllGiftcardsEntities = createSelector(getGiftcardsState, selectEntities);
