import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";

const AdditionalFields = ({countries, formik}) => {
  const { t } = useTranslation();

  return (
    <Row className="col-xs-12 col-md-9 offset-md-1">
      <Col>
        <Form.Group as={Row}>
          <Form.Label className="startgf-fields-text">
            {t("home.startGifting.form.giftToLabel")}
          </Form.Label>
          <Col>
            <Form.Control
              className="mb-2"
              id="sendGiftTo"
              placeholder={t("home.startGifting.form.giftToPlaceholder")}
              onChange={formik.handleChange}
            />
          </Col>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group as={Row}>
          <Form.Label className="startgf-fields-text">
            {t("home.startGifting.form.livingInLabel")}
          </Form.Label>
          <Col>
            <Form.Group>
              <Form.Control
                as="select"
                className="me-sm-2 form-select"
                id="livingIn"
                onChange={formik.handleChange}
                custom
              >
                {countries.map((c, i) => (
                  <option key={i} value={c?.id}>
                    {c?.country_name || ''}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Form.Group>
      </Col>
    </Row>
  );
};

export default AdditionalFields;