import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getGiftcardsState } from '../cards/redux/reducer';
import { giftomatic_countries_mapping } from './searchCountriesMapping';
import { useHistory } from 'react-router-dom';


function MerchandiseSearch() {
  const history = useHistory();
  const giftcardsState = useSelector(getGiftcardsState);
  const currentCountry = giftcardsState?.selectedCountry?.country_name;

  const country = Object.keys(giftomatic_countries_mapping).includes(currentCountry) ? currentCountry : undefined;

  useEffect(() => {
    if (country) {
      const optmzScript = document.createElement('script');
      const gftmtcScript = document.createElement('script');

      gftmtcScript.innerHTML = `
      var gftmtc = gftmtc || [];
      gftmtc.push(${JSON.stringify(giftomatic_countries_mapping[country])});
    `;
      optmzScript.innerHTML = `
      var optmz = optmz || [];
      optmz.push({
          client: '${giftomatic_countries_mapping[country].client}',
          excluded: ['domain.com', 'example.com']
      });
    `;

      optmzScript.async = true;
      gftmtcScript.async = true;
      document.body.appendChild(optmzScript);
      document.body.appendChild(gftmtcScript);
    }

    return () => {
      window.location.reload();
    };
  }, [country]);


  useEffect(() => {
    !country && history.push('/');
  }, [country, history]);

  return (
    <div className="m-2">
      <ins id={giftomatic_countries_mapping[country]?.id} style={{ display: 'block' }}></ins>
    </div>
  );
}

export default MerchandiseSearch;