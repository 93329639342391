import React from 'react';
import GModal from '../GModel';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';

const SharedModal = props => {
  const { icon, header, subText, buttonTxt, showModal, onModalClose, centered, onModalCancel, buttonTxt2 } = props;

  return (
    <GModal show={showModal} centered={centered}>
      <Container>
        <Row>
          <Col xs={12} className="text-center">
            <Image src={icon} rounded className="rounded" style={{ width: '4em' }} />
          </Col>
          <Col xs={12} className="text-center pt-2">
            <h2>{header}</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="pt-3 text-center">
            <p style={{ opacity: '0.9' }}>{subText}</p>
          </Col>
        </Row>
        <div style={{display:"flex", justifyContent:"center", gap:"20px"}}>
          {
            buttonTxt2 &&
        <Button
              className={`${buttonTxt2 ? "profile-btn2" : "profile-btn"} mt-2 btn-block`}
              variant="secondary"
              size="lg"
              type="button"
              onClick={() => onModalCancel(false)}
            >
              {buttonTxt2 || 'OK'}
            </Button>
          }
        <Button
              className={`${buttonTxt2 ? "profile-btn2" : "profile-btn"} mt-2 btn-block`}
              variant="info"
              size="lg"
              type="button"
              onClick={() => onModalClose(false)}
            >
              {buttonTxt || 'OK'}
            </Button>
        </div>
      </Container>
    </GModal>
  );
};

export default SharedModal;

SharedModal.propTypes = {
  buttonTxt: PropTypes.string,
  onModalClose: PropTypes.func,
  header: PropTypes.any,
  icon: PropTypes.string,
  showModal: PropTypes.bool,
  subText: PropTypes.string,
  centered: PropTypes.bool,
  onModalCancel: PropTypes.func,
  buttonTxt2 : PropTypes.string
};
