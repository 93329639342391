import {Button, Col, Form, Row} from 'react-bootstrap';
import React, {useEffect, useMemo, useState} from 'react';
import {useTopBar} from '../../topBar/hooks/useTopBar';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next';
import {useGiveaway} from "../hooks/useGiveaway";
import BrandsIcon from '../../../assets/media/images/giveaway/imaBoston/6000-brands.png';
import CountriesIcon from '../../../assets/media/images/giveaway/imaBoston/150-countries.png';
import CurrenciesIcon from '../../../assets/media/images/giveaway/imaBoston/85-currencies.png';
import TopArrow from '../../../assets/media/icons/top-arrow(2).svg';
import MeritLogo from '../../../assets/media/images/giveaway/merit-logo-white.png';
import GGLogo from '../../../assets/media/images/giveaway/imaBoston/gg-logo.svg';
import {Captcha} from "../../../shared/captcha";
import {SuccessfulResponse} from "./SuccessfulResponse";
import {SplashScreen} from "./SplashScreen";
import {useHistory} from "react-router-dom";
import {ROUTES} from "../../../routes";

const Giveaway = () => {
    const {countries, onGetCountriesListAction} = useTopBar();
    const {t} = useTranslation();
    const {onSendingGift, errors, onClearErrors, isClaimed, onResetState, message} = useGiveaway();
    console.debug(message)
    const [verified, setVerified] = useState(false);
    const isDisabled = useMemo(() => !verified, [verified]);
    const [displayingSplashScreen, setDisplayingSplashScreen] = useState(false);
    const history = useHistory();

    const achievements = [
        {
            img: CurrenciesIcon
        },
        {
            img: BrandsIcon
        },
        {
            img: CountriesIcon
        }
    ];

    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            country: undefined,
            company_name: '',
            company_title: '',
            code: ''
        },

        validationSchema: Yup.object({
            first_name: Yup.string()
                .min(2, `${t('validation:minFieldLength')} 2`)
                .max(200, `${t('validation:maxFieldLength')} 200`)
                .required(t('validation:requiredField')),
            last_name: Yup.string()
                .min(2, `${t('validation:minFieldLength')} 2`)
                .max(200, `${t('validation:maxFieldLength')} 200`)
                .required(t('validation:requiredField')),
            email: Yup.string()
                .min(2, `${t('validation:minFieldLength')} 2`)
                .max(200, `${t('validation:maxFieldLength')} 200`)
                .email(t('validation:invalidEmail'))
                .required(t('validation:requiredField')),
            phone: Yup.string()
                .required(t('validation:requiredField')),
            country: Yup.string().required(t('validation:requiredField')),
            company_name: Yup.string().required(t('validation:requiredField')),
            company_title: Yup.string().required(t('validation:requiredField')),
            code: Yup.string().required(t('validation:requiredField')),
        }), onSubmit: data => {
            onClearErrors();
            onSendingGift(data);
        }, validateOnChange: false,
    });

    const handleExploreClick = () => {
        history.push({pathname: ROUTES.home});
    }

    useEffect(() => {
        onClearErrors();
        onGetCountriesListAction();
        setTimeout(() => {
            setDisplayingSplashScreen(false);
        }, 2500)
    }, [])

    useEffect(() => {
        onResetState();
    }, [onResetState]);

    return (<div>
        <SplashScreen isDisplaying={displayingSplashScreen}/>
        <Row className={"giveaway " + (displayingSplashScreen ? "hidden" : "")}>
            <Col className="giveaway-static">
                <div className='top-logos'>
                    <img src={MeritLogo} alt={t('giveaway.meritLogo')} width={160}/>
                    <img src={GGLogo} alt={t('giveaway.GGLogo')} width={160} />
                </div>
                <h2 className="title">
                    {t('giveaway.info.giftiGlobalCardIs')} <span className="marked">{t('giveaway.info.theFirstGlobalCard')}</span> {t('giveaway.info.title')}
                </h2>

                <div className="achievements">
                    {achievements.map((achievement, index) => (
                      <div key={index} className="d-flex achievement">
                        <img src={achievement.img} alt={achievement.title}/>
                    </div>
                    ))}
                </div>

                <div className="explore-btn-wrapper d-flex justify-content-start">
                    <Button className="btn-custom explore-btn" variant="info" size="lg"
                            onClick={handleExploreClick}>
                        <div className="d-flex align-items-center">
                          <span className="pe-2">{t('giveaway.explore')}</span>
                          <img className="mb-1" style={{ width: '35px'}} src={TopArrow} alt="arrow"/>
                        </div>
                    </Button>
                </div>

                <p className="learn-more">{t('giveaway.info.learnMoreAboutProduct')} {t('giveaway.info.rewardsBy')},
                    <br/> {t('giveaway.info.pleaseVisit')} {' '}
                    <a href="https://rewardsby.com">https://rewardsby.com</a>
                </p>

                {/*<div className="footer-logos">*/}
                {/*    <img className='musettes' src={MuSetTes} alt={t('giveaway.GCVALogo')} />*/}
                {/*</div>*/}
            </Col>
            <Col className="giveaway-dynamic">
                {isClaimed ? <SuccessfulResponse message={message}/> : (
                    <Form onSubmit={formik.handleSubmit} className="giveaway-form">
                        <h1 className="title">{t('giveaway.form.title')}</h1>
                        <p className="subtitle">{t('giveaway.form.fillInformation')} <span
                            className="font-weight-bold"> {t('giveaway.form.giftiGlobalCard')} </span>
                            {t('giveaway.form.youWillReceiveAnEmail')}
                        </p>

                        <Row>
                            <Col md={6} xs={6} sm={6}>
                                <Form.Group controlId="formBasicText">
                                    <Form.Control
                                        size="md"
                                        type="text"
                                        className="input_fields"
                                        name="first_name"
                                        placeholder={t('giveaway.form.firstName')}
                                        value={formik.values.first_name}
                                        onChange={formik.handleChange}
                                        isInvalid={!!formik.errors.first_name}
                                        isValid={!formik.errors.first_name && formik.touched.first_name && formik.values.first_name !== ''}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6} xs={6} sm={6}>
                                <Form.Group controlId="formBasiclast-name">
                                    <Form.Control
                                        size="md"
                                        type="text"
                                        className="input_fields"
                                        name="last_name"
                                        placeholder={t('giveaway.form.lastName')}
                                        value={formik.values.last_name}
                                        onChange={formik.handleChange}
                                        isInvalid={!!formik.errors.last_name}
                                        isValid={!formik.errors.last_name && formik.touched.last_name && formik.values.last_name !== ''}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Form.Group>
                            <Row>
                                <Col>
                                    {formik.errors.first_name ? (
                                        <p className="validation-messages m-0">{formik.errors.first_name}</p>) : null}
                                    {errors && errors.first_name ? (
                                        <p className="validation-messages m-0">{errors.first_name[0]}</p>) : null}
                                </Col>
                                <Col>
                                    {formik.errors.last_name ?
                                        <p className="validation-messages m-0">{formik.errors.last_name}</p> : null}
                                    {errors && errors.last_name ?
                                        <p className="validation-messages m-0">{errors.last_name}</p> : null}
                                </Col>
                            </Row>
                        </Form.Group>

                        <Form.Group>
                            <Form.Control
                                size="md"
                                type="text"
                                className="input_fields"
                                name="company_name"
                                placeholder={t('giveaway.form.companyName')}
                                value={formik.values.company_name}
                                onChange={formik.handleChange}
                                isInvalid={!!formik.errors.company_name}
                                isValid={!formik.errors.company_name && formik.touched.company_name && formik.values.company_name !== ''}
                            />
                            {formik.errors.company_name ? (
                                <p className="validation-messages">{formik.errors.company_name}</p>) : null}
                            {errors && errors.company_name ? (
                                <p className="validation-messages m-0">{errors.company_name[0]}</p>) : null}
                        </Form.Group>

                        <Form.Group>
                            <Form.Control
                                size="md"
                                type="text"
                                className="input_fields"
                                name="company_title"
                                placeholder={t('giveaway.form.jobTitle')}
                                value={formik.values.company_title}
                                onChange={formik.handleChange}
                                isInvalid={!!formik.errors.company_title}
                                isValid={!formik.errors.company_title && formik.touched.company_title && formik.values.company_title !== ''}
                            />
                            {formik.errors.company_title ?
                                <p className="validation-messages">{formik.errors.company_title}</p> : null}
                            {errors && errors.company_title ?
                                <p className="validation-messages">{errors.company_title}</p> : null}
                        </Form.Group>

                        <Form.Group>
                            <Form.Control
                                size="md"
                                type="email"
                                className="input_fields"
                                name="email"
                                placeholder={t('giveaway.form.workEmailAddress')}
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                isInvalid={!!formik.errors.email}
                                isValid={!formik.errors.email && formik.touched.email && formik.values.email !== ''}
                            />
                            {formik.errors.email ?
                                <p className="validation-messages">{formik.errors.email}</p> : null}
                            {errors && errors.email ? <p className="validation-messages">{errors.email}</p> : null}
                        </Form.Group>

                        <Form.Group>
                            <Form.Control
                                size="md"
                                type="text"
                                className="input_fields"
                                name="phone"
                                placeholder={t('giveaway.form.phoneNumber')}
                                value={formik.values.phone}
                                onChange={formik.handleChange}
                                isInvalid={!!formik.errors.phone}
                                isValid={!formik.errors.phone && formik.touched.phone && formik.values.phone !== ''}
                            />
                            {formik.errors.phone ? (
                                <p className="validation-messages">{formik.errors.phone}</p>) : null}
                            {errors && errors.phone ? (
                                <p className="validation-messages">{errors.phone}</p>) : null}
                        </Form.Group>

                        <Form.Group>
                            <Form.Control
                                as="select"
                                className="me-sm-2 form-select input_fields"
                                value={formik.values.country}
                                onChange={formik.handleChange}
                                isInvalid={!!formik.errors.country}
                                name="country"
                                isValid={!formik.errors.country && formik.touched.country && formik.values.country !== ''}
                            >
                                <option value="">{t('giveaway.form.country')}</option>
                                {countries.map((country, index) => (<option key={index} value={country?.country_name}>
                                    {country?.country_name}
                                </option>))}
                            </Form.Control>
                            {formik.errors.country ?
                                <p className="validation-messages">{formik.errors.country}</p> : null}
                            {errors && errors.country ?
                                <p className="validation-messages">{errors.country}</p> : null}
                        </Form.Group>

                        <Form.Group>
                            <Form.Control
                                size="md"
                                type="text"
                                className="input_fields"
                                name="code"
                                placeholder={t('giveaway.form.enterYourGiveawayCode')}
                                value={formik.values.code}
                                onChange={formik.handleChange}
                                isInvalid={!!formik.errors.code}
                                isValid={!formik.errors.code && formik.touched.code && formik.values.code !== ''}
                            />
                            {formik.errors.code ? (
                                <p className="validation-messages">{formik.errors.code}</p>) : null}
                            {errors && errors.code ? (<p className="validation-messages">{errors.code}</p>) : null}
                        </Form.Group>

                        <Captcha onVerify={setVerified}/>

                        <Button className="btn-custom" variant="info" size="lg" type="Submit" disabled={isDisabled}>
                            {t('giveaway.form.claimMyGiftiGlobalCard')}
                        </Button>
                    </Form>)}
            </Col>
        </Row>
    </div>);
};

export default Giveaway;
