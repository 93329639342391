import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProfileState, profileActions } from '../redux/reducer';
import {getPhoneLengthByCountryNameAction, getProfileListAction, updateUserProfileAction} from '../redux/actions';

export const useProfile = () => {
  const dispatch = useDispatch();
  const profileState = useSelector(getProfileState);

  const onGetProfileListAction = useCallback(() => {
    dispatch(getProfileListAction());
  }, [dispatch]);

  const onUpdateUserProfileAction = useCallback(
    data => {
      dispatch(updateUserProfileAction(data));
    },
    [dispatch]
  );

  const onClearProfileErrors = useCallback(() => {
    dispatch(profileActions.clearErrors());
  }, [dispatch]);

  const onGetPhoneLengthByCountryNameAction = useCallback(
      data => {
        dispatch(getPhoneLengthByCountryNameAction(data))
      }, [dispatch]
  );

  return {
    onGetProfileListAction,
    onUpdateUserProfileAction,
    onClearProfileErrors,
    onGetPhoneLengthByCountryNameAction,
    ...profileState,
  };
};
