import React from "react";
import Col from 'react-bootstrap/Col';
import { sortBy } from 'lodash';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import StartingGift from '../../../../assets/media/icons/starting-gift.svg';
import StartingGift2 from '../../../../assets/media/icons/start-giftting2.svg';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ROUTES } from '../../../../routes';
import { useGiftcards } from '../../../cards/hooks/useGiftcards';
import { useCommon } from '../../../common/hooks/useCommon';
import { useTopBar } from '../../../topBar/hooks/useTopBar';
import { useTranslation } from "react-i18next";
import StartGiftingForm from "./form/index";
import StartGiftingImg from "./StartGiftingImg";
import BrowseGiftsTrigger from "./BrowseGiftsTrigger";
import StartGiftingHeading from "./StartGiftingHeading";

const StartGifting = () => {
  const { onSelectCountry, countries: giftcardsCountries } = useGiftcards();
  const { onSetUserCommonAction, onSetGiftingToUser } = useCommon();
  const topBarState = useTopBar();
  const countries = sortBy(giftcardsCountries, ['country_name']);
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      name: '',
      country: parseInt(countries[0]?.id) || 1,
      wantTo: 'treatMySelf',
      sendGiftTo: '',
      livingIn: parseInt(countries[0]?.id) || 1,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(1, `${t('validation:minFieldLength')} 1`)
        .max(200, `${t('validation:maxFieldLength')} 200`),
      country: Yup.number(),
      wantTo: Yup.string()
        .oneOf(['treatMySelf', 'sendAGift']),
      sendGiftTo: Yup.string()
        .min(1, `${t('validation:minFieldLength')} 1`)
        .max(200, `${t('validation:maxFieldLength')} 200`),
      livingIn: Yup.number(),
    }),
  });
  const history = useHistory();

  const triggerBrowseGifts = async event => {
    if (event) {
      event.preventDefault();
    }
    const data = formik.values;
    const selectedCountry = countries.filter(
      r => r.id === (data.wantTo === 'treatMySelf' ? parseInt(data.country) : parseInt(data.livingIn))
    )[0];
    const countryId =
      topBarState.countries.filter(country => country.country_name === selectedCountry?.country_name)[0]?.id || 1;
    onSetUserCommonAction({ name: data.name });
    if (data.wantTo === 'treatMySelf') {
      onSetUserCommonAction({ name: data.name });
      onSetGiftingToUser(null);
    } else {
      onSetUserCommonAction(null);
      onSetGiftingToUser({ name: data.sendGiftTo });
    }
    onSelectCountry({
      ...selectedCountry,
      country_id: countryId,
    });
    history.push({ pathname: ROUTES.allCards });
  };

   return (
    <Container>
      <Row className="cardgifiti-startgf custom-card mt-0 mt-sm-1 mt-lg-5">
        <StartGiftingImg image={StartingGift} />
        <Col md={8}>
          <StartGiftingHeading />
          <StartGiftingForm {...{formik, countries}}/>
          <BrowseGiftsTrigger triggerBrowseGifts={triggerBrowseGifts}/>
        </Col>
        <StartGiftingImg image={StartingGift2} />
      </Row>
    </Container>
  );
};

export default StartGifting;
