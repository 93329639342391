import { createAsyncThunk } from '@reduxjs/toolkit';
import { pageLoaderActions } from '../../../shared/loaders/pageLoader/redux/page-loader.reducer';
import {getPhoneLengthByCountryAPI, getprofileAPI, updateprofileAPI} from '../api';

export const getProfileListAction = createAsyncThunk('profile/get', async (payload, thunkAPI) => {
  const { dispatch } = thunkAPI;
  dispatch(pageLoaderActions.setPageLoadingAction(true));
  const response = await getprofileAPI();
  dispatch(pageLoaderActions.setPageLoadingAction(false));
  return response;
});

export const updateUserProfileAction = createAsyncThunk('profile/update', async (payload, thunkAPI) => {
  const { dispatch } = thunkAPI;
  dispatch(pageLoaderActions.setPageLoadingAction(true));
  const request = {
    user: {
      first_name: payload.firstName,
      last_name: payload.lastName,
      birthday: payload.dob,
      nationality: payload.country,
      language: payload.language,
      country_name: payload.country,
      phone: payload.phone,
    },
  };
  const response = await updateprofileAPI(request);
  if (response?.code !== 200) {
    dispatch(getProfileListAction());
  }
  dispatch(pageLoaderActions.setPageLoadingAction(false));
  return response;
});

export const getPhoneLengthByCountryNameAction = createAsyncThunk('profile/getPhoneLengthByCountryName', async(payload, thunkAPI) => {
  const { dispatch } = thunkAPI;
  dispatch(pageLoaderActions.setPageLoadingAction(true));

  const request = payload?.countryName;

  const response = await getPhoneLengthByCountryAPI(request);

  dispatch(pageLoaderActions.setPageLoadingAction(false));
  return response;
})
