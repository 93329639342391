import PropTypes from 'prop-types';

export const RowDataPropType = PropTypes.shape({
  payment_currency: PropTypes.string,
  payment_currency_amount: PropTypes.string,
  date: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.shape({ medium_rectangle: PropTypes.string })),
  orderid: PropTypes.string,
  order_status: PropTypes.string,
});
