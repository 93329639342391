import React from 'react';
import BarLoader from 'react-spinners/BarLoader';
import { usePageLoader } from './hooks/usePageLoader';

const PageLoader = () => {
  const pageLoaderState = usePageLoader();

  const getLoader = () => {
    if (pageLoaderState.loading) {
      return (
        <>
          <BarLoader
            width="100%"
            height="4px"
            color="#00B2AE"
            css={`
              display: block;
            `}
          />
          <div className="modal-backdrop show" style={{ opacity: '0' }} />
        </>
      );
    }
    return null;
  };

  return getLoader();
};

export default PageLoader;
