import { api } from '../../../api';

export const getRewardPointsAPI = () => {
  return api.get('/v1/user/mylist-credits/total');
};

export const getTransactionsAPI = () => {
  return api.get('/v1/user/mylist-credits');
};

export const getConvertAPI = userData => {
  const { giftcard_number } = userData;
  return api.get('/v1/user/get_giftcard_converted_limit', {
    params: {
      giftcard_number,
    },
  });
};

export const getRemainingAPI = userData => {
  const { giftcard_number } = userData;
  return api.get(`/v1/giftcards/${giftcard_number}/balance`);
};

export const getConvertCreditsAPI = userData => {
  return api.post(`/v1/giftcards/add_mylist_card`, userData);
};
