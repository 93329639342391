import React from 'react';
import PropTypes from 'prop-types';

import { FaqElementPropType, FaqListItem } from './FaqListItem';

export const FaqList = ({ faq }) =>
  faq.map((faqElement, faqElementIndex) => (
    <FaqListItem key={faqElement.eventKey} faqElement={faqElement} faqElementIndex={faqElementIndex} />
  ));

FaqList.propTypes = {
  faq: PropTypes.arrayOf(FaqElementPropType),
};
