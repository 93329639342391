import { useCallback } from "react";

export const useCookie = () => {
  const getCookie = useCallback(
    name => {
      const dc = document.cookie;
      const prefix = name + "=";
      let begin = dc.indexOf("; " + prefix);
      if (begin === -1) {
        begin = dc.indexOf(prefix);
        if (begin !== 0) return null;
      } else {
        begin += 2;
      }
      let end = document.cookie.indexOf(";", begin);
      if (end === -1) {
        end = dc.length;
      }
      return unescape(dc.substring(begin + prefix.length, end));
    }
    , []
  );
  const setCookie = useCallback(
    (name, value, expires, path, domain, secure) => {
      const today = new Date();
      today.setTime(today.getTime());
      if (expires) {
        expires = expires * 1000 * 60 * 60 * 24;
      }
      const expires_date = new Date(today.getTime() + (expires));
      document.cookie = name + "=" + escape(value) +
        ((expires) ? "; expires=" + expires_date.toGMTString() : "") +
        ((path) ? "; path=" + path : "") +
        ((domain) ? "; domain=" + domain : "") +
        ((secure) ? "; secure" : "")
    }, []
  );
  const removeCookie = useCallback(
    (name) => {
      setCookie(name, "", {
        "max-age": -1
      })
    }, []
  )
  return { getCookie, setCookie, removeCookie };
};
