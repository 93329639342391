import { FormControl, InputGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import React from "react";
import { useTranslation } from "react-i18next";


const SearchBar = ({ searchString, setSearchString }) => {
  const { t } = useTranslation();

  return (
    <InputGroup className="mb-3 px-3">
      <FormControl
        id="formGroupExampleInput"
        placeholder={t('home.whatAreYouLooking')}
        onChange={e => setSearchString(e.target.value)}
        value={searchString}
        size="lg"
      />
      <Button variant="outline-secondary" id="button-addon2" disabled={true} className="w-5">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="blue"
             style={{ "width": "80%" }}
             className="bi bi-search" viewBox="0 0 16 16">
          <path
            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>
        </svg>
      </Button>
    </InputGroup>
  );
};

export default SearchBar;