import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";

const WantToCol = ({ formik }) => {
  const { t } = useTranslation();
  return (
    <Col>
      <Form.Group as={Row}>
        <Form.Label className="startgf-fields-text">
          {t("home.startGifting.form.iWantToLabel")}
        </Form.Label>
        <Col>
          <Form.Group>
            <Form.Control
              as="select"
              className="me-sm-2 form-select"
              id="wantTo"
              custom
              onChange={formik.handleChange}
            >
              <option value="treatMySelf">
                {t("home.startGifting.form.treatMySelf")}
              </option>
              <option value="sendAGift">{t("home.startGifting.form.sendAGift")}</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Form.Group>
    </Col>
  );
};

export default WantToCol;