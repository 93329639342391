import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { AUTH_FEATURE_KEY, authReducer } from '../features/auth/redux/auth.reducer';
import { topbarReducer, TOPBAR_FEATURE_KEY } from '../features/topBar/redux/reducer';
import { categoryReducer, CATEGORY_REDUCER } from '../features/category/redux/reducer';
import { reducer, BRAND_REDUCER } from '../features/brands/redux/reducer';
import { giftCardsReducer, GIFTCARD_REDUCER } from '../features/cards/redux/reducer';
import { cartItemReducer, CART_ITEMS_REDUCER } from '../features/cart/redux/reducer';
import { profileReducer, PROFILE__FEATURE_KEY } from '../features/profile/redux/reducer';
import { useractiveReducer, USER_ACTIVE_FEATURE_KEY } from '../features/auth/redux/useractive.reducer';
import { rewardpointsReducer, REWARDS_POINTS_FEATURE_KEY } from '../features/rewardPoints/redux/reducer';
import { orderReducer, ORDER__FEATURE_KEY } from '../features/orders/redux/reducer';
import { commonReducer, COMMON_FEATURE_KEY } from '../features/common/redux/reducer';
import { redeemCardReducer, REDEEM_CARD_FEATURE_KEY } from "../features/redeemCard/redux/reducer";
import {GIVEAWAY_FEATURE_KEY, giveawayReducer} from "../features/giveaway/redux/reducer";

const saveToLocalStorage = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (error) {
    console.log(error);
  }
};
const loadFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    } else {
      return JSON.parse(serializedState);
    }
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

const store = configureStore({
  reducer: {
    [AUTH_FEATURE_KEY]: authReducer,
    [TOPBAR_FEATURE_KEY]: topbarReducer,
    [CATEGORY_REDUCER]: categoryReducer,
    [BRAND_REDUCER]: reducer,
    [GIFTCARD_REDUCER]: giftCardsReducer,
    [PROFILE__FEATURE_KEY]: profileReducer,
    [USER_ACTIVE_FEATURE_KEY]: useractiveReducer,
    [REWARDS_POINTS_FEATURE_KEY]: rewardpointsReducer,
    [CART_ITEMS_REDUCER]: cartItemReducer,
    [ORDER__FEATURE_KEY]: orderReducer,
    [COMMON_FEATURE_KEY]: commonReducer,
    [REDEEM_CARD_FEATURE_KEY]: redeemCardReducer,
    [GIVEAWAY_FEATURE_KEY]: giveawayReducer,
  },
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['giftcardredeem/post/fulfilled'],
      },
    }),
  ],
  enhancers: [],
  preloadedState: loadFromLocalStorage(),
  serializableCheck: false,
});
store.subscribe(() => saveToLocalStorage(store.getState()));
export default store;
