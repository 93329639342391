import { createAsyncThunk } from '@reduxjs/toolkit';
import { giftCardsUnitService, giftCardThemeApiCall } from '../api';
import { sortBy } from 'lodash';

export const giftCardsUnitAction = createAsyncThunk('gitfcards/listGiftCards', async (payload, thunkAPI) => {
  const response = await giftCardsUnitService();
  const { getState } = thunkAPI;
  let selectedCountry = {};
  if (response?.code === 200) {
    const state = getState();
    response.data.giftcard_units = sortBy(response?.data?.giftcard_units, ['country_name']);
    // default country
    selectedCountry = response.data.giftcard_units.find(c => c.id === 1);
    selectedCountry['country_id'] = state.topbar.countries.find(
      country => country.country_name === selectedCountry.country_name
    )?.id;
  }
  return { ...response, selectedCountry };
});

export const giftCardThemeAction = createAsyncThunk('giftcard_theme', async () => {
  return await giftCardThemeApiCall();
});
