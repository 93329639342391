import React from "react";
import useTrackingPixelRedirect from "../features/trackingPixel/useTrackingPixelRedirect";

const MyWorldPage = () => {
  const { trackingPixelRedirect } = useTrackingPixelRedirect();
  trackingPixelRedirect();
  return <></>;
};

export default MyWorldPage;
