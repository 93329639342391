import React from "react";
import Col from "react-bootstrap/Col";

const StartGiftingImg = ({ image }) => {
  return (
    <Col md={2}>
      <img src={image} className="startgiftingimg" alt="startgiftingimg" />
    </Col>
  );
};

export default StartGiftingImg;