import React, { useEffect, useRef } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Usericon from '../../../assets/media/icons/User-icon.svg';
import Passwordicon from '../../../assets/media/icons/Password-icon.svg';
import Alert from 'react-bootstrap/Alert';
import Fade from 'react-bootstrap/Fade';
import { Link } from 'react-router-dom';
import checkbox from '../../../assets/media/icons/checkbox.svg';
// import FacebookLogin from "react-facebook-login";
import { ROUTES } from '../../../routes';
import { useTranslation } from 'react-i18next';
import { useLoginHook } from '../hooks/useLoginHook';
import SocialButtons from './SocialButtons';
import LoginDivider from './LoginDivider';
import ReCAPTCHA from 'react-google-recaptcha';
import config from '../../../config';

const Login = () => {
  // window.dataLayer.push({
  //   event: 'event'
  // });
  const { t } = useTranslation();
  const {
    formik,
    visible,
    isValid,
    authErrors,
    message,
  } = useLoginHook();

  const captchaRef = useRef(null);

  function addAnchorTag(inputString) {
    const text = "Click here"
    if (inputString.includes(text)) {
      const indexOfClick = inputString?.indexOf(text)
      const Route = <Link to="/auth/forgotpassword">{text}</Link>
       return <>
       {inputString?.substring(0, indexOfClick)} {Route} {inputString?.substring(indexOfClick+text?.length)}
       </>
      
    } else {
      return inputString;
    }

  }
  

  useEffect(() => {
    if (authErrors?.length > 0) {
      captchaRef.current.reset();
    }
  }, [authErrors?.length]);

  return (
    <>
      <div>
        {isValid ? (
          <Fade unmountOnExit={!visible}>
            <Alert variant='info' transition={visible}>
              <img src={checkbox} className='me-3' style={{ width: '30px' }} alt='Icon' />
              {message}
            </Alert>
          </Fade>
        ) : (
          <></>
        )}
      </div>

      <Container>
        <Row>
          <Col>
            <div className='login-card mx-auto'>
              <p className='login-text text-center h3 pt-5'>{t('auth.logIn.title')}</p>
              <p className='login-sub-text text-center mt-0'>
                <small>{t('auth.common.enterToContinueText')}</small>
              </p>
              <Form onSubmit={formik.handleSubmit} className='user login-form'>
                <Form.Group controlId='formBasicEmail5' className='icons_login form-group-custom'>
                  <Form.Control
                    size='md'
                    type='email'
                    placeholder={t('common.enterEmail')}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    className='icons_fields'
                    name='email'
                    isInvalid={formik.touched.email && formik.errors.email}
                  />
                  <img src={Usericon} alt='Icon' className='icon_img' />
                </Form.Group>

                {formik.touched.email && formik.errors.email ? (
                  <p className='validation-messages'>{formik.errors.email}</p>
                ) : null}

                <Form.Group controlId='formBasicPassword' className='icons_login form-group-custom'>
                  <Form.Control
                    size='md'
                    type='password'
                    placeholder={t('common.password')}
                    className='icons_fields'
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    name='password'
                    isInvalid={formik.touched.password && formik.errors.password}
                  />
                  <img src={Passwordicon} alt='Icon' className='icon_img' />
                </Form.Group>

                {formik.touched.password && formik.errors.password ? (
                  <p className='validation-messages'>{formik.errors.password}</p>
                ) : null}
                <Form.Group controlId='formBasicRecaptcha' className='icons_login form-group-custom'>
                  <ReCAPTCHA
                    onExpired={() => captchaRef.current.reset()}
                    sitekey={config.captchaSiteKey}
                    ref={captchaRef}
                    onChange={(e) => {
                      formik.setFieldValue('recaptcha', e)
                    }}
                  />
                  {formik.touched.recaptcha && formik.errors.recaptcha ? (
                  <p className='validation-messages'>{formik.errors.recaptcha}</p>
                ) : null}
                  </Form.Group>

                <div className='redio-forgot'>
                  <Form.Group className='forgot form-group-custom' style={{ textAlign: 'left' }}>
                    <Link
                      className='link-color'
                      to={{
                        pathname: ROUTES.auth.forgotPassword,
                        state: { idc: false },
                      }}
                    >
                      {t('auth.logIn.form.forgotPassword')}?
                    </Link>
                  </Form.Group>
                </div>

                {authErrors && authErrors.length ? (
                  <p className='validation-messages'>{addAnchorTag(authErrors.join('\n'))}</p>
                ) : null}

                <Button className='btn-custom' variant='info' size='lg' type='Submit'>
                  {t('auth.logIn.form.loginToContinue')}
                </Button>
                <LoginDivider signUpUrl={ROUTES.auth.signUp} />
                <SocialButtons captchaRef={captchaRef} formik={formik}/>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Login;
