import {
  allBrandAction,
  brandsByCategoryAction,
  descriptionBrandAction,
  featureBrandsAction,
  termBrandAction,
} from './actions';
import { get } from 'lodash';
import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';

export const BRANDS_INIT_STATE = {
  message: '',
  errors: null,
  brands: null,
  currency: '',
  program_id: '1',
  image_size: null,
  image_type: false,
  list_type: false,
  category_id: '',
  id: '',
  brand_id: '',
  terms: [],
  allBrands: [],
  selectedBrand: null,
};

export const BRAND_REDUCER = 'brands';
export const brandsAdaptor = createEntityAdapter();
export const initialBrandsState = brandsAdaptor.getInitialState(BRANDS_INIT_STATE);

export const brandsSlice = createSlice({
  name: BRAND_REDUCER,
  initialState: BRANDS_INIT_STATE,
  reducers: {
    setCategoryId(state, action) {
      state.category_id = action.payload;
    },
    selectBrand(state, action) {
      state.selectedBrand = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(termBrandAction.pending, state => {
        state.errors = null;
        state.terms = null;
      })
      .addCase(termBrandAction.fulfilled, (state, action) => {
        const response = action.payload;
        const data = response?.data;
        const code = response?.code;
        if (200 === code) {
          state.terms = get(data, 'terms');
        }
      })
      .addCase(termBrandAction.rejected, (state, action) => {
        state.errors = [action.error.message || ''];
      })
      .addCase(descriptionBrandAction.pending, state => {
        state.errors = null;
        state.selectedBrand = null;
      })
      .addCase(descriptionBrandAction.fulfilled, (state, action) => {
        const response = action.payload;
        const data = response?.data;
        const code = response?.code;
        if (code === 200) {
          state.selectedBrand = data?.brand;
        }
      })
      .addCase(descriptionBrandAction.rejected, (state, action) => {
        state.errors = [action.error.message || ''];
      })
      .addCase(featureBrandsAction.pending, state => {
        state.errors = null;
        state.brands = null;
      })
      .addCase(featureBrandsAction.fulfilled, (state, action) => {
        const response = action.payload;
        const data = response?.data;
        const code = response?.code;
        if (code === 200) {
          state.featured_brands = data;
        }
      })
      .addCase(featureBrandsAction.rejected, (state, action) => {
        state.errors = [action.error.message || ''];
      })
      .addCase(brandsByCategoryAction.pending, state => {
        state.errors = null;
      })
      .addCase(brandsByCategoryAction.fulfilled, (state, action) => {
        const response = action.payload;
        const data = response?.data;
        const code = response?.code;
        if (code === 200) {
          state.brands = get(data, 'brands');
        }
      })
      .addCase(brandsByCategoryAction.rejected, (state, action) => {
        state.errors = [action.error.message || ''];
      })
      .addCase(allBrandAction.pending, state => {
        state.errors = null;
        state.brands = null;
        state.allBrands = null;
      })
      .addCase(allBrandAction.fulfilled, (state, action) => {
        const response = action.payload;
        const data = response?.data;
        const code = response?.code;
        if (code === 200) {
          state.allBrands = data.categories;
          if (state.category_id) {
            const category = data.categories.find(category => category?.id === state?.category_id);
            state.brands = category?.brands || [];
          }
        }
      })
      .addCase(allBrandAction.rejected, (state, action) => {
        state.errors = [action.error.message || ''];
      });
  },
});

export const reducer = brandsSlice.reducer;
export const brandsActions = brandsSlice.actions;
export const { selectAll, selectEntities } = brandsAdaptor.getSelectors();
export const getBrandsState = rootState => rootState[BRAND_REDUCER];
export const selectAllBrands = createSelector(getBrandsState, selectAll);
export const selectBrandsEntities = createSelector(getBrandsState, selectEntities);
