import React from 'react';
import PropTypes from 'prop-types';
import Nav from 'react-bootstrap/Nav';
import { useTranslation } from 'react-i18next';

import { FaqElementPropType } from './FaqListItem';
import { FaqLinkListItem } from './FaqLinkListItem';

export const FaqLinkList = ({ faq }) => {
  const { t } = useTranslation('faq');

  return (
    <Nav variant="pills" className="flex-column">
      {faq.map((faqElement, faqElementIndex) => (
        <FaqLinkListItem
          key={`link-item-${faqElement.eventKey}-${faqElementIndex}`}
          text={t(faqElement.content)}
          eventKey={faqElement.eventKey}
        />
      ))}
    </Nav>
  );
};

FaqLinkList.propTypes = {
  faq: PropTypes.arrayOf(FaqElementPropType),
};
