import {
    createEntityAdapter,
    createSlice,
} from '@reduxjs/toolkit';
import {
    sendGiftAction
} from './actions';

export const GIVEAWAY_INITIAL_STATE= {
    message: null,
    errors: null,
    isClaimed: false
};

export const GIVEAWAY_FEATURE_KEY = 'giveaway';
export const giveawayAdapter = createEntityAdapter();
export const initialGiveawayState = giveawayAdapter.getInitialState(GIVEAWAY_INITIAL_STATE);

export const giveawaySlice = createSlice({
    name: GIVEAWAY_FEATURE_KEY,
    initialState: initialGiveawayState,
    reducers: {
        clearErrors(state) {
            state.errors = null;
            state.message = null;
        },
        setErrors(state, action) {
            state.errors = action.payload;
        },
        resetState(state) {
            state.message = null
            state.errors = null
            state.isClaimed = false
        },
    },
    extraReducers: builder => {
        builder
            .addCase(sendGiftAction.pending, state => {
                state.errors = null;
            })
            .addCase(sendGiftAction.fulfilled, (state, action) => {
                const response = action.payload;
                if (response?.code === 200) {
                    state.message = response.message;
                    state.isClaimed = true;
                }
                if (response?.code === 400) {
                    state.message = response.message;
                    state.errors = [response.message];
                    state.isClaimed = false;
                } else if (response?.code === 401) {
                    state.user = null;
                    state.errors = [response.message || response.detail || ''];
                    state.isClaimed = false;
                } else if (response?.code === 422) {
                    state.errors = response?.errors;
                    state.isClaimed = false;
                }
            })
            .addCase(sendGiftAction.rejected, (state, action) => {
                state.errors = [action.error.message || ''];
                state.isClaimed = false;
            });
    },
});

export const giveawayReducer = giveawaySlice.reducer;
export const giveawayActions = giveawaySlice.actions;
export const { selectAll, selectEntities } = giveawayAdapter.getSelectors();
export const getGiveawayState = rootState => rootState[GIVEAWAY_FEATURE_KEY];

