import axios from 'axios';
import { errorHandler, requestHandler, responseHandler } from './interceptors';
import { STORAGE_KEYS } from '../utils/localStorage';
export const API_URL = process.env.REACT_APP_API_URL;

export const api = axios.create({
  baseURL: API_URL,
});

const tokenType = STORAGE_KEYS.access_token;

api.interceptors.request.use(
  config => requestHandler(config, tokenType),
  error => errorHandler(error)
);

api.interceptors.response.use(res => responseHandler(res, tokenType));
