import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Link } from 'react-router-dom';

import { useBrands } from '../../brands/hooks/useBrands';
import { useGiftcards } from '../hooks/useGiftcards';
import { DYNAMIC_ROUTES } from '../../../routes';

import './Giftcard.scss';

const Giftcard = ({ card }) => {
  const { selectedCountry } = useGiftcards();
  const { onSelectBrand } = useBrands();

  const handleClick = () => {
    onSelectBrand(card);
  };

  return (
    <Link to={`${DYNAMIC_ROUTES.selectCard(card.id)}/?currency=${selectedCountry.id}`} className="card-link">
      <img
        src={get(card, 'images.color.medium_rectangle')}
        onClick={() => handleClick()}
        className="img-fluid rounded-3"
        alt={card.name}
      />
      <div className="text-block">
        <p>{card.name}</p>
      </div>
    </Link>
  );
};

export default Giftcard;

Giftcard.propTypes = {
  card: PropTypes.object,
};
