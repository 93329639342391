import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";

const PersonalizedCards = ({ selectedCategoryName, currentCountryName }) => {
  const { t } = useTranslation();

  return (
    <Row className="px-0 px-lg-2">
      <Col xs={12} className="px-0 px-lg-2">
        <h2
          className="giftiallcard-text">{`${selectedCategoryName} ${t("home.browseByCategory.giftCardsIn")} ${currentCountryName}`}</h2>
        <p className="allgiftcard-text">
          {t("home.recommendBrands.buyCardsIn")} {currentCountryName}
          <br />
          {t("home.recommendBrands.personalizedCards")}
        </p>
      </Col>
    </Row>
  );
};

export default PersonalizedCards;