/**
 *
 * @type {{Canada: {campaignId: number, client: string, id: string, type: string}, Netherlands: {campaignId: number, client: string, id: string, type: string}, 'United States of America': {campaignId: number, client: string, id: string, type: string}, 'United Kingdom': {campaignId: number, client: string, id: string, type: string}, France: {campaignId: number, client: string, id: string, type: string}}}
 */
export const giftomatic_countries_mapping = {
  'United Kingdom': {
    type: 'search',
    client: 'MERIT_UK',
    campaignId: 13738,
    id: 'giftomatic-snippet-13738'
  },
  Netherlands: {
    type: 'search',
    client: 'MERIT_NL',
    campaignId: 13739,
    id: 'giftomatic-snippet-13739'
  },
  'United States of America': {
    type: 'search',
    client: 'MERIT_US',
    campaignId: 13771,
    id: 'giftomatic-snippet-13771'
  },
  Canada: {
    type: 'search',
    client: 'MERIT_CA',
    campaignId: 13769,
    id: 'giftomatic-snippet-13769'
  },
  France: {
    type: 'search',
    client: 'MERIT_FR',
    campaignId: 13809,
    id: 'giftomatic-snippet-13809'
  }
};
