import React, { useMemo, useState } from "react";
import { useBrands } from "../../../brands/hooks/useBrands";
import { useGiftcards } from "../../../cards/hooks/useGiftcards";
import { useCategory } from "../../../category/hooks/useCategory";
import PropTypes from "prop-types";
import PersonalizedCards from "./PersonalizedCards";
import BrandedCards from "./BrandedCards";

const GiftCardsByCountry = ({ cardsLimit }) => {
  const { onAllBrandAction, allBrands, brands, category_id } = useBrands();
  const { onGiftCardsUnitAction, selectedCountry, currentCountryName } = useGiftcards();
  const { selectedCategoryName } = useCategory();
  const [ searchString, setSearchString ] = useState('')

  const allBrandsByCategories = useMemo(() => {
    const brandsArr = [];
    if (allBrands?.length && category_id && selectedCategoryName !== "All") {
      const category = allBrands?.find(brand => brand?.category_id === category_id);
      return category?.brands || [];
    } else {
      allBrands?.forEach(category =>
        category?.brands?.forEach(brand =>
          cardsLimit && !searchString ? brandsArr.length < cardsLimit && brandsArr.push(brand) : brandsArr.push(brand)
        )
      );
    }
    return brandsArr;
  }, [allBrands, category_id, selectedCategoryName, searchString]);

  React.useEffect(() => {
    if (selectedCountry?.id) {
      onAllBrandAction({
        currency: selectedCountry.id,
        program_id: 1,
        image_size: "medium_rectangle",
        image_type: "Color",
        list_type: "group"
      });
    }
  }, [onAllBrandAction, selectedCountry?.id]);

  React.useEffect(() => {
    if (selectedCountry?.id) {
      onGiftCardsUnitAction({
        currency: selectedCountry.id,
        program_id: 1,
        giftunit_id: selectedCountry.id
      });
    }
  }, [onGiftCardsUnitAction, selectedCountry?.id]);
  return (
    <>
      <PersonalizedCards {...{ selectedCategoryName, currentCountryName }} />
      <BrandedCards {...{ brands, allBrandsByCategories, searchString, setSearchString }} />
    </>
  );
};

export default GiftCardsByCountry;


GiftCardsByCountry.propTypes = {
  cardsLimit: PropTypes.number
};
