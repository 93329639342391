import { api } from '../../../api';

export const getprofileAPI = () => {
  return api.get('/v1/user');
};

export const updateprofileAPI = userData => {
  return api.put('/v1/user', userData);
};

export const getPhoneLengthByCountryAPI = countryName => {
  return api.get('/v1/countries/set_phone_attributes?country_name=' + countryName);
};
