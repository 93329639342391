import React from 'react';

import { PrivacyPolicyLinkListItem } from './PrivacyPolicyLinkListItem';
import { useTranslation } from 'react-i18next';

export const PrivacyPolicyLinkList = ({ privacyPolicyList }) => {
  const { t } = useTranslation('privacyPolicy');

  return privacyPolicyList?.map((privacyPolicyItem, index) => (
    <PrivacyPolicyLinkListItem
      key={`privacy-policy-list-item-${index}`}
      tabIndex={index + 2}
      text={t(`${privacyPolicyItem.localizationKey}.title`)}
      href={`#${privacyPolicyItem.id}`}
    />
  ));
};
