import React from "react";


function EngTerms() {
    return <div className="row my-4">
        <div className="col-md-8 t-col-left">
            <h1>Terms & Conditions</h1>
            <button type="button" className="btn btn-primary btn-sm">
                Last Updated: 11 November 2024
            </button>

            <hr></hr>
            <article>
                <h3 id="conwelcome">Welcome to Gifti Global</h3>
                <p className="mb-3">
                    Welcome to www.giftiglobal.com (“Website”). The owner and operator of the
                    Website is MYLIST FZ LLC, a limited liability company with Fujairah Media Free
                    Zone Trade License
                    registration
                    number 1722/2011 FCC having its registered office at Fujairah, Fujairah Tower,
                    United Arab Emirates (PO
                    BOX
                    334555, Dubai, United Arab Emirates) (<b>“MyList”</b>) a limited liability
                    company registered in the
                    United
                    Arab Emirates (<b>“UAE”</b>).
                </p>
                <p className="mb-3">
                    These Terms of Use and all policies and additional terms (if applicable) posted
                    on the Website from time
                    to
                    time set out the terms on which we offer you access to and use of our site,
                    services, and applications
                    including our mobile application (collectively, the <b>“Services”</b>). You can
                    find all of our policies
                    and
                    additional
                    terms <b>here</b>: <a
                    href="https://www.giftiglobal.com/terms-and-conditions"><b>Legal
                    Documents</b></a>.
                </p>
                <p className="mb-3">
                    These Terms of Use apply whether you are accessing our Website through a
                    computer system, via a mobile
                    device,
                    or by using any app or platform that is provided by MyList.
                </p>
                <p className="mb-3">
                    By accessing, registering and/or continuing to use or access our Services, you
                    are agreeing to be bound
                    by
                    these Terms of Use and the Legal Documents with immediate effect. These Terms of
                    Use and the Legal
                    Documents
                    are subject to change by us at any time without any obligation to notify you.
                    Your continued use of the
                    Website
                    following any such change constitutes your agreement to these Terms of Use and
                    Legal Documents as so
                    modified.
                </p>
                <p className="mb-4">
                    References in these Terms of Use to “you”, “User”, “Buyer” (or similar) are
                    references to you as an
                    individual
                    or legal entity as the case may be.
                </p>
            </article>

            <article id="conabout">
                <h3>1. ABOUT OUR WEBSITE</h3>
                <p className="mb-3">
                    1.1. The Website is a global platform that allows businesses, individuals and
                    enterprises to buy digital gift cards (“Gift Cards”) and other products and
                    services added from time to
                    time.
                </p>
                <p className="mb-4">
                    1.2. We reserve the right to introduce new services and update or withdraw any
                    of the
                    services, in our sole discretion without any obligation to notify you, and we
                    will not be liable to you
                    for exercising this discretion.
                </p>
            </article>

            <article id="eligibility-and-registration-requirements">
                <h3>2. ELIGIBILITY AND REGISTRATION REQUIREMENTS</h3>

                <p>2.1 You are eligible to register as a buyer and benefit from the Services if you
                    meet the following
                    eligibility criteria:</p>
                <ul>
                    <li>2.2 you are above the legal age for purchasing products in your country of
                        residence; and
                    </li>
                    <li>2.3 you are able to provide a valid email address for the delivery of
                        products.
                    </li>
                </ul>

                <p>2.4 In order to register to the Website, you will need to provide us with certain
                    information. Your
                    registration to the Website may not be accepted if you do not provide us with
                    the required information.
                    We reserve the right to decline any registration without further explanation. We
                    reserve the right to
                    undertake such checks as are necessary to verify your identity.</p>

                <p>2.5 Once you have successfully completed registration, your registration shall
                    continue for an
                    indefinite period, subject to suspension or termination in accordance with
                    clause 6 of these Terms of
                    Use.</p>

                <p>2.6 Your information will be used for the purpose of contacting you in relation
                    to your order/s. With
                    your explicit permission, we may send you emails about our products and other
                    updates. If after you
                    opt-in, you change your mind, you may withdraw your consent for us to contact
                    you, for the continued
                    collection, use or disclosure of your information, at any time, by contacting us
                    at <a
                        href="mailto:care@giftiglobal.com">care@giftiglobal.com</a>.</p>

                <p>2.7 For further details about how we use your data, we encourage you to check our
                    Data Privacy Policy
                    included in the Website.</p>
            </article>

            <article id="your-obligations">
                <h3>3. YOUR OBLIGATIONS</h3>

                <p>3.1 When using or accessing the Services, you agree that you:</p>
                <ul>
                    <li>are responsible for maintaining the confidentiality of, and restricting
                        access to and use of your
                        account and password, and accept responsibility for all activities that
                        occur under your account and
                        password;
                    </li>
                    <li>agree to immediately notify us of any unauthorized use of your password or
                        account or any other
                        breach of security;
                    </li>
                    <li>will provide true, accurate, current and complete information about yourself
                        and your use of the
                        Services as required by us;
                    </li>
                    <li>will not disclose to any third party (except as required or requested by us)
                        a user’s information
                        provided to you; and
                    </li>
                    <li>will cooperate with our requests for additional information with respect to
                        your eligibility and
                        usage of our Services.
                    </li>
                </ul>

                <p>Will comply with the following Terms of Use:</p>
                <ul>
                    <li><strong>Unauthorized Resale:</strong> you agree that the Gift Cards shall
                        not be resold, exchanged,
                        or transferred in exchange of cryptocurrencies or other form of digital
                        assets that is not considered
                        to be legal tender.
                    </li>
                    <li><strong>Redemption for Cash:</strong> Gift Cards shall not be redeemed for
                        cash, either partially or
                        fully, except as required by applicable law. Any attempt to convert Gift
                        Cards to cash through
                        unauthorized means is strictly prohibited.
                    </li>
                    <li><strong>Use for Illegal Activities:</strong> you will not use or allow the
                        use of Gift Cards for any
                        purpose that is illegal, fraudulent, or in violation of any applicable laws
                        or regulations. This
                        includes, but is not limited to, money laundering, financing of terrorism,
                        or other criminal
                        activities.
                    </li>
                    <li><strong>Fraudulent Transactions:</strong> you agree to implement appropriate
                        measures to prevent the
                        use of Gift Cards in fraudulent transactions. Any misuse, including the use
                        of stolen or counterfeit
                        Gift Cards, shall be reported to MyList immediately, and the Client shall
                        cooperate fully in any
                        subsequent investigation.
                    </li>
                    <li><strong>Marketing and Promotion Restrictions:</strong> you shall not use
                        Gift Cards in any
                        marketing, advertising, or promotional activities that have not been
                        expressly approved in writing by
                        MyList. This includes, but is not limited to, using Gift Cards as
                        incentives, rewards, or giveaways in
                        a manner that may diminish the value or brand of the Gift Cards or MyList.
                    </li>
                    <li><strong>Compliance with Terms and Conditions:</strong> you shall ensure that
                        you are aware of the
                        Gift Cards’ terms and conditions and comply with them. This includes any
                        restrictions on the use,
                        expiration, and redemption of Gift Cards.
                    </li>
                    <li><strong>Consequences of Breach:</strong> In the event that you violate any
                        of the above prohibited
                        uses, MyList reserves the right to take corrective action, including but not
                        limited to the suspension
                        or cancellation of your account and the Gift Cards, withholding payments,
                        and terminating this
                        Agreement.
                    </li>
                    <li><strong>Notification and Cooperation:</strong> you shall promptly notify
                        MyList of any known or
                        suspected prohibited uses of the Gift Cards. You agree to cooperate fully
                        with MyList in investigating
                        and resolving any such issues.
                    </li>
                    <li><strong>Use as Payment for Unauthorized Goods/Services:</strong> Gift Cards
                        shall not be used as
                        payment for goods or services provided by entities or individuals that are
                        not authorized by MyList.
                        Any attempt to use Gift Cards for non-authorized transactions is prohibited.
                    </li>
                    <li><strong>Purchase from Unauthorized Third Parties:</strong> you shall not
                        purchase Gift Cards from
                        unauthorized third parties. MyList cannot guarantee the legitimacy of Gift
                        Cards obtained from such
                        sources, and the use of such Gift Cards is prohibited.
                    </li>
                    <li><strong>Use for Sourcing Inventory:</strong> you shall not use Gift Cards to
                        fulfill sales or fund
                        purchases with the intent of reselling or exporting the goods or services.
                        Gift Cards are intended for
                        personal use and not for commercial purposes.
                    </li>
                    <li><strong>Ineligible Goods and Services:</strong> Gift Cards shall not be used
                        to purchase certain
                        ineligible goods and services, such as other gift cards or collectible
                        items. The list of ineligible
                        items may be subject to change at MyList’s sole discretion.
                    </li>
                    <li><strong>Transfer After Redemption:</strong> Once a Gift Card's claim code
                        has been redeemed, it
                        cannot be transferred to another account. Redeeming a Gift Card intended for
                        another recipient is
                        strictly prohibited.
                    </li>
                    <li><strong>Force Majeure:</strong> MyList shall not be held liable for any
                        failure to process or honor
                        Gift Cards due to circumstances beyond its control, including but not
                        limited to natural disasters,
                        war, terrorist acts, government regulations, or other unforeseen events. In
                        such cases, the
                        fulfillment of Gift Card transactions may be temporarily suspended until
                        normal operations can be
                        resumed.
                    </li>
                </ul>

                <p>3.2 When using or accessing the Services, you agree that you will not:</p>
                <ul>
                    <li>breach or circumvent any laws, third party rights or our systems, policies
                        or determinations of your
                        account status;
                    </li>
                    <li>use our Services if you no longer fulfil the eligibility criteria or are not
                        able to form legally
                        binding contracts, or are temporarily or indefinitely suspended from using
                        our Services;
                    </li>
                    <li>fail to pay for items purchased by you, unless you have a valid reason as
                        set out in any of our
                        policies;
                    </li>
                    <li>use any stolen debit or credit cards to pay for your purchases;</li>
                    <li>use contact information provided to you during the course of a transaction
                        on the Site to solicit
                        additional sales offline or on another website;
                    </li>
                    <li>manipulate the price of any item;</li>
                    <li>interfere with any other user’s listings;</li>
                    <li>take any action that may undermine the Website’s feedback and ratings
                        systems;
                    </li>
                    <li>post false, inaccurate, misleading, deceptive, defamatory or similar
                        content;
                    </li>
                    <li>transfer your account to another party without our prior written consent;
                    </li>
                    <li>distribute or post spam, unsolicited or bulk electronic communications or
                        similar;
                    </li>
                    <li>distribute viruses or any other technologies that may harm our Services or
                        the interests or property
                        of other users;
                    </li>
                    <li>infringe: the copyright, trademark, patent, publicity, moral, database,
                        and/or other intellectual
                        property rights (collectively, "Intellectual Property Rights") that belong
                        to or are licensed to us;
                        or any Intellectual Property Rights that belong to third parties;
                    </li>
                    <li>harvest or otherwise collect information about users without their consent;
                        or
                    </li>
                    <li>circumvent any technical measures we use to provide the Services.</li>
                </ul>
            </article>

            <article id="customer-restrictions-and-responsibilities">
                <h3>4. CUSTOMER RESTRICTIONS AND RESPONSIBILITIES CLAUSE FOR GIFTI GLOBAL</h3>

                <p>4.1 Customer Responsibilities and Restrictions: The customer agrees to use Gifti
                    Global’s software and
                    services in accordance with all applicable laws and regulations. The customer
                    may not modify,
                    distribute, sublicense, or reverse engineer the software provided by Gifti
                    Global. The customer is also
                    responsible for maintaining the security of their systems, including
                    safeguarding account information
                    and passwords.</p>

                <p>4.2 Unauthorized use of the software, including attempts to access the source
                    code or creating
                    derivative works, is strictly prohibited. Gifti Global reserves the right to
                    suspend or terminate access
                    to the services if the customer violates these restrictions, and the customer
                    will be responsible for
                    any associated costs or damages.</p>

                <p>4.3 You acknowledge and agree that any breach of this Clause 4 shall entitle
                    MyList to terminate this
                    Agreement in its entirety without prior notice. Furthermore, Mylist reserves the
                    right to pursue any and
                    all available remedies under applicable law in response to such breach.</p>
            </article>

            <article id="intellectual-property-rights">
                <h3>5. INTELLECTUAL PROPERTY RIGHTS</h3>

                <p>5.1 Except for the rights expressly granted under these Terms of Use:</p>

                <ul>
                    <li>Gifti Global retains all ownership rights to the software, documentation,
                        and any other materials
                        provided under this agreement. All content included on the Website,
                        including but not limited to text,
                        graphics, logos, images, audio clips, digital downloads and software is our
                        property or the property
                        of our licensors. We (or our licensors, as the case may be) retain all
                        right, title and interest in
                        and to the Site and the Services, including, without limitation, all
                        Intellectual Property Rights
                        therein; and
                    </li>

                    <li>all rights, title and interest in and to any information, materials or other
                        content that you
                        provide in connection with your use of the Services, including all
                        Intellectual Property Rights
                        therein, will become our property.
                    </li>
                </ul>

                <p>5.2 You agree that you have no right to use any of our or our licensor’s
                    trademarks or Intellectual
                    Property Rights without our prior written consent.</p>

                <p>5.3 All rights not expressly granted to you in these Terms of Use are reserved
                    and retained by us or
                    our licensors.</p>

                <p>5.4 Any feedback or suggestions provided by the customer related to the software
                    or services may be
                    used by Gifti Global without any obligation to the customer, and Gifti Global
                    will own all intellectual
                    property rights arising from such feedback.</p>
            </article>

            <article id="warranties-representations-undertakings">
                <h3>6. WARRANTIES, REPRESENTATIONS & UNDERTAKINGS</h3>

                <p>6.1 You warrant, represent and undertake that:</p>
                <ul>
                    <li>you shall fully comply and will at all times continue to fully comply with
                        all applicable laws,
                        statutes and regulations, including, without limitation, all privacy laws
                        and content regulation;
                    </li>
                    <li>any laws, rules, regulations or governmental guidelines to which you are
                        subject to; or
                    </li>
                    <li>any other agreements to which you are a party to or to which you are
                        otherwise bound by.
                    </li>
                </ul>

                <p>6.2 Subject to Clause 3, If you create or use an account on behalf of a business
                    entity, you represent
                    that you are authorized to act on behalf of such business and bind the business
                    to these Terms of Use.
                    Such an account is deemed to be owned and controlled by the business entity.</p>

                <p>6.3 Subject to clause ‎6.1, the Services are provided to you on an “as is” basis
                    without
                    representations, warranties or conditions of any kind. We disclaim all
                    warranties, conditions and
                    representations of any kind, whether express, implied or collateral, including,
                    but not limited to, all
                    conditions, representations or warranties of merchantability, of fitness for a
                    particular or general
                    purpose, of non-infringement, of compatibility or that the Services are secure
                    or error free or will
                    operate without interruption or will be provided in a timely or proper manner or
                    at all.</p>

                <p>6.4 Furthermore, whilst we attempt to be as accurate as possible, we do not
                    warrant that product
                    descriptions or other content of any Service is accurate, complete, reliable,
                    current, or error-free.
                    Additionally, as a buyer, user or customer, you agree that we are not
                    responsible for examining or
                    warranting the listings or content provided by us or third parties through the
                    Services, and that you
                    will not attempt to hold us liable for any inaccuracies or defects in any of the
                    listings.</p>
            </article>


            <article id="liability-indemnities">
                <h3>7. LIABILITY & INDEMNITIES</h3>

                <p>7.1 Nothing in these Terms of Use shall limit or exclude a party’s liability:</p>
                <ul>
                    <li>for fraud, including fraudulent misrepresentation, perpetrated by that
                        party;
                    </li>
                    <li>for death or personal injury caused by the negligence of that party; or</li>
                    <li>for any other liability that cannot be limited or excluded under applicable
                        law.
                    </li>
                </ul>

                <p>7.2 Subject to clause 7.1, in no event will we, our parent company, subsidiaries
                    and affiliates, and
                    our, and their directors, officers, agents, employees, suppliers, subcontractors
                    or licensors be liable,
                    whether based on an action or claim in contract, tort, negligence, breach of
                    statutory duty or otherwise
                    arising out of or in relation to these Terms of Use for loss of profits, loss of
                    data or information,
                    business interruption or other pecuniary loss or for any special, indirect,
                    incidental or consequential
                    damages, even if we, our affiliates, directors, officers, agents, employees,
                    licensors, subcontractors
                    or suppliers have been advised of the possibility of such damages.</p>

                <p>7.3 In addition, to the extent permitted by applicable law, we (including our
                    parent company,
                    subsidiaries and affiliates and our, and their directors, officers, agents,
                    employees, suppliers,
                    subcontractors or licensors) are not liable, and you agree not to hold us
                    responsible, for any damages
                    or losses resulting directly or indirectly from:</p>
                <ul>
                    <li>the content or other information you provide when using the Services;</li>
                </ul>

                <p>7.4 Subject to clause 7.1, in no event will we, our parent company, subsidiaries
                    and affiliates, and
                    our, and their directors, officers, agents, employees, suppliers, subcontractors
                    or licensors be liable,
                    whether based on an action or claim in contract, tort, negligence, breach of
                    statutory duty or otherwise
                    arising out of or in relation to these Terms of Use for loss of profits, loss of
                    data or information,
                    business interruption or other pecuniary loss or for any special, indirect,
                    incidental or consequential
                    damages, even if we, our affiliates, directors, officers, agents, employees,
                    licensors, subcontractors
                    or suppliers have been advised of the possibility of such damages.</p>

                <p>7.5 In addition, to the extent permitted by applicable law, we (including our
                    parent company,
                    subsidiaries and affiliates and our, and their directors, officers, agents,
                    employees, suppliers,
                    subcontractors or licensors) are not liable, and you agree not to hold us
                    responsible, for any damages
                    or losses resulting directly or indirectly from:</p>
                <ul>
                    <li>the content or other information you provide when using the Services;</li>
                    <li>your use of or your inability to use our Services;</li>
                    <li>pricing, shipping, format or other guidance provided by us;</li>
                    <li>delays or disruptions in our Services;</li>
                    <li>viruses or other malicious software obtained by accessing or linking to our
                        Services;
                    </li>
                    <li>bugs, errors or inaccuracies of any kind in our Services;</li>
                    <li>damage to your hardware device from the use of products sold on the Website
                        or our Services;
                    </li>
                    <li>the content, actions or inactions of third parties using our Services; and
                    </li>
                    <li>a suspension or other action taken by us with respect to your use of the
                        Services.
                    </li>
                </ul>

                <p>7.6 Subject to clause 7.1, if clauses 7.2 or 7.3 are held to be unenforceable or
                    inapplicable for any
                    reason, then the total liability applicable to us, our parent company,
                    subsidiaries and affiliates and
                    our, and their directors, officers, agents, employee, suppliers, subcontractors
                    or licensors, to you,
                    whether based on an action or claim in contract, negligence or breach of
                    statutory duty or otherwise,
                    arising out of or in relation to these Terms of Use shall be limited to the
                    amount of fees that you paid
                    to us in the twelve (12) months prior to the action giving rise to the
                    liability.</p>

                <p>7.7 You agree to indemnify and hold us, our parent company, subsidiaries and
                    affiliates and our, and
                    their directors, officers, agents, employee, suppliers, subcontractors or
                    licensors harmless from and
                    against any losses, damages and expenses (including legal fees and attorney’s
                    fees) (“Claims”) arising
                    out of or relating to:</p>
                <ul>
                    <li>any claims or demands made by any of our licensors, third party due to or
                        arising out of your use of
                        the Services;
                    </li>
                    <li>your violation of any of the provisions of these Terms of Use, including,
                        without limitation, any of
                        the warranties, representations and undertakings;
                    </li>
                    <li>your violation of any applicable laws, including, without limitation, data
                        protection or anti-spam
                        laws; or
                    </li>
                    <li>the manner in which you use our Services, including, without limitation,
                        that the content you post
                        (if any), the items you list or purchase or your trademarks infringe the
                        Intellectual Property Rights
                        of any third party or that the content of your purchases, listings is
                        slanderous, defamatory, obscene
                        or violates any other rights (including privacy rights) of any third party
                        (including other Site
                        users).
                    </li>
                </ul>
            </article>

            <article id="suspension-termination-cancellation">
                <h3>8. SUSPENSION, TERMINATION & CANCELLATION</h3>

                <p>8.1 Without prejudice to any of our rights and remedies and without any liability
                    to you, we may limit,
                    suspend or withdraw a user’s access to the Services, and/or cancel any
                    product(s) order at our sole
                    discretion without an obligation of prior notice. For the avoidance of doubt,
                    any amounts paid and
                    received by us in relation to a cancelled product(s) order will be refunded. And
                    all orders placed by
                    you which we decided not to fulfil for whatsoever reason, we shall communicate
                    the reasons for the same
                    to you via your registered contact information.</p>

                <p>8.2 Without affecting any other rights and remedies Mylist may have, Mylist may
                    suspend or terminate
                    your account and use of the Website if:</p>
                <ul>
                    <li>You breach these Terms of Use or any of Mylist’s policies;</li>
                    <li>Mylist suspect on reasonable grounds that you might commit a breach of these
                        Terms of Use;
                    </li>
                    <li>You engage in any activity that is against the law;</li>
                    <li>You do anything that adversely affects Mylist’s business or reputation;</li>
                    <li>Mylist considers that you are disrupting, disturbing or interrupting the
                        operation of the Website in
                        any way;
                    </li>
                    <li>Mylist considers that you are a fraud risk, are using multiple accounts,
                        using proxy internet
                        protocol addresses to hide the use of multiple accounts; or
                    </li>
                    <li>Mylist considers it is necessary, for any reason, to protect Mylist or the
                        other users of the
                        Website.
                    </li>
                </ul>

                <p>8.3 You can deregister your account and stop using this Website at any time.
                    Mylist will keep your
                    account and all your content in an inactive mode but it may not necessarily be
                    deleted. If Mylist elects
                    at Mylist’s sole discretion to permanently delete all your content or account
                    after you have
                    deregistered your account or for any other reason (including a period of
                    inactivity on your part), it
                    will not be able to be recovered or accessed again and Mylist will have no
                    liability or responsibility
                    for this.</p>

                <p>8.4 Without prejudice to any other of Mylist’s rights and remedies under this
                    Terms of Use or at law,
                    Mylist may choose to take other technical and/or legal steps against any person
                    or user who create
                    problems or possible legal liabilities of any kind, who infringe act
                    inconsistently with these Terms of
                    Use or any relevant law.</p>
            </article>

            <article id="reporting-violations">
                <h3>9. REPORTING VIOLATIONS OF THESE TERMS OF USE</h3>

                <p>9.1 We are committed to ensuring that listed items and content on our Website
                    comply with these Terms
                    of Use. If you believe that a listed item or content breaches these Terms of
                    Use, please notify us on
                    the below mentioned details and we will investigate.</p>

                <h4>9.2 Our contact Information:</h4>
                <ul>
                    <li><strong>Address:</strong> Fujairah, Fujairah Tower, United Arab Emirates (PO
                        BOX 334555, Dubai,
                        United Arab Emirates)
                    </li>
                    <li><strong>Email ID:</strong> care@giftiglobal.com CC legal@meritincentives.com
                    </li>
                    <li><strong>Phone Number:</strong> +97145621020</li>
                </ul>
            </article>

            <article id="affiliates-additional-functions">
                <h3>10. giftiglobal.com AFFILIATES & ADDITIONAL FUNCTIONS</h3>

                <p>10.1 giftiglobal.com by MYLIST FZ LLC and/or its affiliates ("MyList Affiliates")
                    provide website
                    features and other products and services to you when you use or sign-up as a
                    buyer, customer or user on
                    the Site. “Affiliate” means, with respect to a particular person, any entity
                    that directly or indirectly
                    controls, is controlled by, or is under common control with such person. For the
                    avoidance of doubt,
                    MyList Affiliates includes all the companies, subsidiaries, affiliates, and
                    associates of MYLIST FZ
                    LLC.</p>

                <p>10.2 To enhance your experience across the Website and with MyList Affiliates,
                    you hereby agree that we
                    may set up additional services, functions, and/or accounts on your behalf, by
                    using the information you
                    provide to us on the Site.</p>
            </article>

            <article id="gift-card-information">
                <h3>11. GIFT CARD INFORMATION</h3>

                <p>11.1 Any information included in our Website or as a result of visits made by you
                    are governed by our
                    terms and conditions of the relevant Gift Card and the Terms of Use included
                    herein. For the avoidance
                    of doubt, in the event of any discrepancies between the Gift Card Terms and
                    Conditions and these Terms
                    of Use, the Gift Card you purchased or intend to purchase shall prevail.</p>

                <p>11.2 Gift Cards distributed by email supplied by giftiglobal.com are not intended
                    for resale.
                    giftiglobal.com reserves the right to change the range of Gift Cards offered at
                    any time, without prior
                    notification. giftiglobal.com takes no responsibility for changes to the stores
                    accepting each Gift
                    Card, and for any changes to the Terms and Conditions relating to each
                    individual Gift Card. Users
                    should refer to the back of the specific Gift Card for any retailer or
                    store-specific terms and
                    conditions, and giftiglobal.com takes no responsibility for changes to the Terms
                    and Conditions of
                    individual Gift Card issuers.</p>

                <p>11.3 giftiglobal.com will pass the risk and title to the customer, buyer, or user
                    upon delivery to the
                    registered email address or any other communicated method of fulfillment.
                    giftiglobal.com can take no
                    liability for any lost, stolen, or damaged Gift Cards once responsibility of
                    ownership has passed to the
                    customer at the time of delivery. giftiglobal.com is not directly linked to some
                    of the retailers whose
                    products are listed in our range. In the event that any such products or
                    companies are placed in either
                    administration or receivership on our Website, there is no obligation on
                    giftiglobal.com or MyList to
                    replace or exchange any previously issued, unspent Gift Cards.</p>
            </article>

            <article id="payment-methods-and-protecting-your-security">
                <h3>12. PAYMENT METHODS AND PROTECTING YOUR SECURITY</h3>

                <p>12.1 All transactions will be processed in AED (UAE Dirham), SAR (Saudi Riyal),
                    USD (US Dollar), or
                    EURO, and we accept multiple payment methods for online orders such as VISA &
                    MasterCard. Payment
                    through debit or credit cards will attract a processing fee, and this cost will
                    be borne by the customer
                    along with any other ancillary bank charges. In case of payment via bank
                    transfer, the customer/client
                    is responsible for all bank charges, intermediary bank charges, collection
                    charges, and any other
                    charges charged by the remitting or beneficiary bank.</p>

                <p>12.2 All orders need to be prepaid; you cannot order Gift Cards on credit.
                    giftiglobal.com must receive
                    the payment in full before Gift Cards are fulfilled.</p>

                <p>12.4 We do not store your credit card number; this information is directly passed
                    to our payment
                    gateway provider for the purpose of processing your payment.</p>

                <p>12.6 When you make a purchase using our Website, you are representing to us that
                    (i) any credit card or
                    debit card information you supply is true and complete, (ii) charges incurred by
                    you will be honored by
                    your credit/debit card company, and (iii) you will pay the charges incurred by
                    you at the posted prices,
                    including any applicable taxes.</p>
            </article>

            <article id="fulfillment-of-gift-cards-specific-clause">
                <h3>13. FULFILLMENT OF GIFT CARDS - SPECIFIC CLAUSE</h3>

                <p>13.1 All orders placed at giftiglobal.com will be processed once your order has
                    been paid in full. We
                    will process and dispatch to the registered email address.</p>
            </article>

            <article id="general">
                <h3>14. GENERAL</h3>

                <p>14.1 <strong>Governing Law.</strong> These Terms of Use and any non-contractual
                    rights or obligations
                    arising out of or in connection with it shall be governed by and construed in
                    accordance with the laws
                    of the United Arab Emirates, as applied in the Emirate of Dubai.</p>

                <p>14.2 <strong>Dispute Resolution.</strong> If you have an issue with our Services,
                    please contact us on
                    the details included in clause 9. We will endeavour to resolve your issue as
                    soon as possible. Any
                    disputes or Claims arising out of or in connection with these Terms of Use,
                    including any
                    non-contractual rights or obligations arising out of or in connection with these
                    Terms of Use shall be
                    referred to and finally resolved by the Dubai Courts.</p>

                <p>14.3 <strong>Third Party Rights.</strong> A person who is not a party to these
                    Terms of Use has no
                    right to enforce any of its terms.</p>

                <p>14.4 <strong>Relationship of the Parties.</strong> Nothing contained in these
                    Terms of Use will be
                    deemed or construed by the parties or any third party to create the relationship
                    of partnership, joint
                    venture or agency between the parties, it being understood that the parties will
                    at all times remain
                    independent parties contracting for Services.</p>

                <p>14.5 <strong>Further Assurances.</strong> The parties will do and execute or
                    arrange for the doing and
                    executing of each necessary act, document and thing reasonably within their
                    power to implement and give
                    effect to these Terms of Use to its full extent, including, without limitation,
                    assisting each other in
                    complying with applicable law.</p>

                <p>14.6 <strong>Assignment.</strong> These Terms of Use will be binding upon and
                    ensure to the benefit of
                    the parties and their respective successors and permitted assigns. You agree
                    that you will not assign or
                    transfer these Terms of Use or any of your rights or obligations under these
                    Terms of Use, whether
                    directly or indirectly, without first obtaining our prior written consent, such
                    consent not to be
                    unreasonably withheld. Notwithstanding the above, you acknowledge and agree that
                    we may duplicate,
                    assign, novate or transfer our rights and obligations under this Terms of Use to
                    any company which is a
                    subsidiary, branch or a parent company of us without an obligation to notify
                    you. Upon such assignment,
                    we will not be liable for any obligation set out in this Terms of Use and/or for
                    any breach of this
                    Terms of Use. The transferee or assignee shall, as from the date of such
                    assignment, assume and perform
                    all of our obligations as set forth in these Terms of Use, and you agree to
                    recognize and accept the
                    transferee or assignee in place of us for all purposes under this Terms of Use.
                </p>

                <p>14.7 <strong>Entire Agreement.</strong> These Terms of Use and the documents
                    referred to or
                    incorporated herein by reference contain the entire agreement between the
                    parties with respect to the
                    subject matter and supersede all prior agreements, negotiations and
                    representations, written or oral,
                    relating to its subject matter. Except as provided in these Terms of Use and the
                    documents referred to
                    or incorporated into these Terms of Use by reference, there are no conditions,
                    representations,
                    warranties, undertakings or agreements between the parties whether direct,
                    indirect, collateral, express
                    or implied.</p>

                <p>14.8 <strong>Amendment.</strong> These Terms of Use cannot be modified, varied,
                    amended or supplemented
                    in any way by you. We reserve the right to modify, vary, amend or supplement
                    these Terms of Use at any
                    time and from time to time. We will post the current version of these Terms of
                    Use on the Site and each
                    such change will be effective upon posting on the Site or upon the date
                    designated by us as the
                    “effective date” (if any). Your continued use of the Services following any such
                    change constitutes your
                    agreement to be bound by and your acceptance of these Terms of Use as so
                    modified.</p>

                <p>14.9 <strong>Severability.</strong> If any provision of these Terms of Use is
                    determined by any court
                    of competent jurisdiction to be invalid, illegal or unenforceable, that
                    provision will be severed from
                    these Terms of Use and the remaining provisions will continue in full force and
                    effect so long as the
                    economic or legal substance of the transactions contemplated hereby is not
                    affected in any manner
                    materially adverse to either of the parties.</p>

                <p>14.10 <strong>Force Majeure.</strong> Neither party will be liable for any loss
                    or damage or for any
                    delay or failure in performance due to acts beyond the control of such party
                    whether or not such acts
                    could reasonably be anticipated (including acts of God, legislative, judicial or
                    regulatory acts of any
                    provincial or the federal government, court or regulatory authority, acts of any
                    of our subcontractors
                    or any third party providers of goods or Services to us, labour disruptions,
                    blackouts, embargoes).</p>

                <p>14.11 <strong>No Waiver.</strong> Any waiver by us of any of the provisions of
                    these Terms of Use will
                    not constitute a waiver of any other provision (whether similar or not), nor
                    will any such waiver
                    constitute a continuing waiver of that particular provision unless expressly
                    provided by us in writing.
                </p>

                <p>14.12 <strong>Communications.</strong> You may contact us through email at
                    care@giftiglobal.com, social
                    media or live chat on the Site, or by calling our call centre at +97145621020.
                </p>

                <p>14.13 <strong>Survival.</strong> All provisions that either expressly or by their
                    nature survive will
                    survive suspension or termination of your membership of the Site.</p>
            </article>

            <article id="confidentiality-clause">
                <h3>15. CONFIDENTIALITY CLAUSE FOR GIFTI GLOBAL</h3>

                <p>15.1 <strong>Confidentiality:</strong> Both Mylist and you agree to keep
                    confidential any proprietary
                    or sensitive information disclosed during the term of this agreement.
                    Confidential information includes,
                    but is not limited to, business strategies, customer data, and software details.
                    The receiving party
                    agrees to use the same level of care to protect this information as it would use
                    to protect its own
                    confidential information, but in no event less than reasonable care.</p>

                <p>15.2 Confidential information may only be used for the purposes outlined in this
                    agreement and may not
                    be disclosed to any third party without the prior written consent of the
                    disclosing party. These
                    confidentiality obligations will survive the termination of this agreement for
                    two years.</p>
            </article>

            <article id="user-content">
                <h3>16. USER CONTENT</h3>

                <p>16.1 Users are responsible for all content they post, upload, transmit,
                    distribute, store, create, or
                    publish through the Service, collectively referred to as "User Content". This
                    content must comply with
                    the rules outlined below, which are in place to ensure a positive experience for
                    all. However, these
                    rules do not guarantee that the Service will be free from content that violates
                    these standards.</p>

                <p>16.2 We reserve the right to terminate any user account at our discretion. Users
                    agree not to engage in
                    activities or post content that:</p>
                <ul>
                    <li>a) Is illegal, harassing, obscene, sexually explicit, violent, or otherwise
                        objectionable;
                    </li>
                    <li>b) Involves sexually explicit material;</li>
                    <li>c) May result in criminal or civil liability, or violations of laws or
                        regulations;
                    </li>
                    <li>d) Constitutes unsolicited advertising or "spam";</li>
                    <li>e) Infringes on intellectual property rights;</li>
                    <li>f) Reposts content from others without proper permission;</li>
                    <li>g) Intends to deceive or mislead users;</li>
                    <li>h) Impersonates any person or falsely represents affiliation;</li>
                    <li>i) Reveals private information of others;</li>
                    <li>j) Contains harmful code or scripts; or</li>
                    <li>k) Is otherwise deemed inappropriate by us.</li>
                </ul>

                <p>16.3 If you encounter any objectionable content, please notify us, and we will
                    review it. We reserve
                    the right to make the final determination on whether the Terms have been
                    breached and what actions
                    should be taken.</p>

                <p>16.4 While we do not actively monitor or edit user content, we may remove,
                    screen, or edit any content
                    at any time for any reason. Users are responsible for backing up their content
                    and replacing any content
                    they upload to the Service at their own expense. We accept no responsibility or
                    liability for user
                    content.</p>

                <p>16.5 Users retain ownership of their content but grant us the right to use,
                    reproduce, modify, and
                    distribute it globally. Users must ensure that:</p>
                <ul>
                    <li>a) They have the necessary rights to post the content;</li>
                    <li>b) The content is accurate and not misleading; and</li>
                    <li>c) Our use of the content does not violate these Terms or any rights.</li>
                </ul>
            </article>

            <article id="user-conduct">
                <h3>17. USER CONDUCT</h3>

                <p>17.1 Users are responsible for their conduct and that of anyone using their
                    account. Users must
                    not:</p>
                <ul>
                    <li>a) Interfere with or disrupt the Service;</li>
                    <li>b) Flag content or report abuse without justification;</li>
                    <li>c) Use the Service for illegal purposes;</li>
                    <li>d) Imply any connection with us without permission;</li>
                    <li>e) Create accounts or post content if under 18 years old;</li>
                    <li>f) Use another user's account without authorization;</li>
                    <li>g) Modify, adapt, or hack the Service;</li>
                    <li>h) Access the Service in unauthorized ways;</li>
                    <li>i) Develop third-party apps that interact with the Service without
                        permission;
                    </li>
                    <li>j) Bypass security measures; or</li>
                    <li>k) Violate the rights of others.</li>
                </ul>

                <p>17.2 If your account is disabled, you may not create another account. We are not
                    responsible for user
                    conduct, errors, or omissions during your use of the Service. Use of the Service
                    is at your own
                    risk.</p>
            </article>

            <article id="disputes">
                <h3>18. DISPUTES</h3>

                <p>18.1 For technical issues, please contact us at <a
                    href="mailto:care@giftiglobal.com">care@giftiglobal.com</a>, and we will review
                    and address them at
                    our discretion.</p>

                <p>18.2 We may request additional information from you to resolve a complaint. You
                    agree to cooperate
                    fully.</p>

                <p>18.3 For disputes regarding purchases:</p>
                <ul>
                    <li>a) Notify us at <a
                        href="mailto:care@giftiglobal.com">care@giftiglobal.com</a> of the dispute;
                    </li>
                    <li>b) We may offer a reasonable remedy if warranted;</li>
                    <li>c) If the complaint violates these Terms of Use, our policies, or laws, we
                        may dismiss it and take
                        appropriate action, including legal action.
                    </li>
                </ul>

                <p>18.4 We are not obligated to resolve disputes between users and third parties.
                    Users should resolve
                    disputes directly.</p>

                <p>18.5 We reserve the right to cancel any order at any time for any reason.</p>
            </article>

            <article id="disclaimers">
                <h3>19. DISCLAIMERS</h3>

                <p>19.1 We operate as a marketplace, providing access to Gift Cards from various
                    Retailers. We are not
                    responsible for the creation or redemption of Gift Cards by Retailers and are
                    not liable for
                    transactions on third-party websites. The use of third-party logos and
                    trademarks does not imply any
                    affiliation with those parties.</p>

                <p>19.2 Use of the Service is at your own risk. We disclaim all warranties, whether
                    express or implied,
                    including:</p>
                <ul>
                    <li>a) Warranties of merchantability;</li>
                    <li>b) Warranties of fitness for a particular purpose; and</li>
                    <li>c) Warranties of non-infringement.</li>
                </ul>
                <p>We do not guarantee that the Service will meet your requirements or be
                    error-free.</p>
            </article>

            <article id="disclaimer-of-warranties-and-limitation-of-liability">
                <h3>20. DISCLAIMER OF WARRANTIES & LIMITATION OF LIABILITY</h3>

                <p>We strive to ensure that all information provided through the Service is accurate
                    but do not guarantee
                    or make any representations regarding the quality, accuracy, or completeness of
                    any data, information,
                    product, or service. Under no circumstances will we be liable for any damages
                    arising from the use of
                    the Service or related functionalities. We are not responsible for delays,
                    non-availability during
                    maintenance, or any unplanned suspensions of access due to technical
                    reasons.</p>

                <p>Users understand that any material downloaded or obtained through the Service is
                    done at their own
                    risk, and they are responsible for any damage to their systems or loss of
                    data.</p>
            </article>

            <article id="return-policy">
                <h3>21. RETURN POLICY</h3>

                <p><strong>21.1 Non-Returnable Nature of E-Gift Cards:</strong> E-Gift Cards
                    purchased through the Website
                    are non-returnable and non-refundable. Once an E-Gift Card is issued and
                    delivered to the recipient, it
                    cannot be returned or exchanged for cash or any other form of payment.</p>

                <p><strong>21.2 Errors in Purchase:</strong> In the event of an error in the
                    purchase of an E-Gift Card,
                    such as a duplicate purchase or incorrect amount, you must notify our customer
                    service team within 24
                    hours of the transaction. We may, at our discretion, offer a solution such as
                    reissuing the E-Gift Card
                    or adjusting the amount, provided the E-Gift Card has not been used.</p>

                <p><strong>21.3 Expiration and Use Limitations:</strong> E-Gift Cards may have
                    expiration dates or
                    specific use limitations (Gift Cards Terms and Conditions) as outlined at the
                    time of purchase or at the
                    back of the Gift Card. It is the purchaser's responsibility to review these
                    terms before completing the
                    transaction.</p>

                <p><strong>21.4 Fraudulent Transactions:</strong> We reserve the right to
                    investigate any purchases
                    suspected of being fraudulent. In cases where fraudulent activity is confirmed,
                    we may cancel the E-Gift
                    Card and notify the relevant authorities.</p>
            </article>

            <article id="business-continuity-plan">
                <h3>22. BUSINESS CONTINUITY PLAN</h3>

                <p><strong>22.1 Overview:</strong> We are committed to maintaining the continuous
                    operation of the
                    Website’s services and protecting the interests of our customers, particularly
                    during unforeseen events
                    or crises.</p>

                <p><strong>22.2 Risk Assessment:</strong> We conduct regular assessments to identify
                    potential risks that
                    may impact our operations, including technological failures, natural disasters,
                    and other emergencies.
                </p>

                <p><strong>22.3 Response Strategy:</strong> In the event of a crisis, we have
                    established procedures to
                    ensure rapid response and recovery. Our response strategy includes:
                    <ul>
                        <li>Immediate communication with customers regarding service disruptions.
                        </li>
                        <li>Activation of backup systems to ensure continuity of operations.</li>
                        <li>Regular updates on the status of services through our website and
                            customer communication
                            channels.
                        </li>
                    </ul>
                </p>

                <p><strong>22.4 Technology Infrastructure:</strong> Our technology infrastructure is
                    designed to be
                    resilient and scalable, ensuring that our platform remains operational even
                    during high-traffic periods
                    or unexpected outages.</p>

                <p><strong>22.5 Customer Support:</strong> Our customer support team is trained to
                    handle inquiries and
                    concerns during any crisis, ensuring that customers receive timely assistance
                    and information regarding
                    their E-Gift Card purchases.</p>

                <p><strong>22.6 Review and Improvement:</strong> We continuously review and update
                    our business continuity
                    plan to enhance our resilience and responsiveness to new risks. Feedback from
                    customers is invaluable in
                    this process, and we encourage suggestions for improvement.</p>
            </article>

        </div>

        <div className="col-md-4 t-col-right">
            <div className="sticky-side p-3">
                <p>Welcome to Gifti Global</p>
                <ul className="list-group list-group-flush">
                    <li className="border-0 condition-item-style">
                        <a href="#about-our-website">ABOUT OUR WEBSITE</a>
                    </li>
                    <li className="border-0 condition-item-style">
                        <a href="#eligibility-and-registration-requirements">ELIGIBILITY AND
                            REGISTRATION REQUIREMENTS</a>
                    </li>
                    <li className="border-0 condition-item-style">
                        <a href="#your-obligations">YOUR OBLIGATIONS</a>
                    </li>
                    <li className="border-0 condition-item-style">
                        <a href="#intellectual-property-rights">INTELLECTUAL PROPERTY RIGHTS</a>
                    </li>
                    <li className="border-0 condition-item-style">
                        <a href="#warranties-representations-undertakings">WARRANTIES,
                            REPRESENTATIONS & UNDERTAKINGS</a>
                    </li>
                    <li className="border-0 condition-item-style">
                        <a href="#liability-indemnities">LIABILITY & INDEMNITIES</a>
                    </li>
                    <li className="border-0 condition-item-style">
                        <a href="#suspension-termination-cancellation">SUSPENSION, TERMINATION &
                            CANCELLATION</a>
                    </li>
                    <li className="border-0 condition-item-style">
                        <a href="#reporting-violations">REPORTING VIOLATIONS OF THESE TERMS OF
                            USE</a>
                    </li>
                    <li className="border-0 condition-item-style">
                        <a href="#affiliates-additional-functions">AFFILIATES & ADDITIONAL
                            FUNCTIONS</a>
                    </li>
                    <li className="border-0 condition-item-style">
                        <a href="#gift-card-information">GIFT CARD INFORMATION</a>
                    </li>
                    <li className="border-0 condition-item-style">
                        <a href="#payment-methods-protecting-your-security">PAYMENT METHODS AND
                            PROTECTING YOUR SECURITY</a>
                    </li>
                    <li className="border-0 condition-item-style">
                        <a href="#fulfillment-of-gift-cards">FULFILLMENT OF GIFT CARDS</a>
                    </li>
                    <li className="border-0 condition-item-style">
                        <a href="#general">GENERAL</a>
                    </li>
                    <li className="border-0 condition-item-style">
                        <a href="#confidentiality-clause">CONFIDENTIALITY CLAUSE FOR GIFTI
                            GLOBAL</a>
                    </li>
                    <li className="border-0 condition-item-style">
                        <a href="#user-content">USER CONTENT</a>
                    </li>
                    <li className="border-0 condition-item-style">
                        <a href="#user-conduct">USER CONDUCT</a>
                    </li>
                    <li className="border-0 condition-item-style">
                        <a href="#disputes">DISPUTES</a>
                    </li>
                    <li className="border-0 condition-item-style">
                        <a href="#disclaimers">DISCLAIMERS</a>
                    </li>
                    <li className="border-0 condition-item-style">
                        <a href="#disclaimer-of-warranties-limitation-of-liability">DISCLAIMER OF
                            WARRANTIES & LIMITATION OF
                            LIABILITY</a>
                    </li>
                    <li className="border-0 condition-item-style">
                        <a href="#return-policy">RETURN POLICY</a>
                    </li>
                    <li className="border-0 condition-item-style">
                        <a href="#business-continuity-plan">BUSINESS CONTINUITY PLAN</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
}

export default EngTerms;
