export const STORAGE_KEYS = {
  access_token: 'access_token',
  // idc_access_token: 'idc_access_token',
};

export const validateTokenType = tokenType => {
  if (!STORAGE_KEYS[tokenType]) throw new Error('Unknown token type');
};

export const getToken = tokenType => {
  validateTokenType(tokenType);
  return localStorage.getItem(STORAGE_KEYS[tokenType]);
};

export const saveToken = (token, tokenType) => {
  validateTokenType(tokenType);
  return token && localStorage.setItem(STORAGE_KEYS[tokenType], token);
};

export const clearAuthData = tokenType => {
  validateTokenType(tokenType);
  return localStorage.removeItem(STORAGE_KEYS[tokenType]);
};
