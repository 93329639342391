import React from 'react';
import Slider from 'react-slick';

import { DefaultSliderElement } from '../DefaultSliderElement';

import './styles.scss';

const settings = {
  dots: true,
  infinite: true,
  arrows: false,
  autoplay: false,
  speed: 1800,
  autoplaySpeed: 3000,
  slidesToShow: 1,
  slidesToScroll: 1,
  cssEase: 'ease-in-out',
  className: 'home-page-slider',
};

export const HomeSlider = () => {
  return (
    <Slider {...settings}>
      <DefaultSliderElement />
    </Slider>
  );
};
