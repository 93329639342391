import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import { Col, Row, Tab } from 'react-bootstrap';

import { Header } from '../../shared';
import { FaqList } from './components/FaqList';
import { FaqLinkList } from './components/FaqLinkList';
import { faq } from './constants/faq';

import Mail from '../../assets/media/icons/email-white.svg';
import PhoneIcon from '../../assets/media/icons/PhoneIcon.png';

import './index.scss';

const Enquiry = () => {
  const { t } = useTranslation('faq');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <div className="faqMainWrap">
        <div className="container my-4">
          <Row className="">
            <Col md={12}>
              <h3 className="font-weight-bold faq-col faq_title">{t('frequentlyAskedQuestions')}</h3>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={12}>
              <div className="help-faq mt-3">
                <h5>{t('faqSubtitle1')}</h5>
                <p>{t('faqSubtitle2')}</p>
              </div>
            </Col>
          </Row>
          <Tab.Container id="faq" defaultActiveKey="account_creation">
            <Row className="mt-5">
              <Col md={4} className="mt-4">
                <FaqLinkList faq={faq} />
              </Col>
              <Col md={8} className="align-self-center mt-4">
                <div>
                  <Tab.Content>
                    <FaqList faq={faq} />
                  </Tab.Content>
                </div>
              </Col>
            </Row>
          </Tab.Container>
        </div>
        <div className="faq-bootom-border">
          <div className="row pb-2 width-limit">
            <div className=" col-sm-2" />
            <div className=" col-sm-8 pt-5">
              <h1 className="faq-text">{t('footerText1')}</h1> <br /> <h1 className="faq-text">{t('footerText2')}</h1>
            </div>
            <div className="col-sm-2" />
          </div>
          <div className="row pb-5 width-limit">
            <div className="col-xs-12 col-sm-6 text-center text-sm-end ">
              <Button className="nav-btn text-white mb-2 me-sm-2">
                <a href="mailto:care@giftiglobal.com" style={{ color: 'white', textDecoration: 'none' }}>
                  <img src={Mail} alt="mail" />
                  &nbsp; &nbsp; &nbsp;care@giftiglobal.com
                </a>
              </Button>
            </div>
            <div className="col-xs-12 col-sm-6 text-center text-sm-start ">
              <Button variant="light" className="nav-btn ms-sm-2" style={{ color: '#544E5D' }}>
                <a
                  href="tel:+97145621020"
                  style={{ display: 'flex', alignItems: 'center', color: '#544E5D', textDecoration: 'none' }}
                >
                  <img src={PhoneIcon} alt="phone" /> +97145621020
                </a>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Enquiry;
