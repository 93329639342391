import React from 'react';
import PropTypes from 'prop-types';

import CartItem from './CartItem';
import { ROUTES } from '../../../routes';
import { useTranslation } from 'react-i18next';

const CartItemsContainer = props => {
  const { cartState, giftCardState, history, removeItem, incrementQuantity, decrementQuantity } = props;
  const { t } = useTranslation('main');

  return (
    <div className="col-second">
      <div className="d-flex justify-content-between">
        <span className="cart-head">{t('cart.cart')}</span>
        <p
          onClick={() => history.push(ROUTES.home)}
          style={{
            cursor: 'pointer',
            textAlign: 'left',
            textDecoration: 'underline',
            letterSpacing: '0px',
            color: '#00B2AE',
            opacity: 1,
            fontWeight: 500,
          }}
        >
          {t('cart.continueShopping')}
        </p>
      </div>
      {cartState.lineItems.map((item, key) => (
        <CartItem
          payment={giftCardState.selectedCountry}
          item={item}
          key={key}
          removeItem={removeItem}
          incrementQuantity={incrementQuantity}
          decrementQuantity={decrementQuantity}
        />
      ))}
    </div>
  );
};

export default CartItemsContainer;

CartItemsContainer.propTypes = {
  cartState: PropTypes.object,
  decrementQuantity: PropTypes.func,
  giftCardState: PropTypes.object,
  history: PropTypes.object,
  incrementQuantity: PropTypes.func,
  removeItem: PropTypes.func,
};
