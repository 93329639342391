import React from 'react';

import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';

const GModal = props => {
  const { show, centered } = props;

  return (
    <Modal centered={centered} show={show} className="g-modal">
      <Modal.Body className="text-center">{props.children}</Modal.Body>
    </Modal>
  );
};

export default GModal;

GModal.propTypes = {
  children: PropTypes.element,
  show: PropTypes.bool,
  centered: PropTypes.bool,
};
