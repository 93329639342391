import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { ROUTES } from '../../routes';
import { usePageLoader } from '../loaders/pageLoader/hooks/usePageLoader';

const ClearComponent = () => {
  const history = useHistory();
  const { onSetPageLoadingAction } = usePageLoader();

  useEffect(() => {
    onSetPageLoadingAction(true);
    localStorage.clear();
    setTimeout(() => {
      history.push({ pathname: ROUTES.home });
    }, 2000);
  }, [onSetPageLoadingAction]);

  return <p>Loading...</p>;
};

export default ClearComponent;
