import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";

const NameCol = ({ formik }) => {
  const { t } = useTranslation();
  return (
    <Col>
      <Form.Group as={Row}>
        <Form.Label className="startgf-fields-text">
          {t("home.startGifting.form.nameLabel")}
        </Form.Label>
        <Col>
          <Form.Control
            className="mb-2"
            id="name"
            name="name"
            placeholder={t("home.startGifting.form.namePlaceholder")}
            onChange={formik.handleChange}
          />
        </Col>
      </Form.Group>
    </Col>
  );
};

export default NameCol;