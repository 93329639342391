import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LoginDivider = ({signUpUrl}) => {
  const { t } = useTranslation();

  return (
    <>
      <p className="login-bottom-text text-center">
        {t('auth.logIn.form.doNotHaveAnAccount')} ?{' '}
        <Link to={signUpUrl}>{t('auth.common.signUpText')}</Link>
      </p>

      <div className="auth-divider-container">
        <div className="divider" />
        <p className="text"> {t('auth.logIn.form.orSignInWith')}</p>
        <div className="divider" />
      </div>
    </>
  );
};

export default LoginDivider;
