import React from 'react';
import Container from 'react-bootstrap/Container';

import InfoPanel from "./InfoPanel";
import { HomeSlider } from "../HomeSlider";

const Cards = () => {

  return (
    <div id={'start-gifting-container'} className="homeBannerSlider">
      <HomeSlider />
      <InfoPanel />
    </div>
  );
};

export default Cards;
