import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';

export const ProfileDropdownItem = ({ linkTo, img, text, alt, onClick }) => (
  <Link to={linkTo || ''} className="link-container">
    <Dropdown.Item as="button" className="userfont-dropdown border-line" onClick={onClick && onClick}>
      <img src={img} alt={alt} className="me-2" />
      {text}
    </Dropdown.Item>
  </Link>
);

ProfileDropdownItem.propTypes = {
  linkTo: PropTypes.string,
  img: PropTypes.string,
  text: PropTypes.string,
  alt: PropTypes.string,
  onClick: PropTypes.func,
};
