import React from 'react';
import { AppRouter } from './routes';
import i18nConfig from './translations/i18n';

import './App.scss';

i18nConfig();

function App() {
  return (
    <div className="App-wrapper">
      <AppRouter />
    </div>
  );
}

export default App;
