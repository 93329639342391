import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getOrderState, orderActions } from '../redux/reducer';
import {
  allOrdersAction,
  createGuestOrderAction,
  createOrderAction,
  failedOrderAction,
  orderDetailsAction,
  processOrderAfterRedirectAction,
} from '../redux/actions';

export const useOrders = () => {
  const dispatch = useDispatch();
  const ordersState = useSelector(getOrderState);

  const onAllOrdersAction = useCallback(
    data => {
      dispatch(allOrdersAction(data));
    },
    [dispatch]
  );

  const onProcessOrderAfterRedirectAction = useCallback(
    async data => {
      await dispatch(processOrderAfterRedirectAction(data));
    },
    [dispatch]
  );

  const onOrderDetailsAction = useCallback(
    async data => {
      await dispatch(orderDetailsAction(data));
    },
    [dispatch]
  );

  const onFailedOrderAction = useCallback(
    async data => {
      await dispatch(failedOrderAction(data));
    },
    [dispatch]
  );

  const onCreateOrderAction = useCallback(
    data => {
      dispatch(createOrderAction(data));
    },
    [dispatch]
  );

  const onCreateGuestOrderAction = useCallback(
    data => {
      dispatch(createGuestOrderAction(data));
    },
    [dispatch]
  );

  const onSetOrdersLoading = useCallback(
    isLoading => {
      dispatch(orderActions.setLoading(isLoading));
    },
    [dispatch]
  );

  const onClearOrdersState = useCallback(() => {
    dispatch(orderActions.clearState());
  }, [dispatch]);

  const onSetGuestPayload = useCallback(
    payload => {
      dispatch(orderActions.setGuestPayload(payload));
    },
    [dispatch]
  );

  return {
    onAllOrdersAction,
    onProcessOrderAfterRedirectAction,
    onOrderDetailsAction,
    onFailedOrderAction,
    onCreateOrderAction,
    onCreateGuestOrderAction,
    onSetOrdersLoading,
    onClearOrdersState,
    onSetGuestPayload,
    ...ordersState,
  };
};
