export const faq = [
  {
    eventKey: 'account_creation',
    content: 'accountCreation',
    count: 3,
    dangerousElements: [1, 2, 3],
  },
  {
    eventKey: 'account_management',
    content: 'accountManagement',
    count: 2,
    dangerousElements: [1, 2],
  },
  {
    eventKey: 'workDoes',
    content: 'howDoesItWork',
    count: 1,
  },
  {
    eventKey: 'aboutGiftCard',
    content: 'aboutGiftiGlobalGiftCard',
    count: 9,
    dangerousElements: [1, 3, 5],
  },
  {
    eventKey: 'privacy',
    content: 'myDataAndPrivacy',
    count: 3,
    dangerousElements: [3],
  },
  {
    eventKey: 'orders',
    content: 'myOrders',
    count: 15,
    dangerousElements: [1, 5, 13, 14, 15],
  },
  {
    eventKey: 'payment',
    content: 'aboutMyPayment',
    count: 5,
    dangerousElements: [1, 2, 3],
  },
  {
    eventKey: 'delivery',
    content: 'aboutMyDelivery',
    count: 5,
    dangerousElements: [1, 2, 3, 4, 5],
  },
  {
    eventKey: 'technicalIssues',
    content: 'technicalIssues',
    count: 1,
    dangerousElements: [1],
  },
];
// const account_creation = () => {
//   return (
//     <Accordion defaultActiveKey="0">
//       <Card>
//         <Accordion.Toggle as={Card.Header} eventKey="0">
//           How can I create an account
//         </Accordion.Toggle>
//         <Accordion.Collapse eventKey="0">
//           <Card.Body dangerouslySetInnerHTML={{
//             __html: t('accountCreation-Body1', { interpolation: { escapeValue: false } }),
//           }}/>
//         </Accordion.Collapse>
//       </Card>
//       <Card>
//         <Accordion.Toggle as={Card.Header} eventKey="1">
//           Can I create multiple accounts?
//         </Accordion.Toggle>
//         <Accordion.Collapse eventKey="1">
//           <Card.Body>
//             <p>
//               {t('accountCreation-Body2')}
//             </p>
//           </Card.Body>
//         </Accordion.Collapse>
//       </Card>
//       <Card>
//         <Accordion.Toggle as={Card.Header} eventKey="2">
//           {t('accountCreation-Title3')}
//         </Accordion.Toggle>
//         <Accordion.Collapse eventKey="2">
//           <Card.Body>
//             <p dangerouslySetInnerHTML={{
//               __html: t('accountCreation-Body3', { interpolation: { escapeValue: false } }),
//             }} />
//           </Card.Body>
//         </Accordion.Collapse>
//       </Card>
//     </Accordion>
//   );
// };
