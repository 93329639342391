import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import {getPhoneLengthByCountryNameAction, getProfileListAction, updateUserProfileAction} from './actions';

export const PROFILE_INITIAL_STATE = {
  profile: null,
  error: null,
  profile_updated: false,
  phone_max_length: 0,
  phone_min_length: 0,
  country_code: null
};

export const PROFILE__FEATURE_KEY = 'profile';
export const ProfileAdapter = createEntityAdapter();
export const initialProfileState = ProfileAdapter.getInitialState(PROFILE_INITIAL_STATE);

export const profileSlice = createSlice({
  name: PROFILE__FEATURE_KEY,
  initialState: PROFILE_INITIAL_STATE,
  reducers: {
    clearErrors(state) {
      state.error = null;
      state.profile_updated = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getProfileListAction.pending, state => {
        state.is_active = true;
      })
      .addCase(getProfileListAction.fulfilled, (state, action) => {
        const response = action.payload;
        if (response?.code === 200) {
          state.profile = response.data.profile;
        }
      })
      .addCase(getProfileListAction.rejected, state => {
        state.is_active = true;
      })
      .addCase(updateUserProfileAction.pending, state => {
        state.is_active = true;
        state.error = null;
        state.profile_updated = false;
      })
      .addCase(updateUserProfileAction.fulfilled, (state, action) => {
        const response = action.payload;
        if (response?.code === 200) {
          state.profile = response.data.profile;
          state.profile_updated = true;
        } else {
          state.error = response?.message || 'Error in updating profile.';
        }
      })
      .addCase(updateUserProfileAction.rejected, state => {
        state.is_active = true;
        state.error = null;
        state.profile_updated = true;
      })
      .addCase(getPhoneLengthByCountryNameAction.pending, state => {
        state.is_active = true;
      })
      .addCase(getPhoneLengthByCountryNameAction.fulfilled, (state, action) => {
        const response = action.payload;
        if (response?.code === 200) {
          state.phone_max_length = response.data.phone_max_length;
          state.phone_min_length = response.data.phone_min_length;
          state.country_code = response.data.country_code;
        }
      })
      .addCase(getPhoneLengthByCountryNameAction.rejected, state => {
        state.is_active = true;
      });
  },
});

export const profileReducer = profileSlice.reducer;
export const profileActions = profileSlice.actions;
export const { selectAll, selectEntities } = ProfileAdapter.getSelectors();
export const getProfileState = rootState => rootState[PROFILE__FEATURE_KEY];
/*export const selectAllProfile = createSelector(getProfileState, selectAll);*/
export const selectProfileEntities = createSelector(getProfileState, selectEntities);
