import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ROUTES } from '../../../../routes';
import { useOrders } from '../../hooks/useOrders';

import { PaidAmountColumn } from './PaidAmountColumn';
import { OrderNumberColumn } from './OrderNumberColumn';
import { ImageColumn } from './ImageColumn';
import { DateColumn } from './DateColumn';
import { StatusColumn } from './StatusColumn';

import './userOrders.scss';

const UserOrders = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { onAllOrdersAction, ...orderState } = useOrders();
  const [selectedProduct1, setSelectedProduct1] = useState(null);

  useEffect(() => {
    onAllOrdersAction({
      image_size: 'medium_rectangle',
      limit: 50,
      offset: 0,
    });
  }, [onAllOrdersAction]);

  const handleRowSelection = e => {
    setSelectedProduct1(e.data.id);
    history.push({
      pathname: ROUTES.order.confirmOrder,
      search: `?order_id=${e.data.id}`,
    });
  };

  return (
    <div className="datatable-responsive-demo">
      <div className="container mt-4">
        <p className="order mb-5">{t('orders.yourOrders')}</p>
        <div className="card mb-5">
          <DataTable
            value={orderState.data}
            resizableColumns
            columnResizeMode="fit"
            className="p-datatable-responsive-demo"
            paginator
            rows={4}
            selectionMode="single"
            selection={selectedProduct1}
            onRowSelect={e => handleRowSelection(e)}
          >
            <Column
              field="Giftcards"
              header={t('orders.giftCards')}
              body={rowData => <ImageColumn rowData={rowData} />}
            />
            <Column field="Date" header={t('orders.date')} body={rowData => <DateColumn rowData={rowData} />} />
            <Column
              field="Order No"
              header={t('orders.orderNo')}
              body={rowData => <OrderNumberColumn rowData={rowData} />}
            />
            <Column
              field="Amount paid"
              header={t('orders.amountPaid')}
              body={rowData => <PaidAmountColumn rowData={rowData} />}
            />
            <Column field="Status" header={t('orders.status')} body={rowData => <StatusColumn rowData={rowData} />} />
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default UserOrders;
