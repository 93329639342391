import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { categoryActions, getCategoryState } from '../redux/reducer';
import { categoriesAction } from '../redux/actions';

export const useCategory = () => {
  const dispatch = useDispatch();
  const categoryState = useSelector(getCategoryState);

  const selectedCategoryName = useMemo(() => {
    const categoryId = categoryState?.category_id;
    if (categoryId === 'ALL') return 'All';
    return categoryState.data?.find(category => category.id === categoryState.category_id)?.name || 'All';
  }, [categoryState.data, categoryState.category_id]);

  const onCategoriesAction = useCallback(
    data => {
      dispatch(categoriesAction(data));
    },
    [dispatch]
  );

  const onSetCategoryId = useCallback(
    data => {
      dispatch(categoryActions.setCategoryId(data));
    },
    [dispatch]
  );

  return {
    onCategoriesAction,
    onSetCategoryId,
    selectedCategoryName,
    ...categoryState,
  };
};
