import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import { ProtectedRoute, ROUTES } from './index';
import {
  AllCardsPage,
  AllFeaturedCardsPage,
  CartPage,
  CheckoutPage,
  ClearPage,
  EditProfilePage,
  EnquiryPage,
  FailurePage,
  ForgotPasswordPage,
  HomePage,
  LoginPage,
  MyWorldPage,
  NotFoundPage,
  PrivacyPolicyPage,
  ResetPasswordPage,
  RewardPointsPage,
  SelectCardsPage,
  SignUpPage,
  StepperPage,
  SuccessPage,
  UserOrdersPage,
} from '../pages';
import { ConfirmOrder } from '../features/orders/components/ConfirmOrder';
import ConditionsPage from '../features/conditions';
import { MainLayout } from '../shared/layouts';
import LoginRedeemPage from '../pages/LoginRedeem';
import SignUpRedeemPage from '../pages/SignUpRedeem';
import RedeemCardPage from '../pages/RedeemCard';
import GiveawayPage from "../pages/GiveawayPage";
import MerchandiseSearch from '../features/giftomatic/search';

const AppRouter = () => {
  return (
    <Router>
      <MainLayout externalRoutes={[ROUTES.theBigHandshake]}>
        <Switch>
          <Route path={ROUTES.theBigHandshake} component={GiveawayPage} exact />
          <Route path={ROUTES.notFound} component={NotFoundPage} />
          <Route path={ROUTES.home} component={HomePage} exact />
          <Route path={ROUTES.auth.logIn} component={LoginPage} exact />
          <Route path={ROUTES.auth.signUp} component={SignUpPage} exact />
          <Route path={ROUTES.auth.forgotPassword} component={ForgotPasswordPage} exact />
          <Route path={ROUTES.auth.resetPassword} component={ResetPasswordPage} exact />
          <Route path={ROUTES.forgotPassword} component={ForgotPasswordPage} exact />
          <Route path={ROUTES.allCards} component={AllCardsPage} exact />
          <Route path={ROUTES.merchandise} component={MerchandiseSearch } exact />
          <Route path={ROUTES.allFeaturedCards} component={AllFeaturedCardsPage} exact />
          <Route path={ROUTES.selectCard} component={SelectCardsPage} exact />
          <Route path={ROUTES.myWorld} component={MyWorldPage} exact />
          <ProtectedRoute path={ROUTES.order.allOrder} component={UserOrdersPage} exact />
          <Route path={ROUTES.cart} component={CartPage} exact />
          <Route path={ROUTES.order.confirmOrder}>
            <ConfirmOrder showOrdersHeading={true} />
          </Route>
          <ProtectedRoute path={ROUTES.profile} component={EditProfilePage} exact />
          <ProtectedRoute path={ROUTES.rewardPoints} component={RewardPointsPage} exact />
          <Route path={ROUTES.checkout} component={CheckoutPage} exact />
          <Route path={ROUTES.payment} component={StepperPage} exact />
          <Route path={ROUTES.termsAndConditions} component={ConditionsPage} exact />
          <Route path={ROUTES.faq} component={EnquiryPage} exact />
          <Route path={ROUTES.privacyPolicy} component={PrivacyPolicyPage} exact />
          {/*<Route path={ROUTES.promotionTerms} component={PromotionTermsPage} exact />*/}
          <Route path={ROUTES.failure} component={FailurePage} exact />
          <Route path={ROUTES.success} component={SuccessPage} exact />
          <Route path={ROUTES.clear} component={ClearPage} exact />
          <Route path={ROUTES.redeemCard} component={RedeemCardPage} exact />
          <Route path={ROUTES.auth.logInRedeem} component={LoginRedeemPage} exact />
          <Route path={ROUTES.auth.signUpRedeem} component={SignUpRedeemPage} exact />
          <Route path={ROUTES.home} exact>
            <Redirect to={{ pathname: ROUTES.home }} />
          </Route>
          <Route>
            <Redirect to={{ pathname: ROUTES.notFound }} />
          </Route>
        </Switch>
      </MainLayout>
    </Router>
  );
};

export default AppRouter;
