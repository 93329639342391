import React from 'react';
import PropTypes from 'prop-types';

export const PrivacyPolicyLinkListItem = ({ text, href, tabIndex }) => (
  <div className="privacy-item-style">
    <a href={href} tabIndex={tabIndex.toString()}>
      {text}
    </a>
  </div>
);

PrivacyPolicyLinkListItem.propTypes = {
  tabIndex: PropTypes.number,
  text: PropTypes.string,
  href: PropTypes.string,
};
