import React, { useEffect, useMemo, useState } from 'react';
import { Button, ButtonGroup, Col, Container, Row } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import { useHistory, useParams } from 'react-router-dom';
import { get, map } from 'lodash';

import Footer1 from './Stikyfooter';
import GiftiGiftCard from './GiftGiftCard';
import { getFixerConvertedAmount } from '../api';
import { useAuth } from '../../auth/hooks/useAuth';
import { useBrands } from '../../brands/hooks/useBrands';
import { useGiftcards } from '../hooks/useGiftcards';
import { ROUTES } from '../../../routes';
import { useCart } from '../../cart/hooks/useCart';
import { useCommon } from '../../common/hooks/useCommon';
import { usePageLoader } from '../../../shared/loaders/pageLoader/hooks/usePageLoader';

import plusicon from '../../../assets/media/icons/+.svg';
import minusicon from '../../../assets/media/icons/minus.svg';
import { useTranslation } from 'react-i18next';
import { getQueryParamFromSearch } from '../../../utils';
import { useTopBar } from '../../topBar/hooks/useTopBar';
import { useSelector } from 'react-redux';
import { getBrandsState } from '../../brands/redux/reducer';
import { Modals } from '../../../shared';
import CancelImage from '../../../assets/media/icons/failed.svg';

const SelectCards = () => {
  const {
    location: { search, pathname },
    push,
  } = useHistory();
  const { cardId } = useParams();
  const { onTermBrandAction, onDescriptionBrandAction } = useBrands();
  const { selectedBrand, ...brandsState } = useSelector(getBrandsState);
  const { countries } = useTopBar();
  const { onUpdateCartAction, onSaveItemsToCart, onUpdateTotalCartItems, lineItems, onFetchItemsByCartAction, ...cartState } = useCart();
  const { onSetPageLoadingAction } = usePageLoader();
  const [eventKey11, seteventkey] = useState(1);
  const [selectedDenomination, setSelectedDenomiation] = useState(null);
  const [count, setCount] = useState(0);
  const [isBuyNow, setIsBuyNow] = useState(false);
  const { selectedCountry, onSelectCountry, countries: giftCardsUnits } = useGiftcards();
  const { isAuthenticated } = useAuth();
  const commonState = useCommon();
  const [tempvisible, setTempVisible] = useState(!commonState?.giftingToUser?.name);
  const [rate, setRate] = useState(0);
  const [giftTo, setGiftTo] = useState(commonState?.giftingToUser?.name ? 'someoneElse' : 'myself');
  const [giftToParams, setGiftToParams] = useState({
    email: null,
    name: commonState?.giftingToUser?.name || null,
    message: null,
    gifting_image_id: null,
  });
  const [initialCurrency, setInitialCurrency] = useState();
  const [cartModal, setCartModal] = useState(false);

  const currencyQueryParam = useMemo(() => Number(getQueryParamFromSearch(search, 'currency')), [search]);
  const selectedCurrencyCountry = useMemo(
    () => giftCardsUnits && giftCardsUnits?.find(unit => unit.id === currencyQueryParam),
    [giftCardsUnits, countries, currencyQueryParam]
  );

  const selectedCurrency = useMemo(() => cartState?.selectedCartCurrency?.unit_name_short, [cartState]);

  const { t } = useTranslation('main');

  const handleSelect = eventKey1 => {
    seteventkey(eventKey1);
  };
  const handleGiftTo = e => {
    setTempVisible(e.target.value === 'myself');
    setGiftTo(e.target.value);
    setGiftToParams({
      email: null,
      name: null,
      message: null,
      gifting_image_id: null,
    });
  };
  const increment = () => {
    if (count >= 5) {
      return null;
    } else {
      const inc = parseFloat(selectedDenomination * (count + 1));
      setCount(count + 1);
      setRate(inc);
    }
  };
  const decrement = () => {
    if (count === 0) {
      return null;
    } else {
      const dec = parseFloat(selectedDenomination * (count - 1));
      setCount(count - 1);
      setRate(dec);
    }
  };
  const handleDenomination = d => {
    setSelectedDenomiation(parseFloat(d));
    setCount(1);
    setRate(d);
  };

  useEffect(() => {
    if (giftCardsUnits?.length > 0 && !selectedCurrencyCountry) push(ROUTES.notFound);
    if (selectedCountry?.id === selectedCurrencyCountry) return;

    onSelectCountry(selectedCurrencyCountry);
  }, [giftCardsUnits, currencyQueryParam, selectedCurrencyCountry, push, selectedCountry, onSelectCountry]);

  useEffect(() => {
    if (!(cardId && Number(cardId) >= 0)) {
      push(ROUTES.home);
    }
    setInitialCurrency(currencyQueryParam);
    onTermBrandAction({
      currency: currencyQueryParam,
      id: cardId,
    });
    onDescriptionBrandAction({
      currency: currencyQueryParam,
      program_id: 1,
      id: cardId,
      image_size: 'medium_rectangle',
      image_type: 'Color',
    });
  }, [currencyQueryParam, search, push, onTermBrandAction, onDescriptionBrandAction]);

  useEffect(() => {
    /* If the selected country has been changed, the currency in the URL need to change also. */
    if (giftCardsUnits?.length > 0 && initialCurrency && selectedCountry?.id !== currencyQueryParam) {
      push(`${pathname}?currency=${currencyQueryParam}`);
    }
  }, [selectedCountry]);

  const saveToCart = async shouldRedirect => {
    if (!countries || !countries.length) return;

    if(!isAuthenticated) return push(ROUTES.auth.logIn)

    // if(cartState?.lineItems?.length===0){
    //   onFetchItemsByCartAction({
    //     currency_code: selectedCurrency || 'AED',
    //     use_giftiglobal_points: false
    //   });
    // }

    if(cartState?.pricebreakdown?.cartCurrency !== "" && (cartState?.pricebreakdown?.cartCurrency !== selectedBrand?.unit_name)) {
      setCartModal(true)
      return
    }

    onSetPageLoadingAction(true);

    const country_id1 = countries?.find(country => country.country_name === selectedCountry?.country_name)?.id;
    const addToCartItem = {
      quantity: count,
      brand_id: selectedBrand.id,
      currency: selectedCountry?.unit_name_short,
      country_id: country_id1,
      giftcard_image: selectedBrand.images.color.medium_rectangle,
      giftcard_value: selectedDenomination,
      card_value_aed: selectedDenomination,
      isforself: giftTo === 'myself',
      name: selectedBrand.name,
      country_name: selectedCountry.country_name,
      markup_percentage: selectedBrand?.markup_percentage || null,
    };
    if (!addToCartItem.isforself) {
      addToCartItem['gift_message'] = giftToParams?.message || '';
      addToCartItem['contact_name'] = giftToParams?.name || null;
      addToCartItem['contact_email'] = giftToParams?.email || null;
      addToCartItem['gifting_image_id'] = giftToParams?.gifting_image_id || '';
    }

    if (selectedCountry?.unit_name_short && selectedCountry?.unit_name_short !== 'AED') {
      const response = await getFixerConvertedAmount(selectedDenomination, selectedCountry?.unit_name_short, 'AED');
      addToCartItem.card_value_aed = response.data.converted_amount; // Converted amount is already fixed to 2 decimal places
    }
    if (isAuthenticated) {
      onUpdateCartAction(addToCartItem);
    } else {
      const isItemPresent = lineItems.find(
        item => item.brand_id === addToCartItem.brand_id && item.giftcard_value === addToCartItem.giftcard_value
      );
      let updatedLineItems;
      if (isItemPresent) {
        updatedLineItems = lineItems.map(item => {
          if (item.brand_id === addToCartItem.brand_id && item.giftcard_value === addToCartItem.giftcard_value) {
            return Object.assign({}, item, {
              quantity: parseFloat(item.quantity) + parseFloat(addToCartItem.quantity),
            });
          }
          return item;
        });
        onSaveItemsToCart(updatedLineItems);
      } else {
        updatedLineItems = [addToCartItem, ...lineItems];
        onSaveItemsToCart(updatedLineItems);
      }

      onUpdateTotalCartItems(updatedLineItems?.length);
    }
    setCount(0);
    setRate(0);
    setSelectedDenomiation(null);
    onSetPageLoadingAction(false);
    if (shouldRedirect) {
      push(ROUTES.cart);
    }
  };
  const saveToCartDelete = async shouldRedirect => {
    if (!countries || !countries.length) return;

    setCartModal(false)

    onSetPageLoadingAction(true);

    const country_id1 = countries?.find(country => country.country_name === selectedCountry?.country_name)?.id;
    const addToCartItem = {
      quantity: count,
      brand_id: selectedBrand.id,
      currency: selectedCountry?.unit_name_short,
      country_id: country_id1,
      giftcard_image: selectedBrand.images.color.medium_rectangle,
      giftcard_value: selectedDenomination,
      card_value_aed: selectedDenomination,
      isforself: giftTo === 'myself',
      name: selectedBrand.name,
      country_name: selectedCountry.country_name,
      markup_percentage: selectedBrand?.markup_percentage || null,
    };
    if (!addToCartItem.isforself) {
      addToCartItem['gift_message'] = giftToParams?.message || '';
      addToCartItem['contact_name'] = giftToParams?.name || null;
      addToCartItem['contact_email'] = giftToParams?.email || null;
      addToCartItem['gifting_image_id'] = giftToParams?.gifting_image_id || '';
    }

    if (selectedCountry?.unit_name_short && selectedCountry?.unit_name_short !== 'AED') {
      const response = await getFixerConvertedAmount(selectedDenomination, selectedCountry?.unit_name_short, 'AED');
      addToCartItem.card_value_aed = response.data.converted_amount; // Converted amount is already fixed to 2 decimal places
    }
    if (isAuthenticated) {
      onUpdateCartAction(addToCartItem);
    } else {
      const isItemPresent = lineItems.find(
        item => item.brand_id === addToCartItem.brand_id && item.giftcard_value === addToCartItem.giftcard_value
      );
      let updatedLineItems;
      if (isItemPresent) {
        updatedLineItems = lineItems.map(item => {
          if (item.brand_id === addToCartItem.brand_id && item.giftcard_value === addToCartItem.giftcard_value) {
            return Object.assign({}, item, {
              quantity: parseFloat(item.quantity) + parseFloat(addToCartItem.quantity),
            });
          }
          return item;
        });
        onSaveItemsToCart(updatedLineItems);
      } else {
        updatedLineItems = [addToCartItem, ...lineItems];
        onSaveItemsToCart(updatedLineItems);
      }

      onUpdateTotalCartItems(updatedLineItems?.length);
    }
    setCount(0);
    setRate(0);
    setSelectedDenomiation(null);
    onSetPageLoadingAction(false);
    if (isBuyNow) {
      push(ROUTES.cart);
    }
  };

  return (
    <>
      <Container fluid className="selected-card-box">
        <Row>
          <Col md={4}>
            <div style={{ textAlign: 'center' }}>
              <img
                src={get(selectedBrand, 'images.color.medium_rectangle')}
                alt="gift card"
                style={{ maxWidth: '100%' }}
              />
            </div>
          </Col>
          <Col md={8}>
            <div className="selected-card-details">
              <p className="select-card-text-lg">{get(selectedBrand, 'name')}</p>
              {selectedCountry && (
                <p className="select-card-text">{`${t('cards.selectCardValue')} (${get(
                  selectedCountry,
                  'unit_name_short'
                )})`}</p>
              )}
              <div className="card-amnt mt-3">
                {selectedBrand &&
                  map(selectedBrand?.denominations, (d, i) => (
                    <Button
                      variant="outline-info"
                      className="me-3 select-card-button mt-2"
                      onClick={() => handleDenomination(d)}
                      active={selectedDenomination === parseFloat(d)}
                      key={i}
                    >
                      {parseFloat(d)}
                    </Button>
                  ))}
              </div>
              <p className="select-card-text mt-5">{t('common.giftingFor')}</p>
              <div className="row width-limit">
                <Form.Check
                  value="myself"
                  type="radio"
                  className="giftslabs"
                  label="Myself"
                  name="formHorizontalRadios"
                  id="formHorizontalRadios1"
                  checked={giftTo === 'myself'}
                  onChange={e => handleGiftTo(e)}
                />
                <Form.Check
                  value="someoneElse"
                  type="radio"
                  className="giftslabs"
                  label="Someone else"
                  name="formHorizontalRadios"
                  id="formHorizontalRadios2"
                  checked={giftTo === 'someoneElse'}
                  onChange={e => handleGiftTo(e)}
                />
              </div>
              {tempvisible === false ? (
                <GiftiGiftCard giftToParams={giftToParams} setGiftToParams={setGiftToParams} />
              ) : (
                ''
              )}
              <div>
                <Nav onSelect={handleSelect}>
                  <Nav.Item id="product">
                    <Nav.Link eventKey="1" active={eventKey11 === '1'}>
                      {t('common.description')}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item id="product">
                    <Nav.Link eventKey="2">{t('common.termsAndConditions')}</Nav.Link>
                  </Nav.Item>
                </Nav>
                {eventKey11 === "1" ? (
                  <div
                    style={{ whiteSpace: "pre-wrap" }}
                    className="mt-4"
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    <p className="mt-4">{selectedBrand?.product_description}</p>
                  </div>
                ) : (
                  <div className="mt-4" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                    <p style={{ whiteSpace: "pre-wrap" }}>
                      {brandsState && brandsState?.terms?.length && brandsState?.terms[0]?.terms_text}
                      <br />
                    </p>
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="fix-footer">
        <Footer1 className="">
          <div className="fix-footer row d-flex align-items-center">
            <div className="col-lg-4" />
            <div className="col-lg-8 col-md-12 col-sm-12">
              <div className="row d-flex align-items-center">
                <div className="col-md-5 col-sm-12 d-flex mb-0 mb-sm-1">
                  <div className="d-flex">
                    <small className="amttext">{t('cards.totalAmount')}</small>
                    <h4 className="md-sm-2 amttext2">
                      {rate} {get(selectedCountry, 'unit_name_short')}
                    </h4>
                  </div>

                  <div className="mobile-view">
                    <ButtonGroup className="btnclasss me-3" aria-label="Second group">
                      <Button variant="link" disabled={!selectedDenomination} onClick={decrement}>
                        {' '}
                        <img style={{ maxHeight: '3px' }} src={minusicon} alt="" />
                      </Button>{' '}
                      <Button disabled variant="link">
                        <b>{count}</b>
                      </Button>{' '}
                      <Button variant="link" disabled={!selectedDenomination} onClick={increment}>
                        {' '}
                        <img style={{ maxHeight: '11px' }} src={plusicon} alt="" />
                      </Button>
                    </ButtonGroup>
                  </div>
                </div>
                <div className="col-md-7 col-sm-12">
                  <span className="desktop-view">
                    <ButtonGroup className="btnclasss me-3" aria-label="Second group">
                      <Button variant="link" disabled={!selectedDenomination} onClick={decrement}>
                        {' '}
                        <img style={{ maxHeight: '3px' }} src={minusicon} alt="" />
                      </Button>{' '}
                      <Button disabled variant="link">
                        <b>{count}</b>
                      </Button>{' '}
                      <Button variant="link" disabled={!selectedDenomination} onClick={increment}>
                        {' '}
                        <img style={{ maxHeight: '11px' }} src={plusicon} alt="" />
                      </Button>
                    </ButtonGroup>
                  </span>
                  <Button
                    className="nav-btn me-2 text-white"
                    disabled={
                      !selectedDenomination ||
                      !count ||
                      (giftTo !== 'myself' &&
                        !(
                          (giftToParams.email && giftToParams.name) ||
                          giftToParams.message ||
                          giftToParams.gifting_image_id
                        ))
                    }
                    onClick={() => {saveToCart(false); setIsBuyNow(false)}}
                    variant="info"
                  >
                    {t('cards.addToCart')}
                  </Button>{' '}
                  <Button
                    className="nav-btn me-2 text-white"
                    disabled={
                      !selectedDenomination ||
                      !count ||
                      (giftTo !== 'myself' &&
                        !(
                          giftToParams.email ||
                          giftToParams.name ||
                          giftToParams.message ||
                          giftToParams.gifting_image_id
                        ))
                    }
                    onClick={() => {saveToCart(true); setIsBuyNow(true)}}
                  >
                    {t('cards.buyNow')}
                  </Button>{' '}
                </div>
              </div>
            </div>
          </div>
        </Footer1>
        <Modals.SharedModal
        icon={CancelImage}
        subText={`${t('cart.widget.modal.cartModalDesc')}.`}
        showModal={cartModal}
        onModalClose={saveToCartDelete}
        buttonTxt={"Yes"}
        buttonTxt2={"Cancel"}
        onModalCancel={setCartModal}
      />
      </div>
    </>
  );
};

export default SelectCards;
