import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTopBarState } from '../redux/reducer';
import { getCountriesListAction } from '../redux/actions';

export const useTopBar = () => {
  const dispatch = useDispatch();
  const topBarState = useSelector(getTopBarState);

  const onGetCountriesListAction = useCallback(() => {
    dispatch(getCountriesListAction());
  }, [dispatch]);

  return {
    onGetCountriesListAction,
    ...topBarState,
  };
};
