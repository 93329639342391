import { ROUTES } from "../../../routes";
import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LoadMore = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClick = () => {
    history.push({ pathname: ROUTES.allCards });
    window.scrollTo(0, 0);
  }

  return (
    <Row className="width-limit">
      <Col xs={12} className="text-center">
        <button
          onClick={() => handleClick()}
          type="button"
          className="my-4 startgf-fields-button btn btn-info btn-md"
        >
          {t("common.loadMore")}
        </button>
      </Col>
    </Row>
  );
};

export default LoadMore;
