import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';

import { Modals } from '../../../shared';

import checkboxImage from '../../../assets/media/icons/checkbox.svg';

export const SuccessPaymentModal = ({ onModalClose }) => {
  const { t } = useTranslation();

  const handleModalClose = () => {
    onModalClose();
  };

  return (
    <Modals.GModal show={true}>
      <Container>
        <Row>
          <Col xs={12} className="text-center">
            <Image src={checkboxImage} rounded className="rounded" style={{ width: '4em' }} />
          </Col>
          <Col xs={12} className="text-center pt-2">
            <h2>{t('payment.paymentSuccessful')}</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="pt-3 text-center">
            <p style={{ opacity: '0.9' }}>{t('payment.successPaymentMessage')}</p>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center">
            <Button
              className="profile-btn mt-2 btn-block text-capitalize"
              variant="info"
              size="lg"
              type="button"
              onClick={handleModalClose}
            >
              {t('common.ok')}
            </Button>
          </Col>
        </Row>
      </Container>
    </Modals.GModal>
  );
};

SuccessPaymentModal.propTypes = {
  onModalClose: PropTypes.func,
};
