import React from "react";
import { useTranslation } from "react-i18next";

const StartGiftingHeading = () => {
  const { t } = useTranslation();
  return (
    <h1 className="startgf-text text-center">
      {t("home.startGifting.primaryHeading")}
    </h1>
  );
};

export default StartGiftingHeading;