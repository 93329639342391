import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import swal from 'sweetalert';

import { getConvertCreditsAction, getRewardPointsAction, getTransactionsAction, redeemGiftCardAction } from './actions';
import i18n from 'i18next';

export const REWARDS_POINTS_INITIAL_STATE = {
  total_credits: null,
  gc_value: null,
  triggerConversion: '',
  status: null,
  code: null,
  message: null,
  conversion_limit: null,
  original_value: null,
  credits: [],
  errors: [],
};

const showErrorPopup = message => {
  swal({
    icon: 'error',
    title: message,
    className: 'notificationMessage',
    buttons: {
      cancel: {
        text: i18n.t('rewardPoints.buttons.cancel'),
        value: false,
        visible: true,
        closeModal: true,
      },
    },
  });
};

export const REWARDS_POINTS_FEATURE_KEY = 'rewardpoints';
export const rewardpointsAdapter = createEntityAdapter();
rewardpointsAdapter.getInitialState(REWARDS_POINTS_INITIAL_STATE);
export const rewardpointsSlice = createSlice({
  name: REWARDS_POINTS_FEATURE_KEY,
  initialState: REWARDS_POINTS_INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getRewardPointsAction.pending, state => {
        state.code = true;
      })
      .addCase(getRewardPointsAction.fulfilled, (state, action) => {
        const response = action.payload;
        if (response?.code === 200) {
          state.code = true;
          state.message = null;
          state.total_credits = response.data.total_credits;
        } else if (response?.code >= 400) {
          const error = [response.message || response.detail || ''];
          showErrorPopup(error);
          state.errors = [error];
        }
      })
      .addCase(getRewardPointsAction.rejected, state => {
        state.code = true;
      })
      .addCase(getTransactionsAction.fulfilled, (state, action) => {
        const response = action.payload;
        if (response?.code === 200) {
          state.credits = response.data.credits;
        } else if (response?.code >= 400) {
          const error = [response.message || response.detail || ''];
          showErrorPopup(error);
          state.errors = [error];
        }
      })
      .addCase(getTransactionsAction.rejected, () => {})
      .addCase(redeemGiftCardAction.fulfilled, (state, action) => {
        const response = action.payload;

        if (response?.error) {
          swal({
            icon: 'error',
            ...{
              ...(response?.error === 'conversion_limit'
                ? {
                    title: i18n.t('rewardPoints.redeemGiftCardAction.conversionLimit.title'),
                    text: i18n.t('rewardPoints.redeemGiftCardAction.conversionLimit.text'),
                  }
                : response?.error === 'card_has_already_been_redeemed'
                ? {
                    title: i18n.t('rewardPoints.redeemGiftCardAction.giftCardRedeemed.title'),
                    text: i18n.t('rewardPoints.redeemGiftCardAction.giftCardRedeemed.text'),
                  }
                : { title: '', text: response?.error || '' }),
            },
            className: 'notificationMessage',
            buttons: {
              cancel: {
                text: i18n.t('rewardPoints.buttons.cancel'),
                value: false,
                visible: true,
                closeModal: true,
              },
            },
          });
        }
      })
      .addCase(getConvertCreditsAction.fulfilled, (state, action) => {
        const response = action.payload;
        if (response?.code === 200) {
          state.total_credits = response.data.total_credits;
          state.gc_value = response.data.gc_value;
          swal({
            title: i18n.t('rewardPoints.getConvertCreditsAction.giftCardConvertedToPoints.title'),
            icon: 'success',
            text: i18n.t('rewardPoints.getConvertCreditsAction.giftCardConvertedToPoints.text'),
            type: '',
            className: 'notificationMessage',
          });
          state.errors = [];
          state.message = response.message;
        } else if (response?.code === 400) {
          const error = [response.message || response.detail || ''];
          showErrorPopup(error);
          state.errors = [error];
        }
      })
      .addCase(getConvertCreditsAction.rejected, () => {});
  },
});

export const rewardpointsReducer = rewardpointsSlice.reducer;
export const rewardpointsActions = rewardpointsSlice.actions;
export const { selectAll, selectEntities } = rewardpointsAdapter.getSelectors();
export const getRewardPointsState = rootState => rootState[REWARDS_POINTS_FEATURE_KEY];
export const selectAllRewardPoints = createSelector(getRewardPointsState, selectAll);
export const selectRewardPointsEntities = createSelector(getRewardPointsState, selectEntities);
