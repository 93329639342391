import React, { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useAuth } from '../../auth/hooks/useAuth';
import { ROUTES } from '../../../routes';
import { useRedeemCard } from '../hooks/useRedeemCard';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const RedeemCard = () => {
  const query = useQuery();
  const token = query.get('token');
  const { isAuthenticated } = useAuth();
  const { onSaveGiftcardNumber, onDecryptHash } = useRedeemCard();

  useEffect(() => {
    const giftcardNumber = onDecryptHash(token);
    if (giftcardNumber) {
      onSaveGiftcardNumber(giftcardNumber);
    }
  }, [onSaveGiftcardNumber, onDecryptHash]);

  return isAuthenticated ? <Redirect to={ROUTES.home} /> : <Redirect to={ROUTES.auth.logInRedeem} />;
};

export default RedeemCard;
