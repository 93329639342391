import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';

import GiftiGlobal from '../../../../assets/media/images/defaultBanner/Gifti+Global+SND.png';

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const DefaultSliderElement = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const history = useHistory();

  return (
    <Row className="cardgifti">
      <Col sm={12} md={5} lg={5} className="homeBanner">
        <h1 className="gifticard-text pt-2">
          {t('home.slider.heading1')}
          <br/>
          {t('home.slider.heading2')}
        </h1>
        <div className="gifticard-text-sub">{t('home.slider.text')}</div>
        <div className="card-text-btn">
          <Button className="startGifting text-white" variant="primary" onClick={() => history.push('allcards')}>
            {t('home.banner.startGiftingButton')}&nbsp;&nbsp;&nbsp;
            <FontAwesomeIcon icon={faArrowRight} />
          </Button>
          <Modal show={show} onHide={handleClose} size={'lg'}>
            <iframe
              width="100%"
              height="350px"
              src="https://www.youtube.com/embed/QOvz41v-ozM"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer;
                      autoplay; clipboard-write;
                       encrypted-media;
                        gyroscope;
                         picture-in-picture"
              allowFullScreen
            />
          </Modal>
        </div>
      </Col>
      <Col sm={12} md={7} lg={7} className="homeBannerImage">
          <img src={GiftiGlobal} alt="Gifti Global" />
      </Col>
    </Row>
  );
};
