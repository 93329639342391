import React from "react";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import NameCol from "./NameCol";
import LivingInCol from "./LivingInCol";
import WantToCol from "./WantToCol";
import AdditionalFields from "./AdditionalFields";

const StartGiftingForm = ({ formik, countries }) => {
  const isWantToSendGift = formik.values.wantTo === 'sendAGift'

  return (
    <Form className="mt-4" onSubmit={formik.handleSubmit}>
      <Row>
        <NameCol {...{formik}}/>
        <LivingInCol {...{formik, countries}} />
        <WantToCol formik={formik}/>
      </Row>
      {isWantToSendGift ? <AdditionalFields {...{formik, countries}}/> : null}
    </Form>
  );
};

export default StartGiftingForm;