import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import StepProgressBar from 'react-step-progress';
import { useTranslation } from 'react-i18next';

import GuestFrom from './GuestForm';
import Stripe from './Stripe';
import { orderActions } from '../../orders/redux/reducer';
import { useAuth } from '../../auth/hooks/useAuth';
import { useCart } from '../../cart/hooks/useCart';
import { useOrders } from '../../orders/hooks/useOrders';
import { ROUTES } from '../../../routes';

import 'react-step-progress/dist/index.css';

const Stepper = () => {
  const { push } = useHistory();

  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();
  const ordersState = useOrders();
  const cartState = useCart();

  useEffect(() => {
    if (!cartState.checkoutCart && cartState.lineItems.length === 0) push(ROUTES.cart);
  }, [cartState]);

  useEffect(() => ordersState.onSetOrdersLoading(false), []);

  return (
    <StepProgressBar
      startingStep={isAuthenticated ? 1 : 0}
      primaryBtnClass={'invisible cart-next-btn'}
      secondaryBtnClass={'invisible cart-prev-btn'}
      steps={[
        {
          label: t('payment.steps.checkout'),
          name: 'step 1',
          content: <GuestFrom orderState={ordersState} cartState={cartState} orderActions={orderActions} />,
        },
        {
          label: t('payment.steps.payment'),
          name: 'step 2',
          content: <Stripe />,
        },
        {
          label: t('payment.steps.orderDetails'),
          name: 'step 3',
          content: '',
        },
      ]}
    />
  );
};

export default Stepper;
