import Button from 'react-bootstrap/Button';
import { ROUTES } from '../../../../routes';
import Col from 'react-bootstrap/Col';
import React from 'react';
import { useSelector } from 'react-redux';
import { getGiftcardsState } from '../../../cards/redux/reducer';
import { giftomatic_countries_mapping } from '../../../giftomatic/searchCountriesMapping';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const SearchProductButtons = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const giftcardsState = useSelector(getGiftcardsState);

  const currentCountry = giftcardsState?.selectedCountry?.country_name;
  const showSearchForProductsButton = Object.keys(giftomatic_countries_mapping).includes(currentCountry);

  return (
    <Col className={'d-flex justify-content-center'}>
      <div className='mt-3 d-flex flex-column flex-md-row'>
        <Button
          variant='info'
          className='rounded-0'
          onClick={() => history.push(ROUTES.allCards)}
        >
          {t('rewardPoints.points.selectGiftCard')}
        </Button>
        {showSearchForProductsButton ? (
          <>
            {' '}<span className='font-weight-bold ms-3 me-3 m-auto text-center'>Or</span>{' '}
            <Button
              variant='info'
              className='rounded-0'
              onClick={() => window.location.href = ROUTES.merchandise}
            >
              {t('rewardPoints.points.searchForProducts')}
            </Button>
          </>
        ) : null}
      </div>
    </Col>
  );
};

export default SearchProductButtons;