import React, { useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { ROUTES } from '../../../routes';
import { useAuth } from '../hooks/useAuth';

import Passwordicon from '../../../assets/media/icons/Password-icon.svg';

const ResetPassword = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { onResetPassword, onUpdatePassword, isAuthenticated, reset, errors } = useAuth();

  const formik = useFormik({
    initialValues: {
      password: '',
      password_confirmation: '',
      token: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(2, `${t('validation:minFieldLength')} 2`)
        .max(200, `${t('validation:maxFieldLength')} 200`)
        .required(t('validation:requiredField')),
      password_confirmation: Yup.string()
        .min(2, `${t('validation:minFieldLength')} 2`)
        .max(200, `${t('validation:maxFieldLength')} 200`)
        .required(t('validation:requiredField')),
    }),
    onSubmit: data => {
      if (isAuthenticated) {
        onUpdatePassword(data);
      } else {
        onResetPassword(data);
      }
    },
  });

  useEffect(() => {
    const token = history.location.search.substring(1, history.location.search.length);
    formik.setFieldValue('token', token);
  }, [history]);

  useEffect(() => {
    if (reset) {
      history.push({ pathname: ROUTES.auth.logIn });
    }
  }, [reset, history]);

  useEffect(() => {
    if (errors) {
      formik.setErrors(errors);
    }
  }, [errors]);

  return (
    <>
      <Container>
        <Row>
          <Col>
            <div className="forgot-password mx-auto">
              <p className="login-text text-center h3">{t('auth.resetPassword.title')}</p>
              <Form className="mt-4" onSubmit={formik.handleSubmit}>
                <Form.Group controlId="formBasicnew_password" className="icons_login">
                  <Form.Control
                    size="md"
                    type="password"
                    placeholder={t('profile.newPassword')}
                    className="icons_fields"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    name="password"
                  />
                  <img src={Passwordicon} alt="Icon" className="icon_img" />
                </Form.Group>
                {formik.touched.password && formik.errors.password ? (
                  <p className="validation-messages">{formik.errors.password}</p>
                ) : null}

                <Form.Group controlId="formBasicconfirm_password" className="icons_login">
                  <Form.Control
                    size="md"
                    type="password"
                    placeholder={t('profile.confirmPassword')}
                    className="icons_fields"
                    value={formik.values.password_confirmation}
                    onChange={formik.handleChange}
                    name="password_confirmation"
                  />
                  <img src={Passwordicon} alt="Icon" className="icon_img" />
                </Form.Group>
                {formik.touched.password_confirmation && formik.errors.password_confirmation ? (
                  <p className="validation-messages">{formik.errors.password_confirmation}</p>
                ) : null}
                <Button className="btn-custom" variant="info" size="lg" type="submit">
                  {t('auth.resetPassword.form.changePassword')}
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ResetPassword;
