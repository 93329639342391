export const privacyPolicy = [
  {
    id: 'info',
    localizationKey: 'article2',
  },
  {
    id: 'useinfo',
    localizationKey: 'article3',
  },
  {
    id: 'discloseinfo',
    localizationKey: 'article4',
  },
  {
    id: 'storeinfo',
    localizationKey: 'article5',
  },
  {
    id: 'security',
    localizationKey: 'article6',
  },
  {
    id: 'access',
    localizationKey: 'article7',
  },
  {
    id: 'privacy',
    localizationKey: 'article8',
  },
  {
    id: 'contact',
    localizationKey: 'article9',
  },
  {
    id: 'contacting',
    localizationKey: 'article10',
  },
];
