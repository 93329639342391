import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

export const Link = ({ children, variant = 'primary', className, ...linkProps }) => (
  <RouterLink {...linkProps} className={`nav-btn text-white btn btn-${variant} ${className}`}>
    {children}
  </RouterLink>
);

Link.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'info', 'link']),
};
