import { api } from '../../../api';

export const giftCardsUnitService = () => {
  return api.get('/v1/giftcard_units');
};

export const giftCardThemeApiCall = () => {
  return api.get('/v1/gifting_images');
};

export const getConversionRateService = giftcardConversion => {
  const { currency } = giftcardConversion;
  return api.get(`/v1/giftcard_units/get_conversion_rate`, { params: { currency } });
};

export const getPaymentCurrencyService = () => {
  return api.get(`/v1/giftcard_units/get_payment_currencies`);
};

export const getFixerConvertedAmount = (amount, source_currency, dest_currency) => {
  return api.get('/v1/giftcard_units/get_conversion_amount', {
    params: {
      amount,
      source_currency,
      dest_currency,
    },
  });
};

// export const getFixerConvertedAmount = async (amount, source_currency, dest_currency) => {
//   const fixerURL = `${process.env.REACT_APP_FIXER_URL}`;
//   const fixerAPIKey = `${process.env.REACT_APP_FIXER_API_KEY}`;
//   const url = `${fixerURL}/convert?access_key=${fixerAPIKey}&from=${source_currency}&to=${dest_currency}&amount=${amount}`;
//   const request = await fetch(url, { method: 'GET' });
//   const response = await request.json();
//   if (response && response.success) {
//     return {
//       converted_amount: parseFloat(amount) * parseFloat(parseFloat(response?.info?.rate).toFixed(2)),
//     };
//   }
//   return null;
// };
