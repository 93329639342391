import React, { useMemo } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ROUTES } from '../../routes';
import { ProfileDropdownItem } from './ProfileDropdownItem';

export const ProfileDropdown = props => {
  const { clearSession, user, profileIcon, exitIcon, cartIcon, coinsIcon } = props;

  const { t } = useTranslation();

  const dropdownItems = useMemo(
    () => [
      {
        img: profileIcon,
        alt: 'profile',
        text: t('common.profile'),
        linkTo: ROUTES.profile,
      },
      {
        img: coinsIcon,
        alt: 'points',
        text: t('common.giftiGlobalPoints'),
        linkTo: ROUTES.rewardPoints,
      },
      {
        img: cartIcon,
        alt: 'orders',
        text: t('common.orders'),
        linkTo: ROUTES.order.allOrder,
      },
      {
        img: exitIcon,
        alt: 'logout',
        text: t('common.logout'),
        onClick: clearSession,
      },
    ],
    [profileIcon, coinsIcon, cartIcon, exitIcon, clearSession]
  );

  return (
    <Dropdown>
      <Dropdown.Toggle variant="link" className="nav-btn text-decoration-none border-0">
        <p className="text-dark ml-2 mb-0 me-2 text-decoration-none navigation-login-text">{t("common.hello") + ", " + user}</p>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {dropdownItems.map((dropdownItem, key) => (
          <ProfileDropdownItem {...dropdownItem} key={`dropdown-item-${key}`} />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

ProfileDropdown.propTypes = {
  cartIcon: PropTypes.string,
  clearSession: PropTypes.func,
  coinsIcon: PropTypes.string,
  exitIcon: PropTypes.string,
  profileIcon: PropTypes.string,
  user: PropTypes.string,
  userLoginIcon: PropTypes.string,
};
