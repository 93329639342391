import React from "react";
import Row from "react-bootstrap/Row";
import { isEmpty } from "lodash";
import BrandCardList from "../BrandCardList";
import SearchBar from "../../../cards/components/SearchBar";
import PropTypes from "prop-types";

const BrandedCards = ({ brands, allBrandsByCategories, searchString, setSearchString }) => {
  return (
    <Row className="px-md-5">
      <SearchBar {...{searchString, setSearchString}}/>
      {isEmpty(brands) ? (
        <BrandCardList brandsList={allBrandsByCategories} searchString={searchString}/>
      ) : (
        <BrandCardList brandsList={brands} searchString={searchString}/>
      )}
    </Row>
  );
};

BrandedCards.propTypes = {
  brands: PropTypes.arrayOf(PropTypes.object),
  allBrandsByCategories: PropTypes.arrayOf(PropTypes.object),
  searchString: PropTypes.string,
  setSearchString: PropTypes.func
};

export default BrandedCards;