import React from 'react';
import PropTypes from 'prop-types';

import BrandCard from './BrandCard';
import { filterByCardName } from "../../cards/utils/filterByCardName";

const BrandCardList = ({ brandsList, searchString }) => {
    return filterByCardName(brandsList, searchString)?.map((brand, index) => <BrandCard key={`brand-card-${index}`} brand={brand} />);
};

BrandCardList.propTypes = {
  brandsList: PropTypes.arrayOf(PropTypes.object),
  searchString: PropTypes.string
};

export default BrandCardList;
