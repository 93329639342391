import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPageLoaderState, pageLoaderActions } from '../redux/page-loader.reducer';

export const usePageLoader = () => {
  const dispatch = useDispatch();
  const pageLoaderState = useSelector(getPageLoaderState);

  const onSetPageLoadingAction = useCallback(
    isLoading => {
      dispatch(pageLoaderActions.setPageLoadingAction(isLoading));
    },
    [dispatch]
  );

  return {
    onSetPageLoadingAction,
    ...pageLoaderState,
  };
};
