import { createAsyncThunk } from '@reduxjs/toolkit';
import { commonActions } from './reducer';
import { pageLoaderActions } from '../../../shared/loaders/pageLoader/redux/page-loader.reducer';
import { contactUsAPI } from '../api';

export const contactUsAction = createAsyncThunk('common/contactus/send', async (payload, thunkAPI) => {
  const { dispatch } = thunkAPI;
  dispatch(pageLoaderActions.setPageLoadingAction(true));
  const response = await contactUsAPI(payload);
  dispatch(commonActions.updateContact(response?.message));
  dispatch(pageLoaderActions.setPageLoadingAction(false));
  return response;
});
