import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faLinkedin, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';

import Map from '../../assets/media/icons/Map.png';
import Logo from '../../assets/media/icons/logo.svg';
import Mail from '../../assets/media/icons/Mail.png';
import PhoneIcon from '../../assets/media/icons/PhoneIcon.png';
import { ROUTES } from '../../routes';

import '../../App.scss';
import './index.scss';
import config from '../../config';

const Footer = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [date, setDate] = useState();
  const getYear = () => {
    setDate(new Date().getFullYear());
  };
  useEffect(() => {
    getYear();
  }, []);

  return (
    <footer className="home-footer p-2">
      <Container>
        <Row>
          <Col sm={12} md={4}>
            <Row>
              <Col xs={6} md={12}>
                <img src={Logo} alt="Icon" className="figure-img" />
              </Col>
              <Col xs={6} className="d-md-none mt-3">
                <a href="https://www.facebook.com/giftiglobal/" className="social">
                  <FontAwesomeIcon icon={faFacebookF} className="me-3" />
                </a>
                <a href="https://www.youtube.com/channel/UCdCdTEynac-Z_w3A3PSSXmg" className="social">
                  <FontAwesomeIcon icon={faYoutube} className="me-3" />
                </a>
                <a href="https://twitter.com/giftiglobal" className="social">
                  <FontAwesomeIcon icon={faTwitter} className="me-3" />
                </a>
                <a href="https://www.linkedin.cn/company/giftiglobal" className="social">
                  <FontAwesomeIcon icon={faLinkedin} className="me-3" />
                </a>
                <a href="https://www.instagram.com/giftiglobal/" className="social">
                  <FontAwesomeIcon icon={faInstagram} className="me-3" />
                </a>
              </Col>
            </Row>
            <p className="mt-2 Footer-text">{t('footer.yourSolution')}</p>
            <div className="bottom-social d-md-block">
              <a href="https://www.facebook.com/giftiglobal/" className="social">
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a href="https://www.youtube.com/channel/UCdCdTEynac-Z_w3A3PSSXmg" className="social">
                <FontAwesomeIcon icon={faYoutube} />
              </a>
              <a href="https://twitter.com/giftiglobal" className="social">
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a href="https://www.linkedin.cn/company/giftiglobal" className="social">
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
              <a href="https://www.instagram.com/giftiglobal/" className="social">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </div>
          </Col>
          <Col xs={4} md={4} className="Gifti_Global_is mt-4 mt-md-3">
            <ul style={{ listStyleType: 'none' }}>
              <span>{t('footer.about')}</span>
              <li
                className="footer-text-list mt-3"
                onClick={() => history.push(ROUTES.termsAndConditions)}
                style={{ cursor: 'pointer' }}
              >
                {t('common.termsAndConditions')}
              </li>
              <li
                className="footer-text-list mt-3"
                onClick={() => history.push(ROUTES.faq)}
                style={{ cursor: 'pointer' }}
              >
                {t('footer.faqs')}
              </li>
              <li
                className="footer-text-list mt-3"
                onClick={() => history.push(ROUTES.privacyPolicy)}
                style={{ cursor: 'pointer' }}
              >
                {t('footer.privacyPolicy')}
              </li>
              <li
                className="footer-text-list mt-3"
                onClick={() => window.open(config.rewardsByLandingUrl, '_blank')}
                style={{ cursor: 'pointer' }}
              >
                {t('footer.contactUs')}
              </li>
            </ul>
          </Col>
          <Col xs={8} md={4} className="Gifti_Global_is mt-4 mt-md-3">
            <ul style={{ listStyleType: 'none' }}>
              <span>{t('footer.getInTouchWithUs')}</span>
              <li className="footer-text-list mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                <img src={Map} alt="map" className="footer_icons" />
                {t('footer.officeLocation')}
              </li>
              <li className="footer-text-list mt-3">
                <img src={PhoneIcon} alt="phone" className="footer_icons" />
                +97145621020
              </li>
              <li className="footer-text-list mt-3">
                <img src={Mail} alt="mail" className="footer_icons" />
                care@giftiglobal.com
              </li>
            </ul>
          </Col>
        </Row>
        <hr />
        <p className="text-center">
          Gifti Global - © {date} {t('footer.allRightsReserved')}
        </p>
      </Container>
    </footer>
  );
};

export default Footer;
