import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cartAction, getCartItemsState } from '../redux/reducer';
import {
  addRemoveQuantityAction,
  bulkUpdateCartAction,
  fetchItemsByCartAction,
  getConversionRateAction,
  getFixerConversionRateAction,
  getPaymentCurrencyAction,
  updateCartAction,
} from '../redux/actions';

export const useCart = () => {
  const dispatch = useDispatch();
  const cartState = useSelector(getCartItemsState);

  const onFetchItemsByCartAction = useCallback(
    data => {
      dispatch(fetchItemsByCartAction(data));
    },
    [dispatch]
  );

  const onAddRemoveQuantityAction = useCallback(
    data => {
      dispatch(addRemoveQuantityAction(data));
    },
    [dispatch]
  );

  const onUpdateCartAction = useCallback(
    data => {
      dispatch(updateCartAction(data));
    },
    [dispatch]
  );

  const onBulkUpdateCartAction = useCallback(
    () => {
      return dispatch(bulkUpdateCartAction());
    },
    [dispatch]
  );

  const onGetPaymentCurrencyAction = useCallback(() => {
    dispatch(getPaymentCurrencyAction());
  }, [dispatch]);

  const onGetConversionRateAction = useCallback(
    data => {
      dispatch(getConversionRateAction(data));
    },
    [dispatch]
  );

  const onGetFixerConversionRateAction = useCallback(
    currency => {
      dispatch(getFixerConversionRateAction(currency));
    },
    [dispatch]
  );

  const onSaveItemsToCart = useCallback(
    lineItems => {
      dispatch(cartAction.saveItemsToCart(lineItems));
    },
    [dispatch]
  );

  const onUpdateTotalCartItems = useCallback(
    quantity => {
      dispatch(cartAction.updateTotalCartItems(quantity));
    },
    [dispatch]
  );

  const onClearCartState = useCallback(() => {
    dispatch(cartAction.clearState());
  }, [dispatch]);

  const onUpdateCheckout = useCallback(
    data => {
      dispatch(cartAction.updateCheckout(data));
    },
    [dispatch]
  );

  return {
    onFetchItemsByCartAction,
    onAddRemoveQuantityAction,
    onUpdateCartAction,
    onGetPaymentCurrencyAction,
    onGetConversionRateAction,
    onGetFixerConversionRateAction,
    onSaveItemsToCart,
    onUpdateTotalCartItems,
    onClearCartState,
    onUpdateCheckout,
    onBulkUpdateCartAction,
    ...cartState,
  };
};
