import React, { useEffect, useMemo } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { get, isEmpty, map } from 'lodash';
import Carousel from 'react-elastic-carousel';

import Giftcard from './Giftcard';
import { useBrands } from '../../brands/hooks/useBrands';
import { useGiftcards } from '../hooks/useGiftcards';
import { useTranslation } from 'react-i18next';

import './AllFeaturedCards.scss';

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 1 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 },
];

const AllFeaturedCards = () => {
  const { t } = useTranslation('main');
  const { selectedCountry, countries } = useGiftcards();
  const { brandsWithFeature, onFeatureBrandsAction } = useBrands();

  const nowCountry = useMemo(
    () =>
      selectedCountry && isEmpty(get(selectedCountry, 'country_name'))
        ? get(countries[0], 'country_name')
        : get(selectedCountry, 'country_name'),
    [countries, selectedCountry]
  );

  useEffect(() => {
    if (selectedCountry?.id) {
      onFeatureBrandsAction({
        currency: selectedCountry.id,
        program_id: 1,
      });
    }
  }, [onFeatureBrandsAction, selectedCountry?.id]);

  return (
    <div>
      <div />
      <div className="cardgifiti-card">
        <Container>
          <Row>
            <Col>
              <p className="giftiallcard-text">{`${t('home.recommendBrands.heading')} ${nowCountry}`}</p>
              <p className="allgiftcard-text">
                {`${t('home.recommendBrands.buyCardsIn')} ${nowCountry}`}
                <br />
                {t('home.recommendBrands.personalizedCards')}
              </p>
              <div className="carosel_images" id="brandImagesCarousel">
                {
                  <Carousel
                    showArrows={brandsWithFeature?.length > 3}
                    pagination={false}
                    breakPoints={breakPoints}
                    preventDefaultTouchmoveEvent={true}
                    isRTL={false}
                  >
                    {map(brandsWithFeature, (brand, i) => (
                      <Col key={i} xs={11}>
                        <Giftcard card={brand} />
                      </Col>
                    ))}
                  </Carousel>
                }
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default AllFeaturedCards;
