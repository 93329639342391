import React from 'react';
import Moment from 'react-moment';
import { useTranslation } from 'react-i18next';

import { ColumnsTitle } from './ColumnsTitle';
import { RowDataPropType } from '../../propTypes/RowData';

export const DateColumn = ({ rowData }) => {
  const { t } = useTranslation();

  return (
    <>
      <ColumnsTitle title={t('orders.date')} />
      <p className="text_order">
        <Moment format="MMM Do,YYYY">{rowData.date}</Moment>
        <br />
        <Moment format="h:mm A">{rowData.date}</Moment>
      </p>
    </>
  );
};

DateColumn.propTypes = {
  rowData: RowDataPropType,
};
