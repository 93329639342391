import React from 'react';
import PropTypes from 'prop-types';

export const PrivacyPolicyListItem = ({ id, title, content }) => (
  <article>
    <h3 id={id}>{title}</h3>
    <div>{content}</div>
  </article>
);

PrivacyPolicyListItem.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.element,
};
