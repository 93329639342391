import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getRewardPointsState } from '../redux/reducer';
import {
  getConvertCreditsAction,
  getRewardPointsAction,
  getTransactionsAction,
  redeemGiftCardAction,
} from '../redux/actions';

export const useRewardPoints = () => {
  const dispatch = useDispatch();
  const rewardPointsState = useSelector(getRewardPointsState);

  const onGetRewardPointsAction = useCallback(() => {
    dispatch(getRewardPointsAction());
  }, [dispatch]);

  const onGetTransactionsAction = useCallback(() => {
    dispatch(getTransactionsAction());
  }, [dispatch]);

  const onGetConvertCreditsAction = useCallback(
    data => {
      dispatch(getConvertCreditsAction(data));
    },
    [dispatch]
  );

  const onRedeemGiftCard = useCallback(
    data => {
      dispatch(redeemGiftCardAction(data));
    },
    [dispatch]
  );

  return {
    onRedeemGiftCard,
    onGetConvertCreditsAction,
    onGetRewardPointsAction,
    onGetTransactionsAction,
    ...rewardPointsState,
  };
};
