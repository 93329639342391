import React from 'react';
import {useTranslation} from "react-i18next";
import GiftIcon from "../../../assets/media/icons/gift.svg"

function Gift({giftcardNumber}) {
    const {t} = useTranslation();

    return (
        <div className="gift-auth-wrapper">
            <img src={GiftIcon} alt="" width="37px" height="44px"/>
            <p>{t('common.giftCardNo')}: {giftcardNumber}</p>
        </div>
    );
}

export default Gift;
