import React, { useEffect, useRef } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Emailicon from '../../../assets/media/icons/Email-icon.svg';
import Usericon from '../../../assets/media/icons/User-icon.svg';
import Passwordicon from '../../../assets/media/icons/Password-icon.svg';
import { ROUTES } from '../../../routes';
import { useTranslation } from 'react-i18next';
import { useSignupHook } from '../hooks/useSignupHook';
import SocialButtons from './SocialButtons';
import SignUpDivider from "./SignUpDivider";
import ReCAPTCHA from 'react-google-recaptcha';
import config from '../../../config';

const SignUp = () => {
  const { t } = useTranslation();
  const { formik, togglePass, eye, showPass, authErrors } = useSignupHook();
  const captchaRef = useRef(null);

    useEffect(() => {
    if (authErrors?.length > 0) {
      captchaRef.current.reset();
    }
  }, [authErrors?.length]);

  return (
    <Container>
      <div>
        <div className="login-card mx-auto">
          <p className="login-text text-center h3 pt-5">{t('auth.signUp.title')}</p>
          <p className="text-center mt-0">
            <small>{t('auth.common.enterToContinueText')}</small>
          </p>

          <Form onSubmit={formik.handleSubmit} className="user">
            <Row>
              <Col md={6}>
                <Form.Group controlId="formBasicText" className="singup-input icons_login form-group-custom">
                  <Form.Control
                    size="md"
                    type="text"
                    placeholder={t('auth.signUp.form.firstName')}
                    className="icons_fields"
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    name="first_name"
                  />
                  <img src={Usericon} alt="Icon" className="icon_img" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formBasiclast-name" className="singup-inputfield form-group-custom">
                  <Form.Control
                    size="md"
                    type="text"
                    placeholder={t('auth.signUp.form.lastName')}
                    className="icons_fields_b"
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    name="last_name"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                {formik.errors.first_name ? <p className="validation-messages">{formik.errors.first_name}</p> : null}
              </Col>
              <Col>
                {formik.errors.last_name ? <p className="validation-messages">{formik.errors.last_name}</p> : null}
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="formBasicEmail6" className="icons_login form-group-custom">
                  <Form.Control
                    size="md"
                    type="email"
                    placeholder={t('auth.signUp.form.enterEmail')}
                    className="icons_fields"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    name="email"
                  />
                  <img src={Emailicon} alt="Icon" className="icon_img" />
                </Form.Group>
                {formik.errors.email ? <p className="validation-messages">{formik.errors.email}</p> : null}

                <Form.Group controlId="formBasicPassword" className="icons_login form-group-custom">
                  <Form.Control
                    size="md"
                    type={showPass ? 'text' : 'password'}
                    placeholder={t('common.password')}
                    className="icons_fields"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    name="password"
                  />
                  <img src={Passwordicon} alt="Icon" className="icon_img" />
                  <i onClick={togglePass} className="icon_right">
                    {eye}
                  </i>{' '}
                </Form.Group>
                {formik.errors.password ? <p className="validation-messages">{formik.errors.password}</p> : null}

                <Form.Group controlId="formBasic-confirmPassword" className="icons_login form-group-custom">
                  <Form.Control
                    size="md"
                    type={showPass ? 'text' : 'password'}
                    placeholder={t('auth.signUp.form.confirmPassword')}
                    className="icons_fields"
                    value={formik.values.password_confirmation}
                    onChange={formik.handleChange}
                    name="password_confirmation"
                  />
                  <i onClick={togglePass} className="icon_right">
                    {eye}
                  </i>{' '}
                  <img src={Passwordicon} alt="Icon" className="icon_img" />
                </Form.Group>
                <Form.Group controlId='formBasicRecaptcha' className='icons_login form-group-custom'>
                  <ReCAPTCHA
                    onExpired={() => captchaRef.current.reset()}
                    sitekey={config.captchaSiteKey}
                    ref={captchaRef}
                    onChange={(e) => {
                      formik.setFieldValue('recaptcha', e)
                    }}
                  />
                  {formik.touched.recaptcha && formik.errors.recaptcha ? (
                  <p className='validation-messages'>{formik.errors.recaptcha}</p>
                ) : null}
                  </Form.Group>

                {formik.errors.password_confirmation ? (
                  <p className="validation-messages">{formik.errors.password_confirmation}</p>
                ) : null}
                {authErrors && authErrors.length ? (
                  <p className="validation-messages">{authErrors.join('\n')}</p>
                ) : null}

                <Button className="btn-custom" variant="info" size="lg" type="submit">
                  {t('auth.common.signUpText')}
                </Button>
                <SignUpDivider logInUrl={ROUTES.auth.logIn}/>
                <SocialButtons captchaRef={captchaRef} formik={formik} />
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </Container>
  );
};

export default SignUp;
