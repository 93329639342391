import { useCallback } from "react";
import { MD5 } from "crypto-js"
const myWorldSecretCode = `${process.env.REACT_APP_MY_WORLD_SECRET_CODE}`;
const myWorldOrganication = `${process.env.REACT_APP_MY_WORLD_ORGANIZATION}`;


export const useTrackingPixel = () => {
  const trackingPixel = useCallback((orders, tduid, myWorldEvent) => {
    // Your organization ID; provided by MyWorld.
    const organization = myWorldOrganication
    // Event ID; provided by Cashback World.
    const event = myWorldEvent;

     function productInfo(product, orders_length, index) {
      let order = "f1=" + product.brand_name +
        "&f2=" + product.id +
        "&f3=" + product.quantity +
        "&f4=" + parseFloat(product.giftcard_value).toFixed(2)

      if (orders_length !== index + 1) {
        order = order + "|";
      }
      return order;
    }

    function getOrderValue(orders) {
      return orders.reduce((sum, order) => sum + order.giftcard_value, 0)
    }

    function getOrdersDetails(orders) {
      let orderValue = getOrderValue(orders)
      let orders_length = orders.length
      let ordersInfo = "";
      let paymentCurrency = orders[0].payment_currency
      let orderId = orders[0].orderid;

      orders.forEach((product, index) => {
        ordersInfo += productInfo(product, orders_length, index);
      });
      return {
        ordersInfo: ordersInfo,
        orderValue: orderValue,
        currency: paymentCurrency,
        orderNumber: orderId
      };
    }



    // OPTIONAL: You may transmit a list of items ordered in the reportInfo parameter. See chapter
    // reportInfo (3.4.7.) at the “Tracking Implementation” Manual for details.
    // let reportInfo = "";
    let { ordersInfo, orderValue, currency, orderNumber } = getOrdersDetails(orders)

    const reportInfo = escape(ordersInfo);

    // Event type:
    // true = Sale
    // false = Lead
    const isSale = true;

    // Encrypted connection on this page:
    // true = Yes (https)
    // false = No (http)
    const isSecure = true;


    /***** IMPORTANT: *****/
    /***** In most cases, you should not edit anything below this line. *****/
    let domain;
    let checkNumberName;
    if (isSale) {
      domain = "tbs.tradedoubler.com";
      checkNumberName = "orderNumber";
    } else {
      domain = "tbl.tradedoubler.com";
      checkNumberName = "leadNumber";
      orderValue = "1";
    }

    function getChecksum() {
      const prefix = "v04"
      return prefix + MD5(
        myWorldSecretCode + orderNumber + orderValue
      ).toString()
    }

    //Create the checksum. See chapter checksum (3.4.1.) at the “Tracking Implementation” Manual for
    // details.
    const checksum = getChecksum();

    let scheme;
    if (isSecure) scheme = "https";
    else scheme = "http";

    let trackBackUrl = scheme +
      "://" + domain +
      "/report?organization=" + organization +
      "&event=" + event +
      "&" + checkNumberName +
      "=" + orderNumber +
      "&checksum=" + checksum +
      "&tduid=" + tduid +
      "&type=iframe&reportInfo=" + reportInfo


    if (isSale) {
      trackBackUrl =
        trackBackUrl + "&orderValue=" + parseFloat(orderValue).toFixed(2) + "&currency=" + currency;
    }

    function prepareFrame(tburl) {
      let ifrm = document.createElement("IFRAME");
      ifrm.setAttribute("src", tburl);
      ifrm.style.width = 1 + "px";
      ifrm.style.height = 1 + "px";
      ifrm.style.border = "none";
      document.body.appendChild(ifrm);
    }

    prepareFrame(trackBackUrl);
  }, []);
  return {
    trackingPixel
  };
};
