import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import { useSelector } from 'react-redux';
import { get, map } from 'lodash';
import Button from 'react-bootstrap/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useGiftcards } from '../hooks/useGiftcards';
import {useTranslation} from "react-i18next";

const GiftiGiftCard = props => {
  const { giftToParams, setGiftToParams } = props;
  const { onGiftCardThemeAction, theme } = useGiftcards();
  const { giftingToUser } = useSelector(state => state['common']);
  const { t } = useTranslation('main');

  const [giftValue, setGiftValue] = useState(0);

  useEffect(() => {
    onGiftCardThemeAction();
  }, [onGiftCardThemeAction]);

  const formik = useFormik({
    initialValues: {
      name: `${giftingToUser?.name || ''}`,
      email: '',
      message: '',
      gifting_image_id: giftToParams.gifting_image_id,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(100, `${t('validation:maxFieldLength')} 100`)
        .required(t('validation:requiredField')),
      email: Yup.string()
        .email()
        .max(100, `${t('validation:maxFieldLength')} 100`)
        .required(t('validation:requiredField')),
      message: Yup.string()
        .max(255, `${t('validation:maxFieldLength')} 255`),
      gifting_image_id: '',
    }),
    onSubmit: () => {
      setGiftToParams();
    },
  });

  const updateGiftToParams = (id, event) => {
    const payload = { ...giftToParams };
    if (id && event.target.value) {
      setGiftToParams({ ...payload, [id]: event.target.value });
      formik.handleChange(event);
    }
  };
  const updateGiftValue = value => {
    setGiftValue(value);
    const payload1 = { ...giftToParams };
    payload1.gifting_image_id = value;
    setGiftToParams({ ...payload1, gifting_image_id: value });
  };

  return (
    <div>
      <p className="select-card-text mt-5">{t("common.sentTo")}</p>
      <Form onSubmit={formik.handleSubmit}>
        <Form.Group>
          <Form.Control
            type="text"
            placeholder={t("common.name")}
            id="name"
            name="name"
            onBlur={formik.handleBlur}
            isInvalid={formik.touched.name && formik.errors.name}
            className="mt-3 giftsomeone "
            value={formik.values.name}
            onChange={e => {
              formik.handleChange(e);
              updateGiftToParams('name', e);
            }}
          />
          {formik.touched.name && formik.errors.name ? (
            <p className="validation-messages1">{formik.errors.name}</p>
          ) : null}
          <Form.Control
            type="email"
            id="email"
            name="email"
            placeholder={t("common.enterEmail")}
            onBlur={formik.handleBlur}
            isInvalid={formik.touched.email && formik.errors.email}
            className="mt-3 giftsomeone"
            onChange={e => {
              formik.handleChange(e);
              updateGiftToParams('email', e);
            }}
          />
          {formik.touched.email && formik.errors.email ? (
            <p className="validation-messages1">{formik.errors.email}</p>
          ) : null}
          <Form.Control
            as="textarea"
            countlimit={255}
            className="giftsome1 mt-3"
            rows={3}
            cols={53}
            style={{
              border: '0.800000011920929px solid #b2b2c9',
              borderRadius: '10px',
              fontSize: '15px',
              padding: '8px',
              width: '100%',
            }}
            placeholder={t("cards.leavePersonalMessage")}
            id="message"
            name="message"
            onBlur={formik.handleBlur}
            isInvalid={formik.touched.message && formik.errors.message}
            onChange={e => {
              formik.handleChange(e);
              updateGiftToParams('message', e);
            }}
          />
          {formik.touched.message && formik.errors.message ? (
            <p className="validation-messages1">{formik.errors.message}</p>
          ) : null}

          <p className="select-card-text mt-3">{t("cards.chooseTemplate")}</p>
          <div className="row m-auto">
            {map(theme, (images2, i) => (
              <React.Fragment key={i}>
                {map(get(images2, 'gifting_images'), (images1, j) => (
                  <React.Fragment key={j}>
                    <Button
                      variant="link"
                      className="themetemplates md-3 mb-3"
                      onClick={() => updateGiftValue(images1.gifting_id)}
                      active={giftValue === images1.gifting_id}
                      key={j}
                    >
                      <img
                        src={get(images1, 'images.xsmall_rectangle')}
                        alt="AmazonMedium"
                        name="gifting_image_id"
                        className="img-fluid"
                      />
                    </Button>
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          </div>
        </Form.Group>
      </Form>
    </div>
  );
};

export default GiftiGiftCard;

GiftiGiftCard.propTypes = {
  giftToParams: PropTypes.object,
  setGiftToParams: PropTypes.func,
};
