import React, { useEffect, useState } from 'react';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import { useLoginHook } from '../hooks/useLoginHook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';


const SocialButtons = ({ captchaRef, formik }) => {
  const { facebookLoginFailure, facebookLoginSuccess, googleLoginFailure, googleLoginSuccess, fbId, googleId } =
    useLoginHook();

  const [isDisabled, setIsDisabled] = useState(true);

  const captchaValue = Boolean(captchaRef?.current?.getValue());

  useEffect(() => {
    setIsDisabled(!captchaValue);
  }, [captchaValue]);

  const captchaValidation = () => {
    isDisabled && formik.setFieldTouched('recaptcha', true);
  };

  return (
    <div className='social-btn'>
      <div className='google'>
        <div onClick={captchaValidation}>
          <GoogleLogin
            style={{ display: 'block' }}
            variant='outline-light'
            className='google-button'
            clientId={googleId}
            onSuccess={event => googleLoginSuccess(event, captchaRef?.current?.getValue())}
            onFailure={googleLoginFailure}
            isSignedIn={false}
            disabled={isDisabled}
          />
        </div>
      </div>
      <div className='facebook'>
        <div onClick={captchaValidation}>
          <FacebookLogin
            isDisabled={isDisabled}
            appId={fbId}
            callback={event => facebookLoginSuccess(event, captchaRef?.current?.getValue())}
            onFailure={facebookLoginFailure}
            size='medium'
            cssClass='facebook-button'
            autoload={false}
            textButton={''}
            icon={<FontAwesomeIcon icon={faFacebook} size='lg' className='facebook-icon' />}
            disabled={true}
          />
        </div>
      </div>
    </div>
  );
};

export default SocialButtons;
