import { api } from '../../../api';
export const categorylistAPI = categoryOptions => {
  const { currency, program_id } = categoryOptions;
  return api.get('/v1/categories/get_categories', {
    params: {
      currency,
      program_id,
    },
  });
};

export const giftcard_units = () => {
  return api.get('/v1/giftcard_units');
};
