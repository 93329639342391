import React from 'react';
import { useTranslation } from 'react-i18next';

import { PrivacyPolicyListItem } from './PrivacyPolicyListItem';

export const PrivacyPolicyList = ({ privacyPolicyList }) => {
  const { t } = useTranslation('privacyPolicy');

  return privacyPolicyList?.map(privacyPolicyItem => (
    <PrivacyPolicyListItem
      key={privacyPolicyItem.id}
      id={privacyPolicyItem.id}
      title={t(`${privacyPolicyItem.localizationKey}.title`)}
      content={
        <div
          dangerouslySetInnerHTML={{
            __html: t(`${privacyPolicyItem.localizationKey}.content`, { interpolation: { escapeValue: false } }),
          }}
        />
      }
    />
  ));
};
