import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { redeemGiftCardAction } from '../../rewardPoints/redux/actions';

export const REDEEM_CARD_INITIAL_STATE = {
  giftcard_number: null,
};

export const REDEEM_CARD_FEATURE_KEY = 'redeemcard';
export const redeemCardAdapter = createEntityAdapter();
redeemCardAdapter.getInitialState(REDEEM_CARD_INITIAL_STATE);

export const redeemCardSlice = createSlice({
  name: REDEEM_CARD_FEATURE_KEY,
  initialState: REDEEM_CARD_INITIAL_STATE,
  reducers: {
    saveGiftcardNumber(state, action) {
      state.giftcard_number = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(redeemGiftCardAction.pending, state => {
        state.giftcard_number = null;
      })
      .addCase(redeemGiftCardAction.fulfilled, state => {
        state.giftcard_number = null;
      });
  },
});

export const redeemCardReducer = redeemCardSlice.reducer;
export const redeemCardActions = redeemCardSlice.actions;
export const getRedeemCardNumberState = rootState => rootState[REDEEM_CARD_FEATURE_KEY].giftcard_number;
