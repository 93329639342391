import React, {useEffect} from "react";
import "./index.scss";
import { Header } from '../../shared';
import EngTerms from "./EngTerms";
import ArTerms from "./ArTerms";

const EN = 'en'
const AR = 'ar'

function ConditionsPage() {
    const [termsTabLang, setTermsTabLang] = React.useState(EN);

    const toggleLanguage = () => {
        setTermsTabLang(prevLang => (prevLang === EN ? AR : EN));
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Header/>
            <div className="container">
                <div className="language-toggle">
                    <button onClick={toggleLanguage}>
                        {termsTabLang === EN ? 'العربية' : 'English'}
                    </button>
                </div>
                {termsTabLang === EN ? <EngTerms/> : <ArTerms/>}
            </div>
        </>
    );
}

export default ConditionsPage;
