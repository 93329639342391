import SuccessfulCheckMark from '../../../assets/media/icons/successful-check-mark.svg';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import React from 'react';
import { ROUTES } from '../../../routes';
import { useHistory } from 'react-router-dom';

/**
 *
 * @param {string | null} message
 * @returns {JSX.Element}
 * @constructor
 */
export const SuccessfulResponse = ({ message }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleOnClick = () => {
    history.push({ pathname: ROUTES.home });
  };

  return (
    <div className='giveaway-response'>
      <img src={SuccessfulCheckMark} alt={t('giveaway.successfulCheckMark')} />
      {message ? <p>{message}</p> : (<p>{t('giveaway.successfullyClaimed')} {t('giveaway.giftiGlobalCard')}</p>)}

      <Button className='btn-custom' variant='info' size='lg' onClick={handleOnClick}>
        {t('giveaway.visitGiftiGlobal')}
      </Button>
    </div>
  );
};