import { api } from '../../../api';

export const cartItemsService = cart_items => {
  return api.post('/v2/cart_items', cart_items);
};

export const fetchItemsByCartService = cart_items => {
  const { currency_code, use_giftiglobal_points=false } = cart_items;
  return api.get('/v2/cart_items/fetch_items_by_cart', {
    params: {
      currency_code,
      use_giftiglobal_points,
    },
  });
};

export const addRemoveQuantityService = cart_items => {
  return api.put('/v2/cart_items/add_or_remove_quantity/', cart_items);
};

/* Commented out, because of no need for getting the total count in the cart. */
// export const cartTotalCountService = cart_items => {
//   const { currency } = cart_items;
//   return api.get('/v1/cart_items/cart_total_count', { params: { currency } });
// };

export const getBrandImageById = (id, currency) => {
  return api.get(`/v1/brands/${id}/brand_images`, { params: { currency } });
};

/* todo: move to another file and change logic */
export const getFixerConversionRateAPI = async currency => {
  // const fixerURL = `${process.env.REACT_APP_FIXER_URL}`;
  // const fixerAPIKey = `${process.env.REACT_APP_FIXER_API_KEY}`;
  // const url = `${fixerURL}/latest?access_key=${fixerAPIKey}&base=AED&symbols=${currency}`;
  // const request = await fetch(url, { method: "GET" });
  // const response = await request.json();
  // if (response && response.success) {
  //   return {
  //     code: 200,
  //     data: {
  //       currency_exchange_rate: parseFloat(
  //         parseFloat(response.rates[currency]).toFixed(2)
  //       ),
  //     },
  //   };
  // }
  // return { code: 200, data: { currency_exchange_rate: 1 } };\
  if (currency === 'AED') {
    return { code: 200, data: { currency_exchange_rate: 1, minimum_payable_amount: 2.05 } };
  } else {
    return api.get('/v1/giftcard_units/get_conversion_rate', {
      params: {
        currency,
      },
    });
  }
};

export const getOrderLimitAPI = () => {
  return api.get('/v1/user/get_user_giftcard_limit');
};
